import { Modal, Skeleton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./TableTrade.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldSelect from "components/field/FieldSelect";
import ButtonCustom from "components/ButtonCustom";
import {
  getAllJobber,
  createTransport,
  editOrderTransport,
  getProjectTradeById,
} from "utils/api/trade/trade";
import { getTripStatus } from "utils/api/masterData/service";
import helper from "utils/helper";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Line from "components/layout/line";
import API from "utils/api/work/work";
import FieldAutoComplete2 from "components/field/FieldAutoComplete/index2";
import dayjs from "dayjs";

let api = API.create();
const ModalUpdateStatusTransport = (props) => {
  const defaultValue = props?.editData;
  // console.log("defaultValue", defaultValue);
  const [listJobber, setListJobber] = useState([]);
  const [listCarrier, setListCarrier] = useState([]);
  const [trackingDetail, setTrackingDetail] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [creditTerm, setCreditTerm] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const sent_date = watch("sent_date");

  useEffect(() => {
    setIsClicked(false);
  }, [props?.open]);

  useEffect(() => {
    setValue("start_send_date", defaultValue?.start_send_date);
    setValue("sent_date", defaultValue?.sent_date);
    setValue("payment_date_forecast", defaultValue?.payment_date_forecast);
    setValue("trade_jobber_id", defaultValue?.trade_jobber_id);
    setValue("trip_status", defaultValue?.trip_status);
    setValue("logistics_provider_type", defaultValue?.logistics_provider_type);
    setValue("tracking_code", defaultValue?.tracking_code);
    setValue("remark", defaultValue?.remark);
    setValue("trade_carrier_id", defaultValue?.trade_carrier_id);
  }, [props]);

  const trade_carrier_id = watch("trade_carrier_id");

  useEffect(() => {
    if (sent_date) {
      const sent_date_cal = new Date(sent_date);
      sent_date_cal.setDate(sent_date_cal.getDate() + creditTerm);
      setValue("payment_date_forecast", sent_date_cal);
    }
  }, [sent_date]);

  useEffect(() => {
    console.log({ trade_carrier_id });
  }, [trade_carrier_id]);

  useEffect(() => {
    getTracking();
  }, [defaultValue]);

  const closeModal = () => {
    props?.handleClose();
    reset();
  };

  useEffect(() => {
    if (props?.tradeProjectId) {
      getProject(props?.tradeProjectId);
    }
  }, [props?.tradeProjectId]);

  useEffect(() => {
    getJobber();
  }, []);

  const getProject = async (id) => {
    try {
      await getProjectTradeById(id).then((res) => {
        const results = res.data.results;
        setCreditTerm(results?.credit_term ?? null);
      });
    } catch (err) {
      console.log("error", err);
    }
  };
  const getJobber = async () => {
    await getAllJobber({ type: "list_select" })
      .then((res) => {
        if (res.data.code === 1000) {
          const results = res.data.results;
          setListJobber(
            results?.map((res) => ({ value: res.id, name: res.jobber_name }))
          );
          setListCarrier(
            results?.map((res) => ({ value: res.id, label: res.jobber_name }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getTracking = async () => {
    console.log(
      "getTracking",
      defaultValue?.tracking_code,
      defaultValue?.logistics_provider_type
    );
    if (
      defaultValue?.logistics_provider_type !== undefined &&
      defaultValue?.tracking_code !== ""
    ) {
      setLoadingDetail(true);
      await api
        .getTrackingFromCode({
          logistics_provider_type: defaultValue?.logistics_provider_type,
          tracking_code: defaultValue?.tracking_code,
        })
        .then((response) => {
          console.log("response", response);
          if (response.data?.results && response.data?.results.length > 0) {
            setTrackingDetail(response.data?.results.reverse());
          }
          setLoadingDetail(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingDetail(false);
        });
    }
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    setIsClicked(true);
    await editOrderTransport(defaultValue?.id, data)
      .then((res) => {
        if (res.data.code === 1000) {
          closeModal();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal open={props?.open} onClose={closeModal} sx={{ zIndex: 1000 }}>
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon sx={{ color: "grey.main" }} onClick={closeModal} />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              อัพเดทสถานะ
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="start_send_date"
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldDatePicker
                      label="วันที่เริ่มส่ง"
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.start_send_date}
                      helperText={
                        errors.start_send_date
                          ? errors.start_send_date.message
                          : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="sent_date"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldDatePicker
                      label="วันที่ส่งเสร็จ"
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.sent_date}
                      maxDate={dayjs().add(6, "day")}
                      helperText={
                        errors.sent_date ? errors.sent_date.message : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="payment_date_forecast"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldDatePicker
                      label="วันประมาณการรับเงิน"
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.payment_date_forecast}
                      helperText={
                        errors.payment_date_forecast
                          ? errors.payment_date_forecast.message
                          : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="trade_jobber_id"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ผู้ขาย"
                      onChange={onChange}
                      options={listJobber ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      readOnly={defaultValue?.seller_payment_status !== 1}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="trade_carrier_id"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldAutoComplete2
                      label="ผู้ขนส่ง"
                      className="w-100"
                      value={value}
                      onChange={onChange}
                      options={listCarrier ?? []}
                      readOnly={
                        defaultValue?.carrier_freight_before_tax == 0 ||
                        defaultValue?.carrier_payment_status > 2
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="trip_status"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="สถานะการจัดส่ง"
                      onChange={onChange}
                      options={[
                        { value: 1, name: "จัดส่งแล้ว" },
                        { value: 2, name: "รอจัดส่ง" },
                      ]}
                      value={defaultValue?.trip_status === 3 ? null : value}
                      readOnly={defaultValue?.trip_status === 3 ? true : false}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />

              <Controller
                control={control}
                name="logistics_provider_type"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ผู้ให้บริการขนส่ง"
                      onChange={onChange}
                      options={[
                        {
                          value: 0,
                          name: "ไปรษณีย์ไทย",
                        },
                        {
                          value: 1,
                          name: "Flash Express",
                        },
                        {
                          value: 2,
                          name: "Kerry Express",
                        },
                        {
                          value: 3,
                          name: "J&T Express",
                        },
                        {
                          value: 4,
                          name: "SCG Express",
                        },
                      ]}
                      value={value}
                      readOnly={defaultValue?.trip_status === 3 ? true : false}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="tracking_code"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="เลขที่ติดตามเอกสาร"
                      onChange={onChange}
                      value={value}
                      readOnly={defaultValue?.trip_status === 3 ? true : false}
                      error={!!errors.tracking_code}
                      helperText={
                        errors.tracking_code ? errors.tracking_code.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="remark"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="หมายเหตุ"
                      onChange={onChange}
                      value={value}
                      readOnly={defaultValue?.trip_status === 3 ? true : false}
                      error={!!errors.remark}
                      helperText={errors.remark ? errors.remark.message : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />

              <div style={{ marginTop: 20 }}>
                <div style={{ textAlign: "center" }}>สถานะการส่งเอกสาร</div>
                <Line />

                <div
                  style={{
                    // overflow: "scroll",
                    // maxHeight: 300,
                    marginBottom: 2,
                    marginTop: 20,
                  }}
                >
                  {loadingDetail ? (
                    <Skeleton height={60} />
                  ) : (
                    trackingDetail?.map((ele, index) => {
                      return (
                        <div key={"status" + index} style={{ display: "flex" }}>
                          <div style={{ flex: 1, display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "0px 10px 10px 10px",
                              }}
                            >
                              <TripOriginIcon
                                sx={{
                                  fontSize: 16,
                                  opacity: index === 0 ? 1 : 0.5,
                                }}
                              />
                              <MoreVertIcon
                                sx={{ opacity: index === 0 ? 1 : 0.5 }}
                              />
                            </div>
                            <div style={{ flex: 1, textAlign: "start" }}>
                              <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                                {ele.track_status}{" "}
                                {ele.location ? "[" + ele.location + "]" : ""}
                              </div>
                              <div
                                style={{
                                  opacity: index === 0 ? 1 : 0.5,
                                  fontSize: 12,
                                }}
                              >
                                {ele.tracking_detail ?? ""}
                              </div>
                              <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                                {helper.momentDateTime(
                                  ele.checkpoint_date,
                                  "short",
                                  false
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={closeModal}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="บันทึก"
                  style={{ padding: 0 }}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                  disabled={
                    defaultValue?.trip_status === 3 || isClicked ? true : false
                  }
                  // disabled={
                  //   defaultValue?.id
                  //     ? defaultValue?.seller_payment_status !== 1 ||
                  //       defaultValue?.carrier_payment_status > 2
                  //     : false
                  // }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalUpdateStatusTransport;
