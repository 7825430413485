import { Modal, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import styles from "./TruckRental.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldSelect from "components/field/FieldSelect";
import ButtonCustom from "components/ButtonCustom";
import {
  createOrder,
  getAllCustomer,
  getAllTradeProject,
  getProjectTradeById,
  editOrderTrade,
} from "utils/api/trade/trade";
import { de } from "date-fns/locale";
import {
  createRentalOrder,
  editOrderRental,
  getAllLessor,
  getAllTenant,
  getAllTruckTenantProject,
  getTruckTenantProjectById,
} from "utils/api/truckRental/truckRental";
import AuthContext from "components/context/AuthContext";
const ModalAddRentalOrder = (props) => {
  const { adminAuth } = useContext(AuthContext);
  const defaultValue = props.editData;
  console.log("defaultValue", defaultValue);
  const [listTenant, setListTenant] = useState([]);
  const [listLessor, setListLessor] = useState([]);
  const [listProject, setListProject] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  console.log("listProject", listProject);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    setIsClicked(false);
  }, [props?.open]);

  useEffect(() => {
    if (defaultValue && props?.type === "edit") {
      setValue("truck_lessor_id", defaultValue?.truck_lessor_id);
      setValue("topic", defaultValue?.topic);
      setValue("order_code", defaultValue?.order_code);
      setValue("puchase_date", defaultValue?.puchase_date);
      setValue("end_date_term", defaultValue?.end_date_term);
      setValue("product_name", defaultValue?.product_name);
      setValue("tenant_amount", defaultValue?.tenant_amount);
      setValue("tenant_amount_unit", defaultValue?.tenant_amount_unit);
      setValue("tenant_price", defaultValue?.tenant_price);
      setValue("tenant_ot", defaultValue?.tenant_ot);
      setValue("lessor_price", defaultValue?.lessor_price);
      setValue("lessor_ot", defaultValue?.lessor_ot);
      setValue("total_tenant", defaultValue?.total_tenant);
      setValue("total_lessor", defaultValue?.total_lessor);
      setValue("profit_baht", defaultValue?.profit_baht);
      setValue("profit_percent", defaultValue?.profit_percent);
    }
  }, [defaultValue]);

  const truck_tenant = watch("truck_tenant_id");
  const tenant_price = watch("tenant_price");
  const tenant_ot = watch("tenant_ot");
  const lessor_price = watch("lessor_price");
  const lessor_ot = watch("lessor_ot");

  const total_tenant = watch("total_tenant");
  const total_lessor = watch("total_lessor");
  const profit_baht = watch("profit_baht");

  //   const truck_lessor = watch("truck_lessor_id");

  useEffect(() => {
    if (tenant_price && tenant_ot) {
      const total_tenant_cal =
        parseFloat(tenant_price || 0) + parseFloat(tenant_ot || 0);
      setValue("total_tenant", total_tenant_cal);
    }
  }, [tenant_price, tenant_ot]);

  useEffect(() => {
    if (lessor_price && lessor_ot) {
      const total_lessor_cal =
        parseFloat(lessor_price || 0) + parseFloat(lessor_ot || 0);
      setValue("total_lessor", total_lessor_cal);
    }
  }, [lessor_price, lessor_ot]);

  useEffect(() => {
    if (total_tenant && total_lessor) {
      const profit_baht_cal =
        parseFloat(total_tenant || 0) - parseFloat(total_lessor || 0);
      setValue("profit_baht", profit_baht_cal);
    }
  }, [total_lessor, total_tenant]);

  useEffect(() => {
    if (profit_baht && total_lessor) {
      const percent =
        (parseFloat(profit_baht) / parseFloat(total_lessor)) * 100;
      setValue("profit_percent", percent.toFixed(2));
    }
  }, [profit_baht, total_lessor]);

  useEffect(() => {
    getTenant();
    getLessor();
  }, []);

  useEffect(() => {
    getProject(truck_tenant);
  }, [truck_tenant]);

  useEffect(() => {
    if (defaultValue) {
      getProjectById(defaultValue?.truck_tenant_project_id);
    }
  }, [defaultValue]);

  const getTenant = async () => {
    await getAllTenant({ type: "list_select" })
      .then((res) => {
        const results = res.data.results;
        setListTenant(
          results?.map((res) => ({
            value: res.id,
            name: res.company_name,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getLessor = async () => {
    await getAllLessor({ type: "list_select" })
      .then((res) => {
        const results = res.data.results;
        setListLessor(
          results?.map((res) => ({
            value: res.id,
            name: res.lessor_name,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getProject = async (id) => {
    await getAllTruckTenantProject(id, { type: "list_select" })
      .then((res) => {
        const results = res.data.results;
        console.log("result project", results);
        setListProject(
          results?.map((res) => ({
            value: res.id,
            name: res.project_name,
            product: res.product_rental_type?.name,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getProjectById = async (id) => {
    await getTruckTenantProjectById(id).then((res) => {
      const results = res.data.results;
      console.log("result project id", results);
      setValue("truck_tenant_project_id", results?.id ?? null);
      setValue("truck_tenant_id", results?.truck_tenant_id ?? null);
    });
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    setIsClicked(true);
    if (props?.type === "edit") {
      await editOrderRental(defaultValue.id, data)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await createRentalOrder(data)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Modal
        open={props?.open}
        onClose={() => {
          props?.handleClose();
          reset();
        }}
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={() => {
                props?.handleClose();
                reset();
              }}
            />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              {props?.type === "edit" ? "แก้ไขรายการเช่า" : "เพิ่มรายการเช่า"}
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="topic"
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldInput
                      label="ชื่อประกาศ"
                      onChange={onChange}
                      value={value}
                      error={!!errors.topic}
                      helperText={errors.topic ? errors.topic.message : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />

              <Controller
                control={control}
                name="order_code"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="เลขที่ใบสั่งซื้อ"
                      onChange={onChange}
                      value={value}
                      error={!!errors.order_code}
                      helperText={
                        errors.order_code ? errors.order_code.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="puchase_date"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกวันที่สั่งซื้อ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldDatePicker
                      label="วันที่สั่งซื้อ"
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.puchase_date}
                      errorText={
                        errors.puchase_date ? errors.puchase_date.message : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="end_date_term"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกวันสุดท้ายของรอบ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldDatePicker
                      label="วันสุดท้ายของรอบ"
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.end_date_term}
                      errorText={
                        errors.end_date_term ? errors.end_date_term.message : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="truck_tenant_id"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณาเลือกผู้เช่า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ผู้เช่า"
                      onChange={onChange}
                      options={listTenant ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.truck_tenant_id}
                      errorText={
                        errors.truck_tenant_id
                          ? errors.truck_tenant_id.message
                          : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="truck_tenant_project_id"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณาเลือกโครงการ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="โครงการ"
                      onChange={(val) => {
                        onChange(val);
                        listProject?.forEach((ele) => {
                          console.log("element", ele, val);
                          if (ele.value === val) {
                            setValue("product_name", ele.product);
                          }
                        });
                      }}
                      options={listProject ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.truck_tenant_project_id}
                      errorText={
                        errors.truck_tenant_project_id
                          ? errors.truck_tenant_project_id.message
                          : ""
                      }
                    />
                  </div>
                )}
              />

              <Controller
                control={control}
                name="truck_lessor_id"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณาเลือกผู้ให้เช่า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ผู้ให้เช่า"
                      onChange={onChange}
                      options={listLessor ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.truck_lessor_id}
                      errorText={
                        errors.truck_lessor_id
                          ? errors.truck_lessor_id.message
                          : ""
                      }
                    />
                  </div>
                )}
              />

              <Controller
                control={control}
                name="product_name"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกชื่อสินค้า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ชื่อสินค้า"
                      onChange={onChange}
                      value={value}
                      error={!!errors.product_name}
                      helperText={
                        errors.product_name ? errors.product_name.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="tenant_amount"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกจำนวนสินค้าที่เช่า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="จำนวนสินค้าที่เช่า"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.tenant_amount}
                      helperText={
                        errors.tenant_amount ? errors.tenant_amount.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="tenant_amount_unit"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกประเภทหน่วยนับ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ประเภทหน่วยนับ"
                      onChange={onChange}
                      value={value}
                      error={!!errors.tenant_amount_unit}
                      helperText={
                        errors.tenant_amount_unit
                          ? errors.tenant_amount_unit.message
                          : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="tenant_price"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกราคาสินค้าที่เช่า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ราคาผู้เช่า (บาท)"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.tenant_price}
                      helperText={
                        errors.tenant_price ? errors.tenant_price.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={
                        props?.type === "edit" &&
                        !(
                          adminAuth?.admin_role_type === 0 ||
                          adminAuth?.admin_role_type === 4
                        ) &&
                        defaultValue?.tenant_payment_status !== 1
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="tenant_ot"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกราคา OT ผู้เช่า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ราคา OT ผู้เช่า"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.tenant_ot}
                      helperText={
                        errors.tenant_ot ? errors.tenant_ot.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={
                        props?.type === "edit" &&
                        !(
                          adminAuth?.admin_role_type === 0 ||
                          adminAuth?.admin_role_type === 4
                        ) &&
                        defaultValue?.tenant_payment_status !== 1
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="total_tenant"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="รวมราคาผู้เช่า"
                      //   onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.total_tenant}
                      errorText={
                        errors.total_tenant ? errors.total_tenant.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={true}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="lessor_price"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกราคาสินค้าที่ให้เช่า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ราคาของผู้ให้เช่า (บาท)"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.lessor_price}
                      helperText={
                        errors.lessor_price ? errors.lessor_price.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={
                        props?.type === "edit" &&
                        !(
                          adminAuth?.admin_role_type === 0 ||
                          adminAuth?.admin_role_type === 4
                        ) &&
                        defaultValue?.lessor_payment_status !== 1
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="lessor_ot"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกราคา OT ผู้ให้เช่า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ราคา OT ผู้ให้เช่า"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.lessor_ot}
                      helperText={
                        errors.lessor_ot ? errors.lessor_ot.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={
                        props?.type === "edit" &&
                        !(
                          adminAuth?.admin_role_type === 0 ||
                          adminAuth?.admin_role_type === 4
                        ) &&
                        defaultValue?.lessor_payment_status !== 1
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="total_lessor"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="รวมราคาผู้ให้เช่า"
                      //   onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.total_lessor}
                      errorText={
                        errors.total_lessor ? errors.total_lessor.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={true}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="profit_baht"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="กำไร (บาท)"
                      //   onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.profit_baht}
                      errorText={
                        errors.profit_baht ? errors.profit_baht.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={true}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="profit_percent"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="กำไร %"
                      //   onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      endAdornment="%"
                      error={!!errors.profit_percent}
                      errorText={
                        errors.profit_percent
                          ? errors.profit_percent.message
                          : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={true}
                    />
                  </div>
                )}
              />
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={() => {
                    props.handleClose();
                    reset();
                  }}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="บันทึก"
                  style={{ padding: 0 }}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                  disabled={isClicked}
                  // disabled={
                  //   adminAuth?.admin_role_type === 0 ||
                  //   adminAuth?.admin_role_type === 4
                  //     ? false
                  //     : defaultValue?.tenant_payment_status !== 1 ||
                  //       defaultValue?.lessor_payment_status !== 1
                  //     ? true
                  //     : false
                  // }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddRentalOrder;
