import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import helper from "utils/helper";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useForm, Controller } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import Line from "components/layout/line";
import InputNormal from "components/input/inputNormal";
import InputDisable from "components/input/inputDisable";
import ModalWork from "./modalWork";
import CustomModal from "components/modal/CustomModal";
const SelectPrice = (props) => {
  const {
    data,
    deposit,
    hasDeposit = () => { },
    depositAmount = () => { },
    destination = 0,
    doc_return = false,
    labor_load_amount = 0,
    labor_unload_amount = 0,
    saas = 0,
    onChange = () => { },
    generalPrice,
    is_detail = false,
    profile_type = 0,
    setGeneralPrice = () => { },
    onDemandPrice,
    setOnDemandPrice = () => { },
    predictPrice = 0,
    templatePrice = null,
    carrierInProjectPrice = null,
    setCarrierInProjectPrice = () => { },
    can_edit = false,
  } = props;
  const [showShipperPrice, setShowShipperPrice] = useState(false);
  const [shipperPriceOption, setShipperPriceOption] = useState({
    general: false,
    custom: false,
    template: false,
    onDemand: false,
  });
  const [shipperPriceCustom, setShipperPriceCustom] = useState(false);
  const [showCarrierPrice, setShowCarrierPrice] = useState(false);
  const [carrierPriceOption, setCarrierPriceOption] = useState({
    general: false,
    custom: false,
    template: false,
    carrierInProject: false,
    onDemand: false,
  });
  const [carrierPriceCustom, setCarrierPriceCustom] = useState(false);
  const [carrierInProject, setCarrierInProject] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  console.log("showQRCode", showQRCode);
  console.log("templatePrice", templatePrice);
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();
  const watchNormalPrice = watch("normal_price");
  const watchPerPoint = watch("price_per_point");
  const watchDocReturn = watch("doc_return");
  const watchLoad = watch("labor_load_amount");
  const watchUnload = watch("labor_unload_amount");
  const watchSummary = watch("summary");
  const watchCarrierPrice = watch("carrier_price");
  // useEffect(())
  console.log("data price", data);
  useEffect(() => {
    if (data?.shipper.shipper_channel_price_type === 0) {
      setShipperPriceOption({
        general: true,
        custom: false,
        template: false,
        onDemand: false,
      });
    }
    if (data?.carrier.shipper_channel_price_type === 0) {
      setCarrierPriceOption({
        general: true,
        custom: false,
        template: false,
        carrierInProject: false,
        onDemand: false,
      });
    }
    console.log("generalPrice", generalPrice);
  }, [data]);
  useEffect(() => {
    let price = 0;
    if (watchNormalPrice !== "") {
      price += parseFloat(watchNormalPrice);
    }
    if (watchPerPoint !== "") {
      price += parseFloat(watchPerPoint);
    }
    if (watchDocReturn !== "") {
      price += parseFloat(watchDocReturn);
    }
    if (watchLoad !== "") {
      // ราคาต่อคน
      // price += parseFloat(watchLoad) * labor_load_amount;
      // ราคารวม
      price += parseFloat(watchLoad);
    }
    if (watchUnload !== "") {
      // ราคาต่อคน
      //  price += parseFloat(watchUnload) * labor_unload_amount;
      // ราคารวม
      price += parseFloat(watchUnload);
    }
    setValue("summary", price);
  }, [watchNormalPrice, watchPerPoint, watchDocReturn, watchLoad, watchUnload]);

  useEffect(() => {
    // if (is_detail) {
    //   let tmp = { ...data };
    //   tmp.shipper.shipper_freight_tax =
    //     parseFloat(tmp.shipper.shipper_freight_before_tax) *
    //     (profile_type === 1 ? 0.01 : 0);
    //   tmp.shipper.shipper_freight_net =
    //     parseFloat(tmp.shipper.shipper_freight_before_tax) -
    //     parseFloat(tmp.shipper.shipper_freight_before_tax) *
    //       (profile_type === 1 ? 0.01 : 0);
    //   onChange(tmp);
    // }
  }, [profile_type]);
  const setShipperPrice = (price) => {
    console.log(price);
    let tmp = { ...data };
    tmp.shipper.shipper_transport_fee = parseFloat(price.normal_price);
    tmp.shipper.shipper_labor_load_fee =
      price.labor_load_amount !== "" ? parseFloat(price.labor_load_amount) : 0;
    tmp.shipper.shipper_labor_unload_fee =
      price.labor_unload_amount !== ""
        ? parseFloat(price.labor_unload_amount)
        : 0;
    tmp.shipper.shipper_add_drop_point_fee =
      price.price_per_point !== "" ? parseFloat(price.price_per_point) : 0;
    tmp.shipper.shipper_document_return_fee =
      price.doc_return !== "" ? parseFloat(price.doc_return) : 0;
    tmp.shipper.shipper_freight_before_tax = parseFloat(price.summary);
    tmp.shipper.shipper_freight_tax =
      parseFloat(price.summary) * (profile_type === 1 ? 0.01 : 0);
    tmp.shipper.shipper_freight_net =
      parseFloat(price.summary) -
      parseFloat(price.summary) * (profile_type === 1 ? 0.01 : 0);
    tmp.shipper.is_general = false;
    tmp.shipper.shipper_channel_price_type = 3;
    const truck_saas =
      Math.ceil(tmp.shipper.shipper_freight_before_tax * (saas / 100) * 100) /
      100;
    const carrier_price_before_tax =
      tmp.shipper.shipper_freight_before_tax - truck_saas;
    const carrier_tax = Math.ceil(carrier_price_before_tax * 0.01 * 100) / 100;
    const carrier_price_after_tax =
      Math.ceil((carrier_price_before_tax - carrier_tax) * 100) / 100 -
      tmp.carrier.carrier_penalty;

    let price_difference;
    let price_difference_percentage;
    if (can_edit) {
      price_difference =
        Math.ceil(
          (tmp.shipper.shipper_freight_before_tax -
            tmp.carrier.carrier_freight_before_tax) *
          100
        ) / 100;
      price_difference_percentage =
        Math.ceil(
          (price_difference / tmp.shipper.shipper_freight_before_tax) *
          100 *
          100
        ) / 100;
    } else {
      price_difference =
        Math.ceil(
          (tmp.shipper.shipper_freight_before_tax - carrier_price_before_tax) *
          100
        ) / 100;
      price_difference_percentage =
        Math.ceil(
          (price_difference / tmp.shipper.shipper_freight_before_tax) *
          100 *
          100
        ) / 100;
      tmp.carrier.carrier_saas_fee = truck_saas;
      tmp.carrier.carrier_freight = carrier_price_before_tax;
      tmp.carrier.carrier_freight_before_tax = carrier_price_before_tax;
      tmp.carrier.carrier_freight_tax = carrier_tax;

      tmp.carrier.carrier_freight_net =
        carrier_price_after_tax - tmp.carrier.carrier_insurance_per_trip;
      tmp.carrier.is_general = true;
      tmp.carrier.carrier_channel_price_type = 3;
      setCarrierPriceOption({
        general: true,
        custom: false,
        template: false,
        carrierInProject: false,
      });
    }

    tmp.difference.price_difference = price_difference;
    tmp.difference.price_difference_percentage = price_difference_percentage;

    onChange(tmp);
    setShipperPriceOption({ general: false, custom: true, template: false });

    setShipperPriceCustom(false);
    setShowShipperPrice(false);
  };
  const setCarrierPrice = (detail) => {
    console.log("setCarrierPrice", detail);
    let tmp = { ...data };
    const truck_saas =
      data.shipper.shipper_freight_before_tax - detail.carrier_price;
    const carrier_price_before_tax =
      tmp.shipper.shipper_freight_before_tax - truck_saas;
    const carrier_tax = Math.ceil(carrier_price_before_tax * 0.01 * 100) / 100;
    const carrier_price_after_tax =
      Math.ceil((carrier_price_before_tax - carrier_tax) * 100) / 100;

    const price_difference =
      Math.ceil(
        (tmp.shipper.shipper_freight_before_tax - carrier_price_before_tax) *
        100
      ) / 100;
    const price_difference_percentage =
      Math.ceil(
        (price_difference / tmp.shipper.shipper_freight_before_tax) * 100 * 100
      ) / 100;
    tmp.carrier.carrier_saas_fee = truck_saas;
    tmp.carrier.carrier_freight = carrier_price_before_tax;
    tmp.carrier.carrier_freight_before_tax = carrier_price_before_tax;
    tmp.carrier.carrier_freight_tax = carrier_tax;
    tmp.carrier.carrier_penalty = detail.carrier_penalty;
    tmp.carrier.carrier_freight_net =
      carrier_price_after_tax -
      tmp.carrier.carrier_insurance_per_trip -
      (detail.carrier_penalty && detail.carrier_penalty !== ""
        ? parseFloat(detail.carrier_penalty)
        : 0);
    tmp.difference.price_difference = price_difference;
    tmp.difference.price_difference_percentage = price_difference_percentage;
    tmp.carrier.is_general = false;
    tmp.carrier.carrier_channel_price_type = 3;
    onChange(tmp);
    setCarrierPriceOption({
      general: false,
      custom: true,
      template: false,
      carrierInProject: false,
    });
    setCarrierPriceCustom(false);
    setShowCarrierPrice(false);
  };
  return (
    <div>
      <div style={{ padding: 10 }}>
        <div
          style={{
            display: "flex",
            fontWeight: 500,
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            ราคาลูกค้า
            {data?.shipper?.shipper_channel_price_type === 0
              ? "ทั่วไป"
              : data?.shipper?.shipper_channel_price_type === 1
                ? "ราคาเฉลี่ย"
                : data?.shipper?.shipper_channel_price_type === 2
                  ? "ราคาแม่แบบงาน"
                  : data?.shipper?.shipper_channel_price_type === 3
                    ? "กำหนดเอง"
                    : data?.shipper?.shipper_channel_price_type === 4
                      ? " on-demand"
                      : ""}
          </div>
          <div style={{}}>
            {helper.addComma(data?.shipper.shipper_freight_before_tax) ?? "-"}{" "}
            บาท
          </div>
          {is_detail ? (
            <div style={{ padding: "0px 0px 0px 10px" }}>
              <IconButton
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: "6px",
                  padding: is_detail
                    ? "10px 10px 10px 10px"
                    : "12px 10px 12px 10px",
                }}
                onClick={() =>
                  data?.work_status > 2
                    ? setShowQRCode(!showQRCode)
                    : setShowQRCode(false)
                }
              >
                <QrCode2Icon fontSize="30" sx={{ color: "#121f43" }} />
              </IconButton>
            </div>
          ) : null}

          <div style={{ padding: "0px 0px 0px 10px" }}>
            <IconButton
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "6px",
                padding: is_detail
                  ? "10px 10px 10px 10px"
                  : "12px 10px 12px 10px",
              }}
              onClick={() => {
                if (can_edit && data.shipper.shipper_channel_price_type === 3) {
                  setValue('normal_price', data.shipper.shipper_transport_fee)
                  setValue('price_per_point', data.shipper.shipper_add_drop_point_fee)
                  setValue('doc_return', data.shipper.shipper_document_return_fee)
                  setValue('labor_load_amount', data.shipper.shipper_labor_load_fee)
                  setValue('labor_unload_amount', data.shipper.shipper_labor_unload_fee)
                }

                setShowShipperPrice(true)
              }
              }
            >
              {is_detail ? (
                <EditIcon fontSize="30" sx={{ color: "#121f43" }} />
              ) : (
                <img
                  src={require("../../assets/image/work/menubar.png")}
                  alt="menu"
                  width={22}
                />
              )}
            </IconButton>
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>ราคาลูกค้า</label>
            <label>
              {helper.addComma(data?.shipper.shipper_transport_fee) ?? "-"} บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>ค่าเพิ่มจุดส่ง</label>
            <label>
              {helper.addComma(data?.shipper.shipper_add_drop_point_fee) ?? "-"}{" "}
              บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>ค่าเอกสารนำกลับ</label>
            <label>
              {helper.addComma(data?.shipper.shipper_document_return_fee) ??
                "-"}{" "}
              บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>ค่าคนงานยกสินค้าขึ้น</label>
            <label>
              {helper.addComma(data?.shipper.shipper_labor_load_fee) ?? "-"} บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>ค่าคนงานยกสินค้าลง</label>
            <label>
              {helper.addComma(data?.shipper.shipper_labor_unload_fee) ?? "-"}{" "}
              บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>รวมราคาลูกค้าก่อนหักภาษี</label>
            <label>
              {helper.addComma(data?.shipper.shipper_freight_before_tax) ?? "-"}{" "}
              บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>หักภาษีหัก ณ ที่จ่าย</label>
            <label>
              {helper.addComma(data?.shipper.shipper_freight_tax) ?? "-"} บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>รวมราคาลูกค้าสุทธิ</label>
            <label>
              {helper.addComma(data?.shipper.shipper_freight_net) ?? "-"} บาท
            </label>
          </div>
        </div>
        <Line />

        {showQRCode === true && (
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: 500 }}>
              {data?.shipper.work_code}
            </div>
            <img
              src={data?.shipper.payment_qrcode}
              alt="qr_code"
              height={300}
              width={300}
            />
          </div>
        )}
        <Line />
        <div
          style={{
            display: "flex",
            fontWeight: 500,
            alignItems: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div style={{ flex: 1 }}>
            ราคาผู้ขนส่ง
            {data?.carrier?.carrier_channel_price_type === 0
              ? "ทั่วไป"
              : data?.carrier?.carrier_channel_price_type === 1
                ? "ราคาเฉลี่ย"
                : data?.carrier?.carrier_channel_price_type === 2
                  ? "ราคาแม่แบบงาน"
                  : data?.carrier?.carrier_channel_price_type === 3
                    ? "กำหนดเอง"
                    : data?.carrier?.carrier_channel_price_type === 4
                      ? "ราคาผู้ขนส่งเสนอในโครงการ"
                      : data?.carrier?.carrier_channel_price_type === 5
                        ? " on-demand"
                        : ""}
          </div>
          <div style={{}}>
            {helper.addComma(data?.carrier.carrier_freight_before_tax) ?? "-"}{" "}
            บาท
          </div>
          <div style={{ padding: "0px 0px 0px 20px" }}>
            <IconButton
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "6px",
                padding: is_detail
                  ? "10px 10px 10px 10px"
                  : "12px 10px 12px 10px",
              }}
              onClick={() => {
                if (can_edit && data.carrier.carrier_channel_price_type === 3) {
                  setValue('carrier_price', data.carrier.carrier_freight)
                  setValue('carrier_penalty', data.carrier.carrier_penalty)
                }
                setShowCarrierPrice(true)
              }}
            >
              {is_detail ? (
                <EditIcon fontSize="30" sx={{ color: "#121f43" }} />
              ) : (
                <img
                  src={require("../../assets/image/work/menubar.png")}
                  alt="menu"
                  width={22}
                />
              )}
            </IconButton>
          </div>
        </div>
        <Line />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>ราคาผู้ขนส่ง</label>
            <label>
              {helper.addComma(data?.carrier.carrier_freight) ?? "-"} บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>รวมราคาผู้ขนส่งก่อนหักภาษี</label>
            <label>
              {helper.addComma(data?.carrier.carrier_freight_before_tax) ?? "-"}{" "}
              บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>หักภาษีหัก ณ ที่จ่าย</label>
            <label>
              {helper.addComma(data?.carrier.carrier_freight_tax) ?? "-"} บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>หักประกันสินค้ารายเที่ยว</label>
            <label>
              {helper.addComma(data?.carrier.carrier_insurance_per_trip) ?? "-"}{" "}
              บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>หักค่าปรับอื่น ๆ</label>
            <label>
              {helper.addComma(data?.carrier.carrier_penalty) ?? "-"} บาท
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label style={{ flex: 1 }}>รวมราคาผู้ขนส่งสุทธิ</label>
            <label>
              {helper.addComma(data?.carrier.carrier_freight_net) ?? "-"} บาท
            </label>
          </div>
        </div>
        <Line />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div style={{ display: "flex", fontWeight: 500 }}>
            <div style={{ flex: 1 }}>ส่วนต่าง</div>
            <div>
              {helper.addComma(data?.difference.price_difference) ?? "-"} บาท{" "}
            </div>
            <div style={{ flex: 1, padding: "0px 0px 0px 10px" }}>ส่วนต่าง</div>
            <div>
              {helper.addComma(data?.difference.price_difference_percentage) ??
                "-"}{" "}
              %
            </div>
          </div>
        </div>
        <Line />
        <div className="post-work-select-custom">
          <FormControl variant="standard" autoFocus fullWidth margin="none">
            <InputLabel
            //  color={errors.fuel_id ? "error" : "primary"}
            >
              ค่ามัดจำ*
            </InputLabel>
            <Select
              value={deposit?.has_deposit}
              defaultValue={0}
              onChange={(val) => {
                console.log(val);
                hasDeposit(val);
                let tmp = { ...data };
                tmp.shipper.shipper_has_deposit =
                  val.target.value === 1 ? true : false;
                onChange(tmp);
              }}
              label="ค่ามัดจำ*"
            >
              <MenuItem value={0}>ไม่มี</MenuItem>
              <MenuItem value={1}>มี</MenuItem>
            </Select>
            <div className="post-work-project-id">
              <div>(ค่าเริ่มต้น: ไม่มี)</div>
            </div>
          </FormControl>
        </div>
        {deposit?.has_deposit === 1 ? (
          <InputNormal
            label="ค่ามัดจำ"
            subFirst={"(ถ้าไม่มีค่ามัดจำจะไม่สามารถระบุได้)"}
            type={"number"}
            value={deposit?.deposit_amount}
            onChange={(value) => {
              console.log(value);
              depositAmount(value);
              let tmp = { ...data };
              tmp.shipper.shipper_deposit_amount = value.target.value;
              onChange(tmp);
            }}
          />
        ) : (
          <InputDisable
            label="ค่ามัดจำ"
            subFirst={"(ถ้าไม่มีค่ามัดจำจะไม่สามารถระบุได้)"}
          />
        )}
      </div>
      {/* <ModalWork
        open={showQRCode}
        close={() => {
          setShowQRCode(false);
        }}
        enableClose
      >
        <div
          style={{ padding: "20px", display: "flex", justifyContent: "center" }}
        >
          <img
            src={data?.shipper.payment_qrcode}
            alt="qr_code"
            height={300}
            width={300}
          />
        </div>
      </ModalWork> */}
      <ModalWork
        open={showShipperPrice}
        // value={value?.product}
        close={() => {
          setShowShipperPrice(false);
          setShipperPriceCustom(false);
        }}
        confirm={() => {
          setShowShipperPrice(false);
        }}
        enableClose
        minHeight
      >
        {shipperPriceCustom ? (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ราคาลูกค้ากำหนดเอง</div>
            <Line />
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    label={"ราคาลูกค้า*"}
                    error={errors.normal_price}
                    onChange={onChange}
                    // subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                    type="number"
                  />
                )}
                name="normal_price"
                defaultValue=""
              />
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    {destination > 1 ? (
                      <InputNormal
                        value={value}
                        label={"ค่าเพิ่มจุดส่ง*"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มจุดส่งจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    ) : (
                      <InputDisable
                        value={value}
                        label={"ค่าเพิ่มจุดส่ง*"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มจุดส่งจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    )}
                  </div>
                )}
                name="price_per_point"
                defaultValue={destination > 1 ? 0 : ""}
              />
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    {doc_return ? (
                      <InputNormal
                        value={value}
                        label={"ค่าเอกสารนำกลับ"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มเอกสารนำกลับจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    ) : (
                      <InputDisable
                        value={value}
                        label={"ค่าเอกสารนำกลับ"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มเอกสารนำกลับจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    )}
                  </div>
                )}
                name="doc_return"
                defaultValue={doc_return ? 0 : ""}
              />

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    {labor_load_amount > 0 ? (
                      <InputNormal
                        value={value}
                        label={"ค่าคนงานยกสินค้าขึ้น"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มคนงานยกสินค้าขึ้นจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    ) : (
                      <InputDisable
                        value={value}
                        label={"ค่าคนงานยกสินค้าขึ้น"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มคนงานยกสินค้าขึ้นจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    )}
                  </div>
                )}
                name="labor_load_amount"
                defaultValue={labor_load_amount > 0 ? 0 : ""}
              />
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    {labor_unload_amount > 0 ? (
                      <InputNormal
                        value={value}
                        label={"ค่าคนงานยกสินค้าลง"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มคนงานยกสินค้าลงจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    ) : (
                      <InputDisable
                        value={value}
                        label={"ค่าคนงานยกสินค้าขึ้น"}
                        onChange={onChange}
                        subFirst={
                          "(ถ้าไม่เพิ่มคนงานยกสินค้าลงจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                        }
                        type="number"
                      />
                    )}
                  </div>
                )}
                name="labor_unload_amount"
                defaultValue={labor_unload_amount > 0 ? 0 : ""}
              />
              <InputDisable
                value={watchSummary}
                label={"รวมราคาลูกค้า"}
                subFirst={
                  "(ถ้าไม่เพิ่มคนงานยกสินค้าลงจะไม่สามารถระบุได้ ค่าเริ่มต้น 0)"
                }
                type="number"
              />

              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                  right: 20,
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      setShipperPriceCustom(false);
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={handleSubmit(setShipperPrice)}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ราคาลูกค้า</div>
            <Line />
            <div>
              <div
                style={{
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 10,
                  borderColor: shipperPriceOption.general ? "#121f43" : "",
                  color: shipperPriceOption.general ? "#121f43" : "",
                  cursor: "pointer",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  setGeneralPrice("shipper");
                  // if (generalPrice?.shipper?.shipper_freight_before_tax) {
                  setShipperPriceOption({
                    general: true,
                    custom: false,
                    template: false,
                    onDemand: false,
                  });
                  //   let tmp = { ...data };
                  //   tmp.shipper = generalPrice.shipper;

                  //   tmp.shipper.is_general = true;
                  //   onChange(tmp);
                  //   // setCarrierPriceOption({ general: false, custom: false });
                  // }
                }}
              >
                <div style={{ flex: 1 }}>ราคาลูกค้าทั่วไป</div>
                <div>
                  {generalPrice?.shipper?.shipper_freight_before_tax
                    ? helper.addComma(
                      generalPrice?.shipper?.shipper_freight_before_tax
                    )
                    : "-"}{" "}
                  บาท
                </div>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 10,
                  borderColor: shipperPriceOption.custom ? "#121f43" : "",
                  color: shipperPriceOption.custom ? "#121f43" : "",
                  cursor: "pointer",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  setShipperPriceCustom(true);
                }}
              >
                <div style={{ flex: 1 }}>ราคาลูกค้ากำหนดเอง</div>
                <div>
                  {watchSummary ? helper.addComma(watchSummary) : "-"} บาท
                </div>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 10,
                  borderColor: shipperPriceOption.onDemand ? "#121f43" : "",
                  color: shipperPriceOption.onDemand ? "#121f43" : "",
                  cursor: "pointer",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  setOnDemandPrice("shipper");
                  setShipperPriceOption({
                    general: false,
                    custom: false,
                    template: false,
                    onDemand: true,
                  });
                  setCarrierPriceOption({
                    general: false,
                    custom: false,
                    template: false,
                    carrierInProject: false,
                    onDemand: true,
                  });
                }}
              >
                <div style={{ flex: 1 }}>ราคาลูกค้า on-demand</div>
                <div>
                  {onDemandPrice?.shipper?.shipper_freight_before_tax
                    ? helper.addComma(
                      onDemandPrice?.shipper?.shipper_freight_before_tax
                    )
                    : "-"}{" "}
                </div>
              </div>
              {templatePrice ? (
                <div
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 10,
                    borderColor: shipperPriceOption.template ? "#121f43" : "",
                    color: shipperPriceOption.template ? "#121f43" : "",
                    cursor: "pointer",
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                  }}
                  onClick={() => {
                    // if (generalPrice?.shipper?.shipper_freight_before_tax) {
                    setShipperPriceOption({
                      general: false,
                      custom: false,
                      template: true,
                      onDemand: false,
                    });
                    setCarrierPriceOption({
                      general: false,
                      custom: false,
                      template: true,
                      carrierInProject: false,
                      onDemand: false,
                    });
                    let tmp = { ...data };
                    tmp.shipper = templatePrice.shipper;
                    tmp.shipper.shipper_freight_tax =
                      parseFloat(
                        templatePrice.shipper.shipper_freight_before_tax
                      ) * (profile_type === 1 ? 0.01 : 0);
                    tmp.shipper.shipper_freight_net =
                      parseFloat(
                        templatePrice.shipper.shipper_freight_before_tax
                      ) -
                      parseFloat(
                        templatePrice.shipper.shipper_freight_before_tax
                      ) *
                      (profile_type === 1 ? 0.01 : 0);
                    onChange(templatePrice);
                    //   // setCarrierPriceOption({ general: false, custom: false });
                    // }
                  }}
                >
                  <div style={{ flex: 1 }}>ราคาลูกค้าตามแม่แบบงาน</div>
                  <div>
                    {templatePrice?.shipper?.shipper_freight_before_tax
                      ? helper.addComma(
                        templatePrice?.shipper?.shipper_freight_before_tax
                      )
                      : "-"}{" "}
                    บาท
                  </div>
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                  right: 20,
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      setShowShipperPrice(false);
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalWork>
      <ModalWork
        open={showCarrierPrice}
        // value={value?.product}
        close={() => {
          setShowCarrierPrice(false);
          setCarrierPriceCustom(false);
        }}
        confirm={() => {
          setShowCarrierPrice(false);
        }}
        enableClose
        minHeight
      >
        {carrierPriceCustom ? (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ราคาผู้ขนส่งกำหนดเอง</div>
            <Line />
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    label={"ราคาผู้ขนส่ง*"}
                    error={errors.carrier_price}
                    onChange={onChange}
                    // subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                    type="number"
                  />
                )}
                name="carrier_price"
                defaultValue=""
              />
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    label={"ค่าปรับอื่นๆ"}
                    error={errors.carrier_penalty}
                    onChange={onChange}
                    // subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                    type="number"
                  />
                )}
                name="carrier_penalty"
                defaultValue=""
              />

              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                  right: 20,
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      setCarrierPriceCustom(false);
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={handleSubmit(setCarrierPrice)}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : carrierInProject ? (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ราคาผู้ขนส่งเสนอในโครงการ</div>
            <Line />
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#121f43",
                  color: "white",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <div style={{ width: "50%", textAlign: "center" }}>
                  ชื่อโปรไฟล์
                </div>
                <div
                  style={{
                    width: "20%",
                    textAlign: "center",
                    padding: "0px 5px 0px 10px",
                  }}
                >
                  ราคา
                </div>
                <div
                  style={{
                    width: "30%",
                    textAlign: "center",
                    padding: "0px 10px 0px 5px",
                  }}
                >
                  AMT.เคยรับ
                </div>
              </div>
              {carrierInProjectPrice?.map((ele, index) => {
                return (
                  <div
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderWidth: 1,
                      padding: 10,
                      borderColor: ele.select ? "#121f43" : "",
                      color: ele.select ? "#121f43" : "",
                      cursor: "pointer",
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                    }}
                    onClick={() => {
                      let tmpPrice = [...carrierInProjectPrice];
                      tmpPrice.forEach((e, i) => {
                        if (i === index) {
                          e.select = true;
                        } else {
                          e.select = false;
                        }
                      });
                      setCarrierInProjectPrice(tmpPrice);
                      let tmp = { ...data };
                      const truck_saas =
                        data.shipper.shipper_freight_before_tax -
                        ele.transport_fee;
                      const carrier_price_before_tax =
                        tmp.shipper.shipper_freight_before_tax - truck_saas;
                      const carrier_tax =
                        Math.ceil(carrier_price_before_tax * 0.01 * 100) / 100;
                      const carrier_price_after_tax =
                        Math.ceil(
                          (carrier_price_before_tax - carrier_tax) * 100
                        ) / 100;

                      const price_difference =
                        Math.ceil(
                          (tmp.shipper.shipper_freight_before_tax -
                            carrier_price_before_tax) *
                          100
                        ) / 100;
                      const price_difference_percentage =
                        Math.ceil(
                          (price_difference /
                            tmp.shipper.shipper_freight_before_tax) *
                          100 *
                          100
                        ) / 100;
                      tmp.carrier.carrier_saas_fee = truck_saas;
                      tmp.carrier.carrier_freight = carrier_price_before_tax;
                      tmp.carrier.carrier_freight_before_tax =
                        carrier_price_before_tax;
                      tmp.carrier.carrier_freight_tax = carrier_tax;
                      tmp.carrier.carrier_penalty = 0;
                      tmp.carrier.carrier_freight_net =
                        carrier_price_after_tax -
                        tmp.carrier.carrier_insurance_per_trip -
                        0;
                      tmp.difference.price_difference = price_difference;
                      tmp.difference.price_difference_percentage =
                        price_difference_percentage;
                      tmp.carrier.is_general = false;
                      tmp.carrier.carrier_channel_price_type = 4;
                      onChange(tmp);
                      setCarrierInProject(false);
                      setShowCarrierPrice(false);
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      {ele?.profileCarrierInProjectId?.profile?.company_name ??
                        ""}
                    </div>
                    <div
                      style={{
                        width: "20%",
                        textAlign: "center",
                        padding: "0px 5px 0px 10px",
                      }}
                    >
                      {helper.addComma(parseFloat(ele?.transport_fee ?? 0))}
                    </div>
                    <div
                      style={{
                        width: "30%",
                        textAlign: "center",
                        padding: "0px 10px 0px 5px",
                      }}
                    >
                      {"-"}
                      {/* {helper.addComma(parseFloat(ele?.transport_fee ?? 0))} */}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                position: "absolute",
                bottom: 20,
                left: 20,
                right: 20,
              }}
            >
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outline"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    color: "#8ca3ba",
                    borderColor: "#8ca3ba",
                    height: 50,
                  }}
                  color="secondary"
                  onClick={() => {
                    setCarrierInProject(false);
                  }}
                >
                  ย้อนกลับ
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ราคาผู้ขนส่ง</div>
            <Line />
            <div>
              <div
                style={{
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 10,
                  borderColor: carrierPriceOption.general ? "#121f43" : "",
                  color: carrierPriceOption.general ? "#121f43" : "",
                  cursor: "pointer",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  // console.log("generalPrice click", generalPrice);
                  setGeneralPrice("carrier");
                  // if (generalPrice?.carrier?.carrier_freight_before_tax) {
                  setCarrierPriceOption({
                    general: true,
                    custom: false,
                    template: false,
                    carrierInProject: false,
                  });
                  //   let tmp = { ...data };
                  //   tmp.carrier = generalPrice.carrier;
                  //   tmp.carrier.is_general = true;
                  //   onChange(tmp);
                  // }
                }}
              >
                <div style={{ flex: 1 }}>ราคาผู้ขนส่งทั่วไป</div>
                <div>
                  {generalPrice?.carrier?.carrier_freight_before_tax
                    ? helper.addComma(
                      generalPrice?.carrier?.carrier_freight_before_tax
                    )
                    : "-"}{" "}
                  บาท
                </div>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 10,
                  borderColor: carrierPriceOption.custom ? "#121f43" : "",
                  color: carrierPriceOption.custom ? "#121f43" : "",
                  cursor: "pointer",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  setCarrierPriceCustom(true);
                }}
              >
                <div style={{ flex: 1 }}>ราคาผู้ขนส่งกำหนดเอง</div>
                <div>
                  {watchCarrierPrice
                    ? helper.addComma(parseFloat(watchCarrierPrice))
                    : "-"}{" "}
                  บาท
                </div>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  borderStyle: "solid",
                  borderWidth: 1,
                  padding: 10,
                  borderColor: carrierPriceOption.onDemand ? "#121f43" : "",
                  color: carrierPriceOption.onDemand ? "#121f43" : "",
                  cursor: "pointer",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  // console.log("generalPrice click", generalPrice);
                  setOnDemandPrice("carrier");
                  // if (generalPrice?.carrier?.carrier_freight_before_tax) {
                  setCarrierPriceOption({
                    general: false,
                    custom: false,
                    template: false,
                    carrierInProject: false,
                    onDemand: true,
                  });
                  //   let tmp = { ...data };
                  //   tmp.carrier = generalPrice.carrier;
                  //   tmp.carrier.is_general = true;
                  //   onChange(tmp);
                  // }
                }}
              >
                <div style={{ flex: 1 }}>ราคาผู้ขนส่ง on-demand</div>
                <div>
                  {onDemandPrice?.carrier?.carrier_freight_before_tax
                    ? helper.addComma(
                      onDemandPrice?.carrier?.carrier_freight_before_tax
                    )
                    : "-"}{" "}
                  บาท
                </div>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  padding: 10,
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ flex: 1 }}>ราคาคาดคะเน</div>
                <div>
                  {predictPrice !== 0 && predictPrice !== ""
                    ? helper.addComma(parseFloat(predictPrice))
                    : "-"}{" "}
                  บาท
                </div>
              </div>
              {templatePrice ? (
                <div
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 10,
                    borderColor: carrierPriceOption.template ? "#121f43" : "",
                    color: carrierPriceOption.template ? "#121f43" : "",
                    cursor: "pointer",
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                  }}
                  onClick={() => {
                    // console.log("generalPrice click", generalPrice);
                    // setGeneralPrice("carrier");
                    // if (generalPrice?.carrier?.carrier_freight_before_tax) {
                    setCarrierPriceOption({
                      general: false,
                      custom: false,
                      template: true,
                      carrierInProject: false,
                    });
                    let tmp = { ...data };
                    const price_difference =
                      Math.ceil(
                        (tmp.shipper.shipper_freight_before_tax -
                          templatePrice.carrier.carrier_freight_before_tax) *
                        100
                      ) / 100;
                    const price_difference_percentage =
                      Math.ceil(
                        (price_difference /
                          tmp.shipper.shipper_freight_before_tax) *
                        100 *
                        100
                      ) / 100;
                    tmp.difference.price_difference = price_difference;
                    tmp.difference.price_difference_percentage =
                      price_difference_percentage;
                    tmp.carrier = templatePrice.carrier;
                    // tmp.carrier.is_general = true;
                    onChange(tmp);
                    // }
                  }}
                >
                  <div style={{ flex: 1 }}>ราคาผู้ขนส่งตามแม่แบบงาน</div>
                  <div>
                    {templatePrice?.carrier?.carrier_freight_before_tax
                      ? helper.addComma(
                        templatePrice?.carrier?.carrier_freight_before_tax
                      )
                      : "-"}{" "}
                    บาท
                  </div>
                </div>
              ) : null}
              {carrierInProjectPrice && carrierInProjectPrice.length > 0 ? (
                <div
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 10,
                    borderColor:
                      carrierInProjectPrice.filter((e) => e.select).length > 0
                        ? "#121f43"
                        : "",
                    color:
                      carrierInProjectPrice.filter((e) => e.select).length > 0
                        ? "#121f43"
                        : "",
                    cursor: "pointer",
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                  }}
                  onClick={() => {
                    setCarrierInProject(true);
                    // setCarrierPriceOption({
                    //   general: false,
                    //   custom: false,
                    //   template: true,
                    // });
                    // let tmp = { ...data };
                    // const price_difference =
                    //   Math.ceil(
                    //     (tmp.shipper.shipper_freight_before_tax -
                    //       templatePrice.carrier.carrier_freight_before_tax) *
                    //       100
                    //   ) / 100;
                    // const price_difference_percentage =
                    //   Math.ceil(
                    //     (price_difference /
                    //       tmp.shipper.shipper_freight_before_tax) *
                    //       100 *
                    //       100
                    //   ) / 100;
                    // tmp.difference.price_difference = price_difference;
                    // tmp.difference.price_difference_percentage =
                    //   price_difference_percentage;
                    // tmp.carrier = templatePrice.carrier;
                    // onChange(tmp);
                  }}
                >
                  <div style={{ flex: 1 }}>ราคาผู้ขนส่งเสนอในโครงการ</div>
                  <div>
                    {carrierInProjectPrice.filter((e) => e.select).length > 0
                      ? helper.addComma(
                        carrierInProjectPrice.filter((e) => e.select)[0]
                          .transport_fee
                      )
                      : carrierInProjectPrice[0].transport_fee
                        ? helper.addComma(carrierInProjectPrice[0].transport_fee)
                        : "-"}{" "}
                    บาท
                  </div>
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                  right: 20,
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      setShowCarrierPrice(false);
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalWork>
    </div>
  );
};
export default SelectPrice;
