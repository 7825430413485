import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuFinance } from "utils/submenuoptions";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import { getInvoice, getInvoiceXlsx } from "utils/api/finance/carrier";
import API from "../../../utils/api/work/work";
import DownloadButton from "components/button/DownloadButtoon";
import SelectRowValue from "components/button/SelectRowValue";
import AuthContext from "components/context/AuthContext";

const CarrierInvoice = () => {
  const { adminAuth } = useContext(AuthContext);
  const apiWork = API.create();
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "invoice_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "work_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [isBusy, setIsBusy] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);

  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "ข้อมูลการเงิน",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้ขนส่ง",
        path: null,
      },
      {
        label: "ตั้งเบิกแล้ว",
        path: null,
      },
    ]);
    handleSearch();
    getStatus();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(8);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const getStatus = async () => {
    apiWork
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        setWorkStatus(
          response.data.results.map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      console.log(e);
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formSearch.dateFrom).toISOString(),
      dateTo: moment(formSearch.dateTo).toISOString(),
      work_code: "",
      carrier_name: "",
      project_name: "",
      invoice_code: "",
      search: "",
      profile_type:
        formdata.profile_type !== "ALL" ? formdata.profile_type : "",
      payment_type:
        formdata.payment_type !== "ALL" ? formdata.payment_type : "",
      work_status: formdata.work_status !== "ALL" ? formdata.work_status : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getInvoice(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;
        setItems(result);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
    }
  };

  const handleOnReset = () => {
    setFormSearch({
      ...formSearch,
      profile_type: "ALL",
      payment_type: "ALL",
      work_status: "ALL",
    });
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const handleDownloadButton = async () => {
    const formdata = formSearch;
    let queryParams = {
      dateFrom: moment(formSearch.dateFrom).toISOString(),
      dateTo: moment(formSearch.dateTo).toISOString(),
      work_code: "",
      carrier_name: "",
      project_name: "",
      invoice_code: "",
      search: "",
      profile_type:
        formdata.profile_type !== "ALL" ? formdata.profile_type : "",
      payment_type:
        formdata.payment_type !== "ALL" ? formdata.payment_type : "",
      work_status: formdata.work_status !== "ALL" ? formdata.work_status : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: 1,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const res = await getInvoiceXlsx(queryParams);
      if (res) {
        helper.downloadFileFromResponse(res);
      }
    } catch (error) {}
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "invoice_date", name: "วันที่ตั้งเบิกล่าสุด" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "work_code", name: "เลขที่งาน" },
                { value: "project_name", name: "ชื่อโครงการ" },
                { value: "carrier_name", name: "ชื่อโปรไฟล์ผู้ขนส่ง" },
                { value: "invoice_code", name: "เลขที่ใบตั้งเบิก" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <FormControl>
              <SelectRowValue
                onSelect={(e) => {
                  setPagination({ ...pagination, per_page: e });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
            <AdvanceSearchButton
              onReset={handleOnReset}
              onSearch={handleSearch}
              value={formSearch}
            >
              {renderFormAdvSearch()}
            </AdvanceSearchButton>
            <DownloadButton onClick={handleDownloadButton} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormAdvSearch = () => {
    return (
      <>
        <form>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "invoice_date", name: "วันที่ตั้งเบิกล่าสุด" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </FormControl>
          <FieldDateRangePicker
            className="FieldDateRangePicker-drawer w-100"
            popupClassName="FieldDateRangePicker-drawer-popup"
            name={`วันเริ่มต้น - วันสิ้นสุด`}
            value={{
              start_date: formSearch.dateFrom,
              end_date: formSearch.dateTo,
            }}
            onChange={(e) => handleOnChangeSearch(e, "date")}
            style={{ marginBottom: "23px" }}
          />
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทโปรไฟล์ผู้ขนส่ง`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              value={formSearch.profile_type}
              onChange={(e) => handleOnChangeSearch(e, "profile_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทการชำระเงินงตาม`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "เงินสด" },
                { value: "1", name: "เครดิต" },
              ]}
              value={formSearch.payment_type}
              onChange={(e) => handleOnChangeSearch(e, "payment_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะงาน`}
              options={[{ value: "ALL", name: "ทั้งหมด" }, ...workStatus]}
              value={formSearch.work_status}
              onChange={(e) => handleOnChangeSearch(e, "work_status")}
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderTable = () => {
    const fields = [
      {
        key: "invoice_date",
        label: "วันที่ตั้งเบิก",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "invoice_code",
        label: "เลขที่งาน",
        tdclass: "text-center",
        render: "invoice",
      },
      {
        key: "carrier_name",
        label: "ชื่อโปรไฟล์ผู้ขนส่ง",
      },
      {
        key: "carrier_profile_type",
        label: "ประเภทโปรไฟล์",
        tdclass: "text-center",
      },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "carrier_payment_type",
        label: "ประเภทการรับเงินของผู้ขนส่ง",
        tdclass: "text-center",
      },
      {
        key: "work_status",
        label: "สถานะงาน",
      },
      {
        key: "finish_date",
        label: "วันที่เสร็จสิ้นงาน",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "carrier_freight_before_tax",
        label: "ราคาผู้ขนส่งก่อนหักภาษี",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_freight_tax",
        label: "ภาษีหัก ณ ที่จ่าย",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_insurance_per_trip",
        label: "ประกันภัยสินค้า",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_penalty",
        label: "ค่าปรับ",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_freight_net",
        label: "ราคาผู้ขนส่งสุทธิ",
        render: "currency",
        tdclass: "text-end ",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          type="carrier"
        />
      </>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <div className="Finance CarrierInvoice">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default CarrierInvoice;
