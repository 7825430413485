import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuFinance } from "utils/submenuoptions";
import { getPaidGroup, cancelPaid } from "utils/api/finance/shipper";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import CancelStatusButton from "components/button/finance/CancelStatusButton";
import API from "../../../utils/api/work/work";
import Loading from "components/loading/Loading";
import LoadingBackground from "components/loading/LoadingBackground";
import SelectRowValue from "components/button/SelectRowValue";
import FieldTextArea from "components/field/FieldTextArea";
import AuthContext from "components/context/AuthContext";

const ShipperReceiptGroup = () => {
  const { adminAuth } = useContext(AuthContext);
  const apiWork = API.create();
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "receipt_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "work_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);

  //submunu
  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [cancelDetail, setCancelDetail] = useState("");
  const [errorDetail, setErrorDetail] = useState(false);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  useEffect(() => {
    if (cancelDetail !== "") {
      setErrorDetail(false);
    }
  }, [cancelDetail]);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "ข้อมูลการเงิน",
        path: null,
      },
      {
        label: "ข้อมูลการเงินลูกค้า",
        path: null,
      },
      {
        label: "ประวัติการชำระ",
        path: null,
      },
    ]);
    handleSearch();
    getStatus();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(6);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      // const project_id = rowSelected[0].project_id;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.key !== rowSelected[0].key) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const getStatus = async () => {
    apiWork
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        setWorkStatus(
          response.data.results.map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      work_code: "",
      invoice_code: "",
      shipper_name: "",
      project_name: "",
      bill_code: "",
      receipt_code: "",
      search: "",
      profile_type:
        formdata.profile_type !== "ALL" ? formdata.profile_type : "",
      payment_type:
        formdata.payment_type !== "ALL" ? formdata.payment_type : "",
      work_status: formdata.work_status !== "ALL" ? formdata.work_status : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getPaidGroup(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;

        const resultWithKey = result.map((item) => {
          return { ...item, key: item.receipt_code };
        });

        const uniqueProjectIds = [
          ...new Set(resultWithKey.map((item) => item.project_id)),
        ];

        const updatedResult = resultWithKey.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;
          if (!resetCheckBox) {
            if (rowSelected.length > 0) {
              const projectID = rowSelected[0].project_id;
              rowIsActive = rowSelected.some(
                (selectedItem) => selectedItem.key === item.key
              );
              rowDisabled = projectID !== item.project_id;
            }
          }
          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
            key: item.receipt_code,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
    }
  };

  const handleOnReset = () => {
    setFormSearch({
      ...formSearch,
      profile_type: "ALL",
      payment_type: "ALL",
      work_status: "ALL",
    });
  };

  const handleOnclickUpdateStatusButton = () => {
    if (rowSelected.length > 0) {
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  const handleCancelStatus = async () => {
    if (cancelDetail === "") {
      setErrorDetail(true);
    } else {
      try {
        const body = {
          detail: cancelDetail,
        };
        const response = await cancelPaid(rowSelected[0].receipt_code, body);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Notification Error",
          error.response.data.description
        );
        return false;
      }
    }
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "receipt_date", name: "วันที่ชำระล่าสุด" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "work_code", name: "เลขที่งาน" },
                { value: "project_name", name: "ชื่อโครงการ" },
                { value: "shipper_name", name: "ชื่อโปรไฟล์ลูกค้า" },
                { value: "invoice_code", name: "เลขที่ใบแจ้งหนี้" },
                { value: "bill_code", name: "เลขที่ใบวางบิล" },
                { value: "receipt_code", name: "เลขที่ใบเสร็จ" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <CancelStatusButton
              title="ยกเลิกการชำระเงิน"
              description="ยืนยันการยกเลิกการชำระเงิน?"
              onClick={handleOnclickUpdateStatusButton}
              onConfirmCancel={handleCancelStatus}
              selectedLength={rowSelected.length}
              openNotificationWithIcon={openNotificationWithIcon}
              onClose={() => setCancelDetail("")}
            >
              <FieldTextArea
                placeholder="กรุณากรอกเหตุผลในการยกเลิก"
                onChange={(e) => setCancelDetail(e.target.value)}
                value={cancelDetail}
                error={errorDetail}
                errorMessage={"*จำเป็นต้องระบุ"}
              />
            </CancelStatusButton>
            <FormControl>
              <SelectRowValue
                onSelect={(e) => {
                  setPagination({ ...pagination, per_page: e });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
            <AdvanceSearchButton
              onReset={handleOnReset}
              onSearch={handleSearch}
              value={formSearch}
            >
              {renderFormAdvSearch()}
            </AdvanceSearchButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormAdvSearch = () => {
    return (
      <>
        <form>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "receipt_date", name: "วันที่ชำระล่าสุด" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </FormControl>
          <FieldDateRangePicker
            className="FieldDateRangePicker-drawer w-100"
            popupClassName="FieldDateRangePicker-drawer-popup"
            name={`วันเริ่มต้น - วันสิ้นสุด`}
            value={{
              start_date: formSearch.dateFrom,
              end_date: formSearch.dateTo,
            }}
            onChange={(e) => handleOnChangeSearch(e, "date")}
            style={{ marginBottom: "23px" }}
          />
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทลูกค้า`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              value={formSearch.profile_type}
              onChange={(e) => handleOnChangeSearch(e, "profile_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทการชำระเงินงตาม`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "เงินสด" },
                { value: "1", name: "เครดิต" },
              ]}
              value={formSearch.payment_type}
              onChange={(e) => handleOnChangeSearch(e, "payment_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะงาน`}
              options={[{ value: "ALL", name: "ทั้งหมด" }, ...workStatus]}
              value={formSearch.work_status}
              onChange={(e) => handleOnChangeSearch(e, "work_status")}
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderTable = () => {
    const fields = [
      {
        key: "receipt_date",
        label: "วันที่ชำระ",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "receipt_code",
        label: "เลขที่ใบเสร็จ",
        tdclass: "text-center",
      },
      {
        key: "shipper_name",
        label: "ชื่อโปรไฟล์ลูกค้า",
      },
      {
        key: "shipper_profile_type",
        label: "ประเภทโปรไฟล์",
        tdclass: "text-center",
      },
      // {
      //   key: "project_name",
      //   label: "ชื่อโครงการ",
      // },
      {
        key: "shipper_payment_type",
        label: "ประเภทการชำระเงิน",
        tdclass: "text-center",
      },
      {
        key: "shipper_freight_before_tax",
        label: "ราคาลูกค้าก่อนหักภาษี",
        render: "currency",
        tdclass: "text-end",
      },
      {
        key: "shipper_freight_tax",
        label: "ภาษีหัก ณ ที่จ่าย",
        render: "currency",
        tdclass: "text-end",
      },
      {
        key: "shipper_freight_net",
        label: "ราคาลูกค้าสุทธิ",
        render: "currency",
        tdclass: "text-end",
      },
      {
        key: "amount_of_transfer_actual",
        label: "จำนวนเงินที่ชำระจริง",
        render: "currency",
        tdclass: "text-end",
      },
      {
        key: "ginkgo_res",
        label: "สถานะ Ginkgo",
        render: "ginkgo",
        tdclass: "text-center",
      },
      {
        key: "receipt_tax_id",
        label: "รายละเอียด",
        render: "receipt_detail",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          setIsLoading={setIsLoading}
        />
      </>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <div className="Finance ShipperReceiptGroup">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
        {isLoading && <Loading />}
      </Container>
    </div>
  );
};

export default ShipperReceiptGroup;
