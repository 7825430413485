import { Button } from "antd";
import UpdateIcon from "@mui/icons-material/Update";
import ModalComponent from "components/modal/FormModal";
import {
  Box,
  Modal,
  Typography,
  Button as MuiButton,
  IconButton,
  FormHelperText,
  Avatar,
} from "@mui/material";
import { useState } from "react";

const UpdateStatusButton2way = ({
  title = "title",
  description = "description",
  textUpdateBtn = "textUpdateBtn",
  textConfirmUpdateBtn = "ยินยัน",
  textCancelBtn = "textCancelBtn",
  cancelDescription = "cancelDescription",
  updateTitle = "updateTitle",
  onClick = () => {},
  onConfirmCancel = () => {},
  onConfirmUpdate = () => {},
  onClose = () => {},
  children,
  disabledCancelBtn = false,
  selectedLength = 1,
  openNotificationWithIcon = () => {},
  childrenCancel = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handlerOnClick = async () => {
    setDisabledBtn(true);
    try {
      const result = await onClick();
      if (result) {
        setOpen(true);
        setOpenCancel(false);
        setOpenUpdate(false);
      } else {
        setOpen(false);
        setOpenCancel(false);
        setOpenUpdate(false);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาด:", error);
    } finally {
      setDisabledBtn(false);
    }
  };

  const handlerOnClickX = () => {
    setOpen(false);
    setOpenCancel(false);
    setOpenUpdate(false);
    onClose();
  };

  const handlerOnClickCancelBtn = () => {
    if (selectedLength > 1) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "โปรดยกเลิกทีละรายการ"
      );
    } else {
      setOpen(false);
      setOpenCancel(true);
      setOpenUpdate(false);
    }
  };

  const handlerOnClickUpdateBtn = () => {
    setOpen(false);
    setOpenCancel(false);
    setOpenUpdate(true);
  };

  const handlerConfrimCancel = async () => {
    setDisabledBtn(true);
    try {
      const result = await onConfirmCancel();
      if (result) {
        handlerOnClickX();
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาด:", error);
    } finally {
      setDisabledBtn(false);
    }
  };

  const handlerConfrimUpdate = async () => {
    setDisabledBtn(true);
    try {
      const result = await onConfirmUpdate();
      if (result) {
        handlerOnClickX();
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาด:", error);
    } finally {
      setDisabledBtn(false);
    }
  };

  const style_modal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    minWidth: "00px",
    borderRadius: "20px",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
    padding: "25px",
  };

  const renderXBtn = () => (
    <IconButton
      onClick={handlerOnClickX}
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M8.73655 7.5L14.9548 0.30138C15.059 0.181749 14.9714 0 14.8103 0H12.92C12.8086 0 12.702 0.0483128 12.6286 0.131135L7.5 6.06902L2.37142 0.131135C2.30035 0.0483128 2.19375 0 2.08005 0H0.189699C0.0286162 0 -0.0590318 0.181749 0.0451981 0.30138L6.26346 7.5L0.0451981 14.6986C0.0218496 14.7253 0.00687072 14.7579 0.00203912 14.7926C-0.00279249 14.8273 0.00272616 14.8626 0.0179404 14.8943C0.0331547 14.926 0.0574256 14.9529 0.0878709 14.9716C0.118316 14.9903 0.153657 15.0002 0.189699 15H2.08005C2.19139 15 2.29798 14.9517 2.37142 14.8689L7.5 8.93098L12.6286 14.8689C12.6996 14.9517 12.8062 15 12.92 15H14.8103C14.9714 15 15.059 14.8182 14.9548 14.6986L8.73655 7.5Z"
          fill="#8CA3BA"
        />
      </svg>
    </IconButton>
  );

  return (
    <>
      <Avatar
        variant="rounded"
        sx={{
          width: "40px",
          height: "40px",
          filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
          bgcolor: "white",
        }}
        onClick={handlerOnClick}
      >
        <UpdateIcon sx={{ fontSize: "35px", color: "#121F43" }} />
      </Avatar>

      <Modal open={open}>
        <Box className="text-center" sx={style_modal}>
          {renderXBtn()}
          <Typography
            id="modal-modal-title"
            sx={{
              color: "var(--dark, #121F43)",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              color: "var(--grey, #8CA3BA)",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            {description}
          </Typography>
          <MuiButton
            className="w-100"
            type="submit"
            variant="contained"
            color="primary"
            style={{
              marginTop: "20px",
              height: "50px",
              borderRadius: "10px",
              border: "1px solid #8CA3BA",
            }}
            onClick={handlerOnClickUpdateBtn}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
              {textUpdateBtn}
            </Typography>
          </MuiButton>
          <MuiButton
            className="w-100"
            type="submit"
            variant="outlined"
            color="secondary"
            style={{
              marginTop: "20px",
              height: "50px",
              borderRadius: "10px",
              border: "1px solid #8CA3BA",
            }}
            onClick={handlerOnClickCancelBtn}
            disabled={disabledCancelBtn}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "var(--grey, #8CA3BA)",
              }}
            >
              {textCancelBtn}
            </Typography>
          </MuiButton>
        </Box>
      </Modal>

      <Modal
        open={openCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="text-center" sx={style_modal}>
          {renderXBtn()}
          <Typography
            id="modal-modal-title"
            sx={{
              color: "var(--dark, #121F43)",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {textCancelBtn}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              color: "var(--grey, #8CA3BA)",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            {cancelDescription}
          </Typography>
          {childrenCancel()}
          <Box>
            <MuiButton
              type="submit"
              variant="outlined"
              color="secondary"
              style={{
                marginTop: "20px",
                height: "50px",
                width: "175px",
                borderRadius: "10px",
                border: "1px solid #8CA3BA",
                marginRight: "35px",
              }}
              onClick={handlerOnClickX}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "var(--grey, #8CA3BA)",
                }}
              >
                ยกเลิก
              </Typography>
            </MuiButton>
            <MuiButton
              type="submit"
              variant="contained"
              color="primary"
              style={{
                marginTop: "20px",
                height: "50px",
                width: "175px",
                borderRadius: "10px",
                border: "1px solid #8CA3BA",
              }}
              onClick={handlerConfrimCancel}
              disabled={disabledBtn}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                ยืนยัน
              </Typography>
            </MuiButton>
          </Box>
        </Box>
      </Modal>

      <ModalComponent
        open={openUpdate}
        title={textUpdateBtn}
        onConfirm={handlerConfrimUpdate}
        onCancel={handlerOnClickX}
        setIsModalOpen={setOpenUpdate}
        disabledComfirmBtn={disabledBtn}
        setDisableConfirm={setDisabledBtn}
        confirmTextBtn={textConfirmUpdateBtn}
      >
        {children}
      </ModalComponent>
    </>
  );
};

export default UpdateStatusButton2way;
