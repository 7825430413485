import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import ModalSelectProduct from "./modalSelectProduct";
import Swal from "sweetalert2";
import ModalWork from "./modalWork";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldDatePicker from "components/field/FieldDatePicker";
import InputDateRangeCalendar from "components/input/inputDateRangeCalendar";
import { Button } from "@mui/material";
import InputSelect from "components/input/inputSelect";
import helper from "utils/helper";
let optionTime = [
  { item: "00:00", value: "00:00", pure: 0 },
  { item: "01:00", value: "01:00", pure: 1 },
  { item: "02:00", value: "02:00", pure: 2 },
  { item: "03:00", value: "03:00", pure: 3 },
  { item: "04:00", value: "04:00", pure: 4 },
  { item: "05:00", value: "05:00", pure: 5 },
  { item: "06:00", value: "06:00", pure: 6 },
  { item: "07:00", value: "07:00", pure: 7 },
  { item: "08:00", value: "08:00", pure: 8 },
  { item: "09:00", value: "09:00", pure: 9 },
  { item: "10:00", value: "10:00", pure: 10 },
  { item: "11:00", value: "11:00", pure: 11 },
  { item: "12:00", value: "12:00", pure: 12 },
  { item: "13:00", value: "13:00", pure: 13 },
  { item: "14:00", value: "14:00", pure: 14 },
  { item: "15:00", value: "15:00", pure: 15 },
  { item: "16:00", value: "16:00", pure: 16 },
  { item: "17:00", value: "17:00", pure: 17 },
  { item: "18:00", value: "18:00", pure: 18 },
  { item: "19:00", value: "19:00", pure: 19 },
  { item: "20:00", value: "20:00", pure: 20 },
  { item: "21:00", value: "21:00", pure: 21 },
  { item: "22:00", value: "22:00", pure: 22 },
  { item: "23:00", value: "23:00", pure: 23 },
];
const SelectDateTime = (props) => {
  const [show, setShow] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const { value, onChange = () => { }, is_detail = false, is_edit } = props;
  const [optionTimeStart, setOptionTimeStart] = useState(optionTime);
  const [optionTimeEnd, setOptionTimeEnd] = useState(optionTime);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  console.log("SelectDateTime", value);

  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
      // onClick={() => {

      // }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("../../assets/image/work/datetime.png")
            }
            height={40}
            alt={"product"}
          />
        </div>
        <div
          style={{ flex: 1, display: "flex" }}
          onClick={() => {
            if (!is_detail) {
              clickShow();
            }
          }}
        >
          <div style={{ flex: 1, textAlignLast: "start" }}>
            <div
              style={{
                fontWeight: 500,
                color: value.date ? "#121f43" : "#ff316f",
                fontSize: 14,
              }}
            >
              {"ช่วงวันให้เข้ารับสินค้า*"}
            </div>
            <div style={{ fontSize: 13, opacity: value.date ? 1 : 0.3 }}>
              {value.date
                ? helper.momentTwoDate(
                  value.date.startDate,
                  value.date.endDate,
                  "short",
                  false
                )
                : "โปรดระบุช่วงวัน"}
            </div>
          </div>
          {is_detail ? null : (
            <div style={{ alignSelf: "center" }}>
              <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
            </div>
          )}
        </div>
        <div
          style={{ flex: 1, display: "flex" }}
          onClick={() => {
            if (!is_detail) setShowTime(true);
          }}
        >
          <div style={{ flex: 1, textAlignLast: "start" }}>
            <div
              style={{
                fontWeight: 500,
                color: value.time ? "#121f43" : "#ff316f",
                fontSize: 14,
              }}
            >
              {"ช่วงเวลาที่สะดวก*"}
            </div>
            <div style={{ fontSize: 13, opacity: value.time ? 1 : 0.3 }}>
              {is_detail
                ? value?.time?.time_start + " - " + value?.time?.time_end
                : value.time
                  ? (value?.time?.time_start
                    ? optionTime.filter(
                      (e) => e.value === value?.time?.time_start
                    )[0].item
                    : null) +
                  " - " +
                  (value?.time?.time_end
                    ? optionTime.filter(
                      (e) => e.value === value?.time?.time_end
                    )[0].item
                    : null)
                  : "โปรดระบุช่วงเวลา"}
            </div>
          </div>
          {is_detail ? null : (
            <div style={{ alignSelf: "center" }}>
              <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
            </div>
          )}
        </div>
      </div>
      <ModalWork
        open={show}
        close={() => setShow(false)}
        minHeight={isMobile ? true : false}
      >
        <div style={{}}>
          <InputDateRangeCalendar
            value={value.date}
            onChange={(val) => {
              console.log('select date', val, { ...value, date: val })
              onChange({ ...value, date: val });
              // setShow(false);
            }}
            is_edit={is_edit}
          />
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, margin: "0px 20px 20px 20px" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  ยืนยัน
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalWork>
      <ModalWork open={showTime} close={() => setShowTime(false)}>
        <div style={{ margin: "20px 20px 20px 20px" }}>
          <div>
            <InputSelect
              options={optionTimeStart}
              value={value?.time?.time_start}
              onChange={(val) => {
                onChange({
                  ...value,
                  time: { ...value.time, time_start: val.target.value },
                });
                let t = val.target.value.split(":");
                let timeEnd = optionTime.filter(
                  (e) => e.pure >= parseInt(t[0])
                );
                setOptionTimeEnd(timeEnd);
                if (value?.time?.time_end) {
                  let te = value?.time?.time_end.split(":");
                  if (parseInt(te[0]) < parseInt(t[0])) {
                    onChange({
                      ...value,
                      time: { ...value.time, time_end: "" },
                    });
                  }
                }
                // console.log("timeEnd", timeEnd);
                // if (value.time?.time_end && value.time?.time_start) {
                //   setShowTime(false);
                // }
              }}
              label={"ช่วงเวลาสะดวกให้เข้ารับสินค้าเริ่มต้น*"}
            />
            {value.time?.time_start ? (
              <div
                style={{
                  // opacity: 1,
                  textAlign: "center",
                  fontSize: 30,
                  fontWeight: 400,
                }}
              >
                ถึง
              </div>
            ) : null}
            {value.time?.time_start ? (
              <InputSelect
                options={optionTimeEnd}
                value={value?.time?.time_end}
                onChange={(val) => {
                  onChange({
                    ...value,
                    time: { ...value.time, time_end: val.target.value },
                  });
                  // if (value.time?.time_end && value.time?.time_start) {
                  // setShowTime(false);
                  // }
                }}
                label={"ช่วงเวลาสะดวกให้เข้ารับสินค้าสิ้นสุด*"}
              />
            ) : null}
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, margin: "0px 20px 20px 20px" }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={() => {
                      setShowTime(false);
                    }}
                    disabled={!value.time?.time_end || !value.time?.time_start}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  onClick={() => {
                    setShowTime(false);
                  }}
                >
                  ยืนยัน
                </Button>
              </div>
            </div>
          </div> */}
        </div>
      </ModalWork>
    </div>
  );
};
export default SelectDateTime;
