// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeFinanceBuyer_gridItem__kpyd- {
  padding-right: 20px;
}

.TradeFinanceBuyer_modalStyle__BobNR {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 400px;
  max-width: 400px;
  max-height: 95vh;
  padding: 25px;
  /* border: 2px solid #000; */
}

.TradeFinanceBuyer_modalStyle__BobNR :focus-visible {
  outline: none;
}

.TradeFinanceBuyer_ellipsisDetail__a1ar- {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/pages/trade/finance/buyer/TradeFinanceBuyer.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,4BAA4B;EAC5B,oBAAoB;EACpB,qBAAqB;EACrB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".gridItem {\n  padding-right: 20px;\n}\n\n.modalStyle {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  min-width: 400px;\n  max-width: 400px;\n  max-height: 95vh;\n  padding: 25px;\n  /* border: 2px solid #000; */\n}\n\n.modalStyle :focus-visible {\n  outline: none;\n}\n\n.ellipsisDetail {\n  -webkit-box-orient: vertical;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridItem": `TradeFinanceBuyer_gridItem__kpyd-`,
	"modalStyle": `TradeFinanceBuyer_modalStyle__BobNR`,
	"ellipsisDetail": `TradeFinanceBuyer_ellipsisDetail__a1ar-`
};
export default ___CSS_LOADER_EXPORT___;
