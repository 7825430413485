import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuFinance } from "utils/submenuoptions";
import UpdateStatusButton from "components/button/finance/UpdateStatusButton";
import FieldDatePicker from "components/field/FieldDatePicker";
import { createInvoice, getUnpaid } from "utils/api/finance/shipper";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import currency from "currency.js";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import UpdateStatusButtonV2 from "components/button/finance/UpdateStatusButtonV2";
import { getReceiptName } from "utils/api/finance/other";
import * as R from "ramda";
import API from "../../../utils/api/work/work";
import SelectRowValue from "components/button/SelectRowValue";
import AuthContext from "components/context/AuthContext";

const ShipperUnpiad = () => {
  const { adminAuth } = useContext(AuthContext);
  const apiWork = API.create();
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "matching_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "work_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  //updatestatus modal
  const [updateStatusForm, setUpdateStatusForm] = useState({
    actual_date: moment().format("YYYY-MM-DD kk:mm"),
    work_code: [],
    shipper_freight_before_tax: 0,
    shipper_freight_tax: 0,
    shipper_freight_net: 0,
    payment_due_date: moment().format("YYYY-MM-DD kk:mm"),
    credit_term: "",
    credit_length_date: "",
    credit_remark: "",
  });
  const [updateStatusForm2, setUpdateStatusForm2] = useState({
    receipt_name_id: "",
    profile_type: "",
    branch_code: "",
    tax_id: "",
    phone_number: "",
    address: "",
    country: "",
    province: "",
    district: "",
    subdistrict: "",
    postal_code: "",
    email: "",
  });
  const [error, setError] = useState({
    actual_date: false,
    work_code: false,
    payment_due_date: false,
  });
  const [error2, setError2] = useState({
    actual_date: false,
    work_code: false,
    payment_due_date: false,
    receipt_name_id: false,
  });

  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [receiptNameOptions, setReceiptNameOptions] = useState([]);
  const [receiptNameData, setReceiptNameData] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);
  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "ข้อมูลการเงิน",
        path: null,
      },
      {
        label: "ข้อมูลการเงินลูกค้า",
        path: null,
      },
      {
        label: "รอแจ้งหนี้",
        path: null,
      },
    ]);
    handleSearch();
    getStatus();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(0);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const shipper_id = rowSelected[0].shipper_id;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (R.isNil(item.matching_date)) {
            return { ...item, rowDisabled: true };
          } else if (item.shipper_id !== shipper_id) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (R.isNil(item.matching_date)) {
            return { ...item, rowDisabled: true };
          } else {
            return { ...item, rowDisabled: false };
          }
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const getStatus = async () => {
    apiWork
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        setWorkStatus(
          response.data.results.map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      work_code: "",
      shipper_name: "",
      project_name: "",
      search: "",
      profile_type:
        formdata.profile_type !== "ALL" ? formdata.profile_type : "",
      payment_type:
        formdata.payment_type !== "ALL" ? formdata.payment_type : "",
      work_status: formdata.work_status !== "ALL" ? formdata.work_status : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getUnpaid(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;

        const resultWithKey = result.map((item) => {
          return { ...item, key: item.work_code };
        });

        const uniqueProjectIds = [
          ...new Set(resultWithKey.map((item) => item.shipper_id)),
        ];

        const updatedResult = resultWithKey.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;
          if (R.isNil(item.matching_date)) {
            rowDisabled = true;
          } else {
            if (!resetCheckBox) {
              if (rowSelected.length > 0) {
                const shipper_id = rowSelected[0].shipper_id;
                rowIsActive = rowSelected.some((selectedItem) => {
                  return selectedItem.key === item.key;
                });
                rowDisabled = shipper_id !== item.shipper_id;
              }
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
            key: item.work_code,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "error.response.data.description"
      );
    }
  };

  const handleUpdatestatusFormChange = (name, event) => {
    if (name === "actual_date" || name === "payment_due_date") {
      const value = event;
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      const value = helper.sanitizeInput(event.target.value);
      if (name === "credit_remark") {
        const lines = value.split("\n").slice(0, 8);
        // const truncatedLines = lines.map((line) => line.slice(0, 32));
        // console.log({ truncatedLines });
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          credit_remark: lines.join("\n"),
        }));
      } else {
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleOnclickUpdateStatusButton = async () => {
    if (rowSelected.length > 0) {
      const receiptName = await getReceiptNameOptions(
        rowSelected[0].shipper_id
      );
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "work_code") {
              if (!result["work_code"]) {
              }
              result["work_code"].push(val["work_code"]);
            } else if (
              [
                "shipper_freight_before_tax",
                "shipper_freight_tax",
                "shipper_freight_net",
              ].includes(key)
            ) {
              result[key] = currency(result[key]).add(val[key]).value;
            }
          });
          return result;
        },
        {
          work_code: [],
          shipper_freight_before_tax: 0,
          shipper_freight_tax: 0,
          shipper_freight_net: 0,
        }
      );
      const beforetax = helper.formatNumeric(
        groupdata.shipper_freight_before_tax,
        "display"
      );
      const tax = helper.formatNumeric(
        groupdata.shipper_freight_tax,
        "display"
      );
      const net = helper.formatNumeric(
        groupdata.shipper_freight_net,
        "display"
      );
      setUpdateStatusForm({
        // actual_date: moment().format("YYYY-MM-DD kk:mm"),
        actual_date: moment().format("YYYY-MM-DD kk:mm"),
        work_code: groupdata.work_code,
        shipper_freight_before_tax: beforetax,
        shipper_freight_tax: tax,
        shipper_freight_net: net,
        payment_due_date: moment().format("YYYY-MM-DD kk:mm"),
        credit_term:
          rowSelected[0].project_credit_term === null
            ? ""
            : parseInt(rowSelected[0].project_credit_term),
        credit_length_date:
          rowSelected[0].project_credit_length_date === null
            ? ""
            : rowSelected[0].project_credit_length_date,
        credit_remark: "",
      });
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  const onCheckPage1 = async () => {
    const insertData = {
      work_code: updateStatusForm.work_code,
      actual_date: updateStatusForm.actual_date,
      payment_due_date: updateStatusForm.payment_due_date,
      credit_term: parseInt(updateStatusForm.credit_term),
      credit_length_date: updateStatusForm.credit_length_date,
      credit_remark: updateStatusForm.credit_remark,
    };

    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (["actual_date", "payment_due_date", "work_code"].includes(key)) {
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          value === "0.00"
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });

    const check_actual_date = moment(
      updateStatusForm.actual_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    const check_payment_due_date = moment(
      updateStatusForm.payment_due_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();

    newError.actual_date = !check_actual_date;
    newError.payment_due_date = !check_payment_due_date;
    setError(newError);
    if (Object.values(newError).every((value) => !value)) {
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
      return false;
    }
  };

  const handleSubmitUpdateStatus = async () => {
    const insertData = {
      work_code: updateStatusForm.work_code,
      actual_date: updateStatusForm.actual_date,
      payment_due_date: updateStatusForm.payment_due_date,
      credit_term: parseInt(updateStatusForm.credit_term),
      credit_length_date: updateStatusForm.credit_length_date,
      credit_remark: updateStatusForm.credit_remark,
      receipt_name_id: updateStatusForm2.receipt_name_id,
    };

    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (["actual_date", "payment_due_date", "work_code"].includes(key)) {
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          value === "0.00"
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });

    const check_actual_date = moment(
      updateStatusForm.actual_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    const check_payment_due_date = moment(
      updateStatusForm.payment_due_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();

    newError.actual_date = !check_actual_date;
    newError.payment_due_date = !check_payment_due_date;
    setError2(newError);
    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await createInvoice(insertData);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        }
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Notification Error",
          "เกิดข้อผิดพลาดบางอย่าง"
        );
        return false;
      }
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
    return false;
  };

  const handleOnReset = () => {
    setFormSearch({
      ...formSearch,
      profile_type: "ALL",
      payment_type: "ALL",
      work_status: "ALL",
    });
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const getReceiptNameOptions = async (profileId) => {
    try {
      const response = await getReceiptName(profileId);
      if (response) {
        let results = response.data.data.results;
        const selectData = results[0];
        setUpdateStatusForm2({
          ...updateStatusForm2,
          receipt_name_id: selectData.id,
          profile_type:
            selectData.profile_type === 0 ? "บุคคลธรรมดา" : "นิติบุคคล",
          branch_code:
            selectData.branch_code === null ? "" : selectData.branch_code,
          tax_id: selectData.tax_id === null ? "" : selectData.tax_id,
          phone_number:
            selectData.phone_number === null ? "" : selectData.phone_number,
          address: selectData.address === null ? "" : selectData.address,
          country: selectData.country === null ? "" : selectData.country,
          province: selectData.province === null ? "" : selectData.province,
          district: selectData.district === null ? "" : selectData.district,
          subdistrict:
            selectData.subdistrict === null ? "" : selectData.subdistrict,
          postal_code:
            selectData.postal_code === null ? "" : selectData.postal_code,
          email: selectData.email ?? "",
        });
        setReceiptNameData(results);
        setReceiptNameOptions(
          results?.map((res) => ({
            value: res.id,
            name: res.company_name,
          }))
        );
        return results;
      }
    } catch (error) {
      return "";
    }
  };

  const handlerOnchangeReceiptNameId = (receiptID) => {
    const filterData = receiptNameData.filter((item) => item.id === receiptID);
    const selectData = filterData[0];
    if (selectData) {
      setUpdateStatusForm2({
        ...updateStatusForm2,
        receipt_name_id: selectData.id,
        profile_type:
          selectData.profile_type === 0 ? "บุคคลธรรมดา" : "นิติบุคคล",
        branch_code:
          selectData.branch_code === null ? "" : selectData.branch_code,
        tax_id: selectData.tax_id === null ? "" : selectData.tax_id,
        phone_number:
          selectData.phone_number === null ? "" : selectData.phone_number,
        address: selectData.address === null ? "" : selectData.address,
        country: selectData.country === null ? "" : selectData.country,
        province: selectData.province === null ? "" : selectData.province,
        district: selectData.district === null ? "" : selectData.district,
        subdistrict:
          selectData.subdistrict === null ? "" : selectData.subdistrict,
        postal_code:
          selectData.postal_code === null ? "" : selectData.postal_code,
        email: selectData.email ?? "",
      });
    }
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "matching_date", name: "วันจับคู่งาน" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            sx={{ paddingRight: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            sx={{ paddingRight: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "work_code", name: "เลขที่งาน" },
                { value: "project_name", name: "ชื่อโครงการ" },
                { value: "shipper_name", name: "ชื่อโปรไฟล์ลูกค้า" },
                { value: "work_name", name: "ชื่อประกาศ" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            sx={{ paddingRight: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />

            {/* <UpdateStatusButton
              styles={{ marginLeft: "10px" }}
              title="แจ้งหนี้"
              onClick={handleOnclickUpdateStatusButton}
              onConfirm={handleSubmitUpdateStatus}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButton> */}
            <UpdateStatusButtonV2
              title="แจ้งหนี้"
              onClick={handleOnclickUpdateStatusButton}
              onConfirm={handleSubmitUpdateStatus}
              onCheckPage1={onCheckPage1}
              children2={renderFormUpdateStatus2()}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButtonV2>

            <FormControl>
              <SelectRowValue
                onSelect={(e) => {
                  setPagination({ ...pagination, per_page: e });
                }}
                customOptions={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                  { value: 500, name: "500" },
                  { value: 1000, name: "1000" },
                ]}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                  { value: 500, name: "500" },
                  { value: 1000, name: "1000" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
            <AdvanceSearchButton
              onReset={handleOnReset}
              onSearch={handleSearch}
              value={formSearch}
            >
              {renderFormAdvSearch()}
            </AdvanceSearchButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormUpdateStatus = () => {
    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการแจ้งหนี้ *"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={updateStatusForm.actual_date}
              onChange={(e) => handleUpdatestatusFormChange("actual_date", e)}
              showTime
              error={error.actual_date}
              helperText={error.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมจำนวนงาน"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.work_code.length}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมราคาลูกค้าก่อนหักภาษี"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.shipper_freight_before_tax}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมภาษีหัก ณ ที่จ่าย"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.shipper_freight_tax}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมราคาลูกค้าสุทธิ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.shipper_freight_net}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันที่กำหนดชำระเงิน*"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน) (ลูกค้าเงินสด load_time_end +1 วัน)"
              value={updateStatusForm.payment_due_date}
              onChange={(e) =>
                handleUpdatestatusFormChange("payment_due_date", e)
              }
              showTime
              error={error.payment_due_date}
              helperText={error.payment_due_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="จำนวนวันเครดิตเทอม"
              subLab="(ค่าเริ่มต้น: ดึงข้อมูลจากโครงการ) (ลูกค้าเครดิตไม่ต้องระบุ)"
              value={updateStatusForm.credit_term}
              onChange={(e) => handleUpdatestatusFormChange("credit_term", e)}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รอบเวลาที่นับการใช้งาน"
              subLabel="(ค่าเริ่มต้น: ดึงข้อมูลจากโครงการ) (ลูกค้าเครดิตไม่ต้องระบุ)"
              value={updateStatusForm.credit_length_date}
              onChange={(e) =>
                handleUpdatestatusFormChange("credit_length_date", e)
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="หมายเหตุ"
              value={updateStatusForm.credit_remark}
              onChange={(e) => handleUpdatestatusFormChange("credit_remark", e)}
              multiline={true}
              maxRows={4}
            />
          </FormControl>
        </form>
      </Box>
    );
  };

  const renderFormUpdateStatus2 = () => {
    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="ชื่อผู้รับใบแจ้งหนี้*"
              options={receiptNameOptions}
              value={updateStatusForm2.receipt_name_id}
              onChange={(e) => handlerOnchangeReceiptNameId(e)}
              error={error2.receipt_name_id}
              errorText={error2.receipt_name_id ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
        </form>
        <Box>
          <b>ประเภทผู้ที่รับใบแจ้งหนี้</b>
          <br />
          <span>{updateStatusForm2.profile_type}</span>
          <br />
          <br />
          <b>รหัสสาขา</b>
          <br />
          <span>{updateStatusForm2.branch_code}</span>
          <br />
          <br />
          <b>เลขประจำตัวผู้เสียภาษี</b>
          <br />
          <span>{updateStatusForm2.tax_id}</span>
          <br />
          <br />
          <b>เบอร์โทร</b>
          <br />
          <span>{updateStatusForm2.phone_number}</span>
          <br />
          <br />
          <b>อีเมล</b>
          <br />
          <span>{updateStatusForm2.email}</span>
          <br />
          <br />
          <b>ที่อยู่</b>
          <br />
          <span>{updateStatusForm2.address}</span>
          <br />
          <br />
          <b>ประเทศ</b>
          <br />
          <span>{updateStatusForm2.country.name}</span>
          <br />
          <br />
          <b>จังหวัด</b>
          <br />
          <span>{updateStatusForm2.province.name}</span>
          <br />
          <br />
          <b>เขต/อำเภอ</b>
          <br />
          <span>{updateStatusForm2.district.name}</span>
          <br />
          <br />
          <b>แขวง/ตำบล</b>
          <br />
          <span>{updateStatusForm2.subdistrict.name}</span>
          <br />
          <br />
          <b>รหัสไปรษณีย์</b>
          <br />
          <span>{updateStatusForm2.subdistrict.postal_code}</span>
        </Box>
      </Box>
    );
  };

  const renderFormAdvSearch = () => {
    return (
      <>
        <form>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "matching_date", name: "วันจับคู่งาน" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </FormControl>
          <FieldDateRangePicker
            className="FieldDateRangePicker-drawer w-100"
            popupClassName="FieldDateRangePicker-drawer-popup"
            name={`วันเริ่มต้น - วันสิ้นสุด`}
            value={{
              start_date: formSearch.dateFrom,
              end_date: formSearch.dateTo,
            }}
            onChange={(e) => handleOnChangeSearch(e, "date")}
            style={{ marginBottom: "23px" }}
          />
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทลูกค้า`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              value={formSearch.profile_type}
              onChange={(e) => handleOnChangeSearch(e, "profile_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทการชำระเงินงตาม`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "เงินสด" },
                { value: "1", name: "เครดิต" },
              ]}
              value={formSearch.payment_type}
              onChange={(e) => handleOnChangeSearch(e, "payment_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะงาน`}
              options={[{ value: "ALL", name: "ทั้งหมด" }, ...workStatus]}
              value={formSearch.work_status}
              onChange={(e) => handleOnChangeSearch(e, "work_status")}
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderTable = () => {
    const fields = [
      {
        key: "matching_date",
        label: "วันที่จับคู่งาน",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "work_code",
        label: "เลขที่งาน",
        // tdclass: "text-center",
      },
      {
        key: "work_name",
        label: "ชื่อประกาศ",
      },
      {
        key: "shipper_name",
        label: "ชื่อโปรไฟล์ลูกค้า",
      },
      {
        key: "shipper_profile_type",
        label: "ประเภทโปรไฟล์",
        tdclass: "text-center",
      },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "shipper_payment_type",
        label: "ประเภทการชำระเงิน",
        tdclass: "text-center",
      },
      {
        key: "receipt_tax_id",
        label: "ชื่อใบเสร็จ",
        render: "receipt_name",
      },
      {
        key: "work_status",
        label: "สถานะงาน",
      },
      {
        key: "finish_date",
        label: "วันที่เสร็จสิ้นงาน",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "shipper_freight_before_tax",
        label: "ราคาลูกค้าก่อนหักภาษี",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "shipper_freight_tax",
        label: "ภาษีหัก ณ ที่จ่าย",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "shipper_freight_net",
        label: "ราคาลูกค้าสุทธิ",
        render: "currency",
        tdclass: "text-end ",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
        />
      </>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <div className="Finance ShipperUnpiad">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default ShipperUnpiad;
