export const subMenuDasdboard = [
  {
    label: "แผนภูมิและกราฟปริมาณงาน",
    path: "/dashboard/work",
  },
  {
    label: "แผนภูมิและกราฟรายได้",
    path: "/dashboard/income",
  },
  {
    label: "แผนภูมิและกราฟผู้ใช้งาน",
    path: "/dashboard/profile",
  },
  {
    label: "แผนภูมิและกราฟรถ",
    path: "/dashboard/truck",
  },
  {
    label: "KPI",
    path: "/dashboard/kpi",
  },
];

export const subMenuFinance = [
  {
    label: "รอแจ้งหนี้",
    path: "/finance/shipper/unpaid",
    header: "ข้อมูลการเงินลูกค้า",
  },
  {
    label: "แจ้งหนี้แล้ว",
    path: "/finance/shipper/invoice",
  },
  {
    label: "ประวัติการแจ้งหนี้",
    path: "/finance/shipper/invoice-group",
  },
  {
    label: "วางบิลแล้ว",
    path: "/finance/shipper/billingnote",
  },
  {
    label: "ประวัติการวางบิล",
    path: "/finance/shipper/billingnote-group",
  },
  {
    label: "ชำระแล้ว",
    path: "/finance/shipper/paid",
  },
  {
    label: "ประวัติการชำระ",
    path: "/finance/shipper/paid-group",
  },
  {
    label: "รอตั้งเบิก",
    path: "/finance/carrier/unpaid",
    header: "ข้อมูลการเงินผู้ขนส่ง",
  },
  {
    label: "ตั้งเบิกแล้ว",
    path: "/finance/carrier/invoice",
  },
  {
    label: "ประวัติการตั้งเบิก",
    path: "/finance/carrier/invoice-group",
  },
  {
    label: "เตรียมแจ้งโอนแล้ว",
    path: "/finance/carrier/billingnote",
  },
  {
    label: "ประวัติการเตรียมแจ้งโอน",
    path: "/finance/carrier/billingnote-group",
  },
  {
    label: "แจ้งโอนแล้ว",
    path: "/finance/carrier/paid",
  },
  {
    label: "ประวัติการแจ้งโอน",
    path: "/finance/carrier/paid-group",
  },
  {
    label: "แก้ไขการเงินลูกค้า",
    path: "/finance/shipper/edit-transaction",
    header: "แก้ไขข้อมูล",
    fix_role: [0, 4],
  },
  {
    label: "แก้ไขการเงินผู้ขนส่ง",
    path: "/finance/carrier/edit-transaction",
    fix_role: [0, 4],
  },
  // {
  //   label: "รอตั้งเบิก",
  //   path: "/finance/tps/unpaid",
  //   header: "TPS",
  // },
  // {
  //   label: "ตั้งเบิกแล้ว",
  //   path: "/finance/tps/invoice",
  // },
  // {
  //   label: "ประวัติการตั้งเบิก",
  //   path: "/finance/tps/invoice-group",
  // },
];

export const subMenuAdmin = [
  {
    label: "จัดการผู้ดูแลระบบ",
    path: "/",
  },
];

export const subMenuAdminID = [
  {
    label: "รายละเอียดผู้ดูและระบบ",
    path: "",
    active: true,
  },
];

export const subMenuMasterData = [
  {
    label: "Term&Condition",
    path: "/master-data/term-and-condition",
    header: "ระบบงานขนส่ง",
  },
  {
    label: "Privacy Policy",
    path: "/master-data/privacy-policy",
  },
  {
    label: "พื้นที่ให้บริการ",
    path: "/master-data/service-area",
  },
  {
    label: "เส้นทางจังหวัดที่ให้บริการ (FTL-Factor)",
    path: "/master-data/route-province",
  },
  {
    label: "เส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)",
    path: "/master-data/route-stl-standard-size",
  },
  {
    label: "ประเภทรถ",
    path: "/master-data/truck-type",
  },
  {
    label: "อัตรานํ้ามัน",
    path: "/master-data/fuel-rate",
  },
  // {
  //   label: "ประกันภัยสินค้ารายปี",
  //   path: "/master-data/annual-insurance",
  // },
  {
    label: "อุปกรณ์เสริมติดรถ",
    path: "/master-data/accessories",
  },
  {
    label: "ประเภทสินค้า",
    path: "/master-data/product-type",
  },
  {
    label: "อัพเดทเวอร์ชัน WeMove",
    path: "/master-data/app-version/wemove-version",
  },
  {
    label: "อัพเดทเวอร์ชัน WeMoveDrive",
    path: "/master-data/app-version/wemovedrive-version",
  },
  {
    label: "Third Party Key",
    path: "/master-data/third-party-key",
  },
  {
    label: "ประเภทสินค้าซื้อขาย",
    path: "/master-data/product-trade-type",
    header: "ระบบซื้อขาย",
  },
  {
    label: "อุสาหกรรม",
    path: "/master-data/industry",
  },
  {
    label: "ประเภทสินค้าเช่า",
    path: "/master-data/product-rental-type",
    header: "ระบบเช่ารถ",
  },
];

export const subMenuUser = [
  {
    label: "ข้อมูลผู้ใช้งาน",
    path: "",
  },
  {
    label: "ติดตามสถานะเอกสารผู้ใช้งานทั้งหมด",
    path: "/user-tracking",
  },
  {
    label: "ติดตามคนขับทั้งหมด",
    path: "/tracking-driver",
  },
];

export const subMenuTruck = [
  {
    label: "ข้อมูลรถ",
    path: "/truck",
  },
  {
    label: "ติดตามสถานะเอกสารทั้งหมด",
    path: "/",
  },
];

export const subMenuPostWork = [
  {
    label: "ประกาศงานรายการเดียว",
    path: "/post-work",
  },
  {
    label: "ประกาศงานแบบใช้ไฟล์นำเข้า",
    path: "/import-work",
  },
  {
    label: "จัดเส้นทาง",
    path: "/route-optimize",
  },
  {
    label: "จัดการงานประจำ",
    path: "/work-regular",
  },
];

export const subMenuTradeFinance = [
  {
    label: "รอแจ้งหนี้",
    path: "/trade-finance",
    header: "ข้อมูลการเงินผู้ซื้อ",
  },
  {
    label: "แจ้งหนี้แล้ว",
    path: "/trade-finance/buyer/invoice",
  },
  {
    label: "ประวัติการแจ้งหนี้",
    path: "/trade-finance/buyer/invoice-group",
  },
  {
    label: "วางบิลแล้ว",
    path: "/trade-finance/buyer/billingnote",
  },
  {
    label: "ประวัติการวางบิล",
    path: "/trade-finance/buyer/billingnote-group",
  },
  {
    label: "ชำระแล้ว",
    path: "/trade-finance/buyer/paid",
  },
  {
    label: "ประวัติการชำระ",
    path: "/trade-finance/buyer/paid-group",
  },
  {
    label: "รอตั้งเบิก",
    path: "/trade-finance/seller/unpaid",
    header: "ข้อมูลการเงินผู้ขาย",
  },
  {
    label: "ตั้งเบิกแล้ว",
    path: "/trade-finance/seller/invoice",
  },
  {
    label: "ประวัติการตั้งเบิก",
    path: "/trade-finance/seller/invoice-group",
  },
  {
    label: "เตรียมแจ้งโอนแล้ว",
    path: "/trade-finance/seller/billingnote",
  },
  {
    label: "ประวัติการเตรียมแจ้งโอน",
    path: "/trade-finance/seller/billingnote-group",
  },
  {
    label: "แจ้งโอนแล้ว",
    path: "/trade-finance/seller/paid",
  },
  {
    label: "ประวัติการแจ้งโอน",
    path: "/trade-finance/seller/paid-group",
  },
  {
    label: "รอตั้งเบิก",
    path: "/trade-finance/carrier/unpaid",
    header: "ข้อมูลการเงินผู้ขนส่ง",
  },
  {
    label: "ตั้งเบิกแล้ว",
    path: "/trade-finance/carrier/invoice",
  },
  {
    label: "ประวัติการตั้งเบิก",
    path: "/trade-finance/carrier/invoice-group",
  },
  {
    label: "เตรียมแจ้งโอนแล้ว",
    path: "/trade-finance/carrier/billingnote",
  },
  {
    label: "ประวัติการเตรียมแจ้งโอน",
    path: "/trade-finance/carrier/billingnote-group",
  },
  {
    label: "แจ้งโอนแล้ว",
    path: "/trade-finance/carrier/paid",
  },
  {
    label: "ประวัติการแจ้งโอน",
    path: "/trade-finance/carrier/paid-group",
  },
  {
    label: "แก้ไขการเงินผู้ซื้อ",
    path: "/trade-finance/buyer/edit-transaction",
    header: "แก้ไขข้อมูล",
    fix_role: [0, 4],
  },
  {
    label: "แก้ไขการเงินผู้ขาย",
    path: "/trade-finance/seller/edit-transaction",
    fix_role: [0, 4],
  },
  {
    label: "แก้ไขการเงินผู้ขนส่ง",
    path: "/trade-finance/carrier/edit-transaction",
    fix_role: [0, 4],
  },
];

export const subMenuRentalFinance = [
  {
    label: "รอแจ้งหนี้",
    path: "/truck-rental/finance/tenant/unpaid",
    header: "ข้อมูลการเงินผู้เช่า",
  },
  {
    label: "แจ้งหนี้แล้ว",
    path: "/truck-rental/finance/tenant/invoice",
  },
  {
    label: "ประวัติการแจ้งหนี้",
    path: "/truck-rental/finance/tenant/invoice-group",
  },
  {
    label: "วางบิลแล้ว",
    path: "/truck-rental/finance/tenant/billingnote",
  },
  {
    label: "ประวัติการวางบิล",
    path: "/truck-rental/finance/tenant/billingnote-group",
  },
  {
    label: "ชำระแล้ว",
    path: "/truck-rental/finance/tenant/paid",
  },
  {
    label: "ประวัติการชำระ",
    path: "/truck-rental/finance/tenant/paid-group",
  },
  {
    label: "รอตั้งเบิก",
    path: "/truck-rental/finance/lessor/unpaid",
    header: "ข้อมูลการเงินผู้ให้เช่า",
  },
  {
    label: "ตั้งเบิกแล้ว",
    path: "/truck-rental/finance/lessor/invoice",
  },
  {
    label: "ประวัติการตั้งเบิก",
    path: "/truck-rental/finance/lessor/invoice-group",
  },
  {
    label: "เตรียมแจ้งโอนแล้ว",
    path: "/truck-rental/finance/lessor/billingnote",
  },
  {
    label: "ประวัติการเตรียมแจ้งโอน",
    path: "/truck-rental/finance/lessor/billingnote-group",
  },
  {
    label: "แจ้งโอนแล้ว",
    path: "/truck-rental/finance/lessor/paid",
  },
  {
    label: "ประวัติการแจ้งโอน",
    path: "/truck-rental/finance/lessor/paid-group",
  },
  {
    label: "แก้ไขการเงินผู้เช่า",
    path: "/truck-rental/finance/tenant/edit-transaction",
    header: "แก้ไขข้อมูล",
    fix_role: [0, 4],
  },
  {
    label: "แก้ไขการเงินผู้ให้เช่า",
    path: "/truck-rental/finance/lessor/edit-transaction",
    fix_role: [0, 4],
  },
];
