import instance from "../../axiosInstance";

const getDashboardWork = async (param) => {
  try {
    return instance.get(`/api/new-dashboard/work`, {
      baseURL: process.env.REACT_APP_API_EXPORT_DATA_ENPOINT,
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export { getDashboardWork };
