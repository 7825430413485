import instance from "../../axiosInstance";
import helper from "utils/helper";
const getAllProductTradeType = async (param) => {
  try {
    return instance.get(`/api/master/product-trade-type`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createProductTradeType = async (body) => {
  try {
    return instance.post(`/api/master/add-product-trade-type`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const editProductTradeType = async (id, body) => {
  try {
    return instance.patch(`/api/master/edit-product-trade-type/${id}`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const getAllIndustry = async (param) => {
  try {
    return instance.get(`/api/master/industry`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createIndustry = async (body) => {
  try {
    return instance.post(`/api/master/create-industry`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const editIndustry = async (id, body) => {
  try {
    return instance.patch(`/api/master/edit-industry/${id}`, body);
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getAllProductTradeType,
  createProductTradeType,
  editProductTradeType,
  getAllIndustry,
  createIndustry,
  editIndustry,
};
