import React, { useState, useEffect } from "react";
import styles from "../TruckRental.module.css";
import { Modal, Typography } from "@mui/material";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldSelect from "components/field/FieldSelect";
import ButtonCustom from "components/ButtonCustom";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import {
  getAllCountry,
  getAllZone,
  getProvince,
  getDistrict,
  getSubDistrict,
} from "utils/api/masterData/gis";
import {
  createTenant,
  editTenant,
  getTenantById,
} from "utils/api/truckRental/truckRental";

const ModalAddTenant = (props) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      tax_id: "",
    },
  });

  const [country, setListCountry] = useState([]);
  const [zone, setListZone] = useState([]);
  const [province, setListProvince] = useState([]);
  const [district, setListDistrict] = useState([]);
  const [subDistrict, setListSubDistrict] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const country_id = watch("country_id");
  const zone_id = watch("zone_id");
  const province_id = watch("province_id");
  const district_id = watch("district_id");
  const subdistrict_id = watch("subdistrict_id");

  console.log("props", props);
  useEffect(() => {
    if (props.idTenant && props.type === "edit") {
      getTenant(props.idTenant);
    }
  }, [props.idTenant]);
  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    setIsClicked(false);
  }, [props?.open]);

  useEffect(() => {
    if (country_id) {
      getZone();
    }
  }, [country_id]);
  useEffect(() => {
    if (zone_id) {
      getProvoinceByZone(zone_id);
    }
  }, [zone_id]);
  useEffect(() => {
    if (province_id) {
      getDistrictByProvince(province_id);
    }
  }, [province_id]);
  useEffect(() => {
    if (district_id) {
      getSubDistrictByDistrict(district_id);
    }
  }, [district_id]);
  useEffect(() => {
    if (subdistrict_id && subDistrict) {
      console.log("subDistrict", subDistrict);
      console.log("subdistrict_id", subdistrict_id);
      const postcode = subDistrict.find(
        (element) => element.value == subdistrict_id
      );
      setValue("postal_code", postcode?.postal_code);
    }
  }, [subdistrict_id, subDistrict]);

  const handleMaxDigit = (e, field, max) => {
    const newValue = e.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };
  const getCountry = async () => {
    await getAllCountry().then((res) => {
      const results = res.data.data.results;
      setListCountry(
        results?.map((res) => ({ value: res.id, name: res.name }))
      );
    });
  };
  const getZone = async () => {
    await getAllZone().then((res) => {
      const results = res.data.data.results;
      setListZone(results?.map((res) => ({ value: res.id, name: res.name })));
    });
  };
  const getProvoinceByZone = async (id) => {
    await getProvince(id).then((res) => {
      const results = res.data.data.results;
      setListProvince(
        results?.map((res) => ({ value: res.id, name: res.name }))
      );
    });
  };
  const getDistrictByProvince = async (id) => {
    await getDistrict(id).then((res) => {
      const results = res.data.data.results;
      setListDistrict(
        results?.map((res) => ({
          value: res.id,
          name: res.name,
        }))
      );
    });
  };
  const getSubDistrictByDistrict = async (id) => {
    await getSubDistrict(id).then((res) => {
      const results = res.data.data.results;
      setListSubDistrict(
        results?.map((res) => ({
          value: res.id,
          name: res.name,
          postal_code: res?.postal_code,
        }))
      );
    });
  };

  const getTenant = async (id_cus) => {
    await getTenantById(id_cus)
      .then((res) => {
        const results = res.data.results;

        console.log("results", results);
        setValue("tenant_code", results?.tenant_code);
        setValue("company_name", results?.company_name);
        setValue("tax_id", results?.tax_id);
        setValue("certificate_file", results?.certificate_file);
        setValue("branch_code", results?.branch_code);
        setValue("address", results?.address);
        setValue("email", results?.email);
        setValue("country_id", results?.country_id);
        setValue("zone_id", results?.zone_id);
        setValue("province_id", results?.province_id);
        setValue("district_id", results?.district_id);
        setValue("subdistrict_id", results?.subdistrict_id);
        setValue("active", results?.active);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const onSubmit = async (data) => {
    setIsClicked(true);
    if (props.type === "create") {
      const GForm = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) {
          GForm.delete(key);
        } else {
          GForm.append(key, data[key]);
        }
      });

      GForm.delete("postal_code");
      await createTenant(GForm)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error.response.data.description);
          setError("tax_id", {
            type: "error",
            message: error.response.data.description,
          });
        });
    } else if (props.type === "edit") {
      const GForm = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) {
          GForm.delete(key);
        } else {
          GForm.append(key, data[key]);
        }
      });
      GForm.delete("postal_code");
      await editTenant(props.idTenant, GForm)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          setError("tax_id", {
            type: "error",
            message: error.response.data.description,
          });
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Modal
        open={props?.open}
        onClose={() => {
          props?.handleClose();
          reset();
        }}
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={() => {
                props?.handleClose();
                reset();
              }}
            />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              {props.type === "create"
                ? "เพิ่มรายชื่อผู้เช่า"
                : "แก้ไขรายชื่อผู้เช่า"}
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {props.type === "edit" && (
                <Controller
                  control={control}
                  name="tenant_code"
                  render={({ field: { onChange, value } }) => (
                    <div style={{ paddingTop: "20px" }}>
                      <FieldInput
                        label="รหัสลูกค้า"
                        readOnly={true}
                        value={value}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}

              <Controller
                control={control}
                name="company_name"
                rules={{
                  required:
                    props.type === "create" ? "กรุณากรอกชื่อบริษัท" : null,
                }}
                render={({ field: { onChange, value } }) => (
                  <div
                    style={{ paddingTop: props.type === "create" ? "20px" : 0 }}
                  >
                    <FieldInput
                      label="ชื่อบริษัท"
                      onChange={onChange}
                      value={value}
                      error={!!errors.company_name}
                      helperText={
                        errors.company_name ? errors.company_name.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="tax_id"
                rules={{
                  required:
                    props.type === "create"
                      ? "กรุณากรอกเลขประจําตัวผู้เสียภาษี"
                      : null,
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "ระบุเป็นตัวเลขได้เท่านั้น",
                  },
                  minLength: {
                    value: 13,
                    message: "เลขประจําตัวผู้เสียภาษีต้องมี 13 ตัว",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="เลขประจําตัวผู้เสียภาษี"
                      onChange={(e) => handleMaxDigit(e, "tax_id", 13)}
                      value={value}
                      type="number"
                      error={!!errors.tax_id}
                      helperText={errors.tax_id ? errors.tax_id.message : ""}
                      style={{ width: "100%" }}
                      maxLength={13}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="certificate_file"
                rules={{
                  required:
                    props.type === "create"
                      ? "กรุณาอัพโหลดไฟล์หนังสือรับรอง"
                      : null,
                }}
                render={({ field: { onChange, value } }) => (
                  <FieldUpload
                    name={"ไฟล์หนังสือรับรอง"}
                    // accept="image/jpeg,image/png"
                    icon={<UploadFile />}
                    value={value} //คลิกเพื่อเปิด
                    onChange={onChange}
                    // url={dataDetail.image}
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    error={!!errors.certificate_file}
                    textError={
                      errors.certificate_file
                        ? errors.certificate_file.message
                        : ""
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="branch_code"
                rules={{
                  required:
                    props.type === "create" ? "กรุณากรอกรหัสสาขา" : null,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="รหัสสาขา"
                      onChange={(e) => handleMaxDigit(e, "branch_code", 5)}
                      value={value}
                      error={!!errors.branch_code}
                      helperText={
                        errors.branch_code ? errors.branch_code.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="address"
                rules={{
                  required: props.type === "create" ? "กรุณากรอกที่อยู่" : null,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ที่อยู่"
                      onChange={onChange}
                      value={value}
                      error={!!errors.address}
                      helperText={errors.address ? errors.address.message : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="email"
                rules={{
                  required: props.type === "create" ? "กรุณากรอกอีเมล" : null,
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "รูปแบบอีเมลไม่ถูกต้อง",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="อีเมล"
                      onChange={onChange}
                      value={value}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="country_id"
                rules={{
                  required: props.type === "create" ? "กรุณากรอกประเทศ" : null,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ประเทศ"
                      onChange={onChange}
                      options={country ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.country_id}
                      errorText={
                        errors.country_id ? errors.country_id.message : ""
                      }
                    />
                  </div>
                )}
              />
              {country_id && (
                <Controller
                  control={control}
                  name="zone_id"
                  rules={{
                    required:
                      props.type === "create" ? "กรุณากรอกภูมิภาค" : null,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="ภูมิภาค"
                        onChange={onChange}
                        options={zone ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.zone_id}
                        errorText={errors.zone_id ? errors.zone_id.message : ""}
                      />
                    </div>
                  )}
                />
              )}
              {zone_id && (
                <Controller
                  control={control}
                  name="province_id"
                  rules={{
                    required:
                      props.type === "create" ? "กรุณากรอกจังหวัด" : null,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="จังหวัด"
                        onChange={onChange}
                        options={province ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.province_id}
                        errorText={
                          errors.province_id ? errors.province_id.message : ""
                        }
                      />
                    </div>
                  )}
                />
              )}
              {province_id && (
                <Controller
                  control={control}
                  name="district_id"
                  rules={{
                    required:
                      props.type === "create" ? "กรุณากรอกอําเภอ" : null,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="อําเภอ"
                        onChange={onChange}
                        options={district ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.district_id}
                        errorText={
                          errors.district_id ? errors.district_id.message : ""
                        }
                      />
                    </div>
                  )}
                />
              )}
              {district_id && (
                <Controller
                  control={control}
                  name="subdistrict_id"
                  rules={{
                    required: props.type === "create" ? "กรุณากรอกตําบล" : null,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="ตําบล"
                        onChange={onChange}
                        options={subDistrict ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.subdistrict_id}
                        errorText={
                          errors.subdistrict_id
                            ? errors.subdistrict_id.message
                            : ""
                        }
                      />
                    </div>
                  )}
                />
              )}
              {subdistrict_id && (
                <Controller
                  control={control}
                  name="postal_code"
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldInput
                        label="รหัสไปรษณีย์"
                        // onChange={onChange}
                        readOnly={true}
                        value={value}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}
              {props.type === "edit" && (
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="สถานะการใช้งาน"
                        onChange={onChange}
                        options={[
                          { value: true, name: "ใช้งาน" },
                          { value: false, name: "ระงับ" },
                        ]}
                        value={value}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={() => {
                    props?.handleClose();
                    reset();
                  }}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name={"บันทึก"}
                  // type="submit"
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                  disabled={isClicked}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddTenant;
