import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import ModalSelectProduct from "./modalSelectProduct";
import Swal from "sweetalert2";
import ModalSelectService from "./modalSelectService";
import ModalWork from "./modalWork";
import Line from "components/layout/line";

const SelectCarrier = (props) => {
  const [show, setShow] = useState(false);
  const { carrier, onChange = () => { }, driver, truck } = props;

  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
  };
  // useEffect(() => {
  //   console.log("carrier", carrier);
  //   console.log("driver", driver);
  //   console.log("truck", truck);
  // }, [carrier, driver, truck]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          //   alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          clickShow();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={require("../../assets/image/work/carrier.png")}
            height={40}
            alt={"carrier"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div
            style={{
              fontWeight: 500,
              opacity: 1,
            }}
          >
            {"ผู้ขนส่งที่รับงาน"}
          </div>
          <div
            style={{
              fontSize: 12,
              opacity: 1,
            }}
          >
            ผู้ขนส่ง: {carrier?.company_name ?? ""}{" "}
            {carrier?.user_info?.phone ?? "-"}
            <br />
            คนขับ: {driver ? driver.firstname + " " + driver.lastname : "-"}{" "}
            {driver?.phone ?? ""}
            <br />
            ทะเบียนรถ:{" "}
            {truck
              ? truck.license_plate_head +
              (truck.license_plate_tail && truck.license_plate_tail !== ""
                ? " " + truck.license_plate_tail
                : "")
              : "-"}
          </div>
        </div>
        {/* <div style={{ alignSelf: "center" }}>
          <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
        </div> */}
      </div>
      {/* <ModalWork open={show} minHeight enableClose close={() => setShow(false)}>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>ชื่อในการออกใบเสร็จ</div>
          <Line />
        </div>
      </ModalWork> */}
    </div>
  );
};
export default SelectCarrier;
