import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuTradeFinance } from "utils/submenuoptions";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import { getInvoice } from "utils/api/trade-finance/seller";
import SelectRowValue from "components/button/SelectRowValue";
import AuthContext from "components/context/AuthContext";

const TradeSellerInvoice = () => {
  const { adminAuth } = useContext(AuthContext);
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "invoice_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "order_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "จัดการการซื้อขาย",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้ขาย",
        path: null,
      },
      {
        label: "ตั้งเบิกแล้ว",
        path: null,
      },
    ]);
    handleSearch();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuTradeFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(8);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const order_code = rowSelected[0].order_code;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.order_code !== order_code) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getInvoice(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;
        const uniqueProjectIds = [
          ...new Set(result.map((item) => item.order_code)),
        ];

        const updatedResult = result.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;

          if (!resetCheckBox) {
            if (rowSelected.length > 0) {
              const order_code = rowSelected[0].order_code;
              rowIsActive = rowSelected.some((selectedItem) => {
                return selectedItem.key === item.key;
              });
              rowDisabled = order_code !== item.order_code;
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "error.response.data.description"
      );
    }
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "invoice_date", name: "วันที่ตั้งเบิกล่าสุด" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "order_code", name: "เลขที่คําสั่งซื้อ" },
                { value: "invoice_code", name: "เลขที่ใบตั้งเบิก" },
                { value: "id", name: "ไอดี" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <FormControl>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const renderTable = () => {
    const fields = [
      {
        key: "key",
        label: "เลขที่เอกสาร",
        tdclass: "text-center",
        render: "trade_invoice",
      },
      {
        key: "actual_date",
        label: "วันที่ตั้งเบิก",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "sent_date",
        label: "กำหนดวันส่ง",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "customer_name",
        label: "ผู้ซื้อ",
      },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "destination_name",
        label: "ชื่อจุดส่ง",
        tdclass: "text-center",
      },
      {
        key: "trade_jobber",
        label: "ผู้ขาย",
        tdclass: "text-center",
      },
      {
        key: "buy_amount_per_trip",
        label: "จำนวนสินค้าที่ซื้อ",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "buy_per_trip",
        label: "ราคาสินค้าที่ซื้อ (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "sell_per_trip",
        label: "ราคาสินค้าที่ขาย (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "profit_bath",
        label: "กำไร (บาท)",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "profit_percent",
        label: "กำไร (%)",
        tdclass: "text-end",
        render: "percent",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          type="seller"
        />
      </>
    );
  };

  return (
    <div className="Finance ShipperUnpiad">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default TradeSellerInvoice;
