import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./modalSelectMap.css";
import { Button, TextField, InputAdornment } from "@mui/material";
import {
  Search,
} from "@mui/icons-material";
import Line from "components/layout/line";
import { useForm, Controller } from "react-hook-form";
import API from "../../utils/api/work/work";
import FieldSelect from "components/field/FieldSelect";
import FieldDatePicker from "components/field/FieldDatePicker";
import InputNormal from "components/input/inputNormal";
import Swal from "sweetalert2";
import InputDisable from "components/input/inputDisable";
import InputSelect from "components/input/inputSelect";
import InputUpload from "components/input/inputUpload";
import InputImageShow from "components/input/InputImageShow";
import { getAllTruckInProfile } from "utils/api/truck/truck";
import { getAllDriverInProfile } from "utils/api/profile/profile";
import { getAllParticipateRunnerProject } from "utils/api/profile/profileJoinCarrier";
import Pagination from "components/pagination/Pagination";
import {
  getAllUser as getAllUserAPI,
  getDrivingLicense,
} from "utils/api/user/user";
import helper from "utils/helper";
const api = API.create();
// const google = window.google;
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 400,
  flexDirection: "column",
  overflow: "scroll",
  maxHeight: "90vmin",
  //   height: 200,
  bgcolor: "background.paper",
  borderRadius: 5,
  color: "#121f43",
  boxShadow: 24,
  // pt: 3,
  // px: 3,
  // pb: 3,
};
let staticStatus = [
  "กำลังหารถ",
  "คนขับรถรับทราบงานแล้ว",
  "ลบประกาศ",
  "ประกาศหมดอายุ",
  "ผู้ขนส่งรับงานแล้ว",
  "กำหนดรถและคนขับรถแล้ว",
  "รถออกเดินทาง",
  "รถกำลังถึงจุดรับสินค้า",
  "รับสินค้าแล้ว",
  "รถกำลังถึงจุดส่งสินค้า",
  "ส่งสินค้าแล้ว",
  "รอจัดส่งเอกสารนำกลับ",
  "กำลังส่งเอกสารนำกลับ",
  "ยกเลิก",
  "เสร็จสิ้น",
  "อาจเข้ารับสินค้าช้า",
  "อาจเข้าส่งสินค้าช้า",
  "แจ้งปัญหา",
];
const ModalUpdateStatus = (props) => {
  const { value, close, open, onChange, confirm, workData, success } = props;
  const [profileList, setProfileList] = useState([]);
  const [totalProfile, setTotalProfile] = useState(0);
  const [masterWorkStatus, setMasterWorkStatus] = useState([]);
  const [turckList, setTruckList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [filterUser, setFilterUser] = useState({
    sort_by: "updated_at",
    search_by: "company_name",
    search_val: '',
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 3,
    total: 0,
    is_main: [],
    profile_type: [],
    role: ['carrier'],
    active: ['true'],
  });
  const [workStatus, setWorkStatus] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm({});
  useEffect(() => {
    getStatus();
  }, []);
  useEffect(() => {
    reset();
    console.log("props", props);
  }, [props]);
  const getStatus = async () => {
    api
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        let filStatus = response.data.results
          .filter((e) => staticStatus.includes(e.name))
          .map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          });
        console.log("filStatus", filStatus);
        setMasterWorkStatus(filStatus);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const watchCarrier = watch("carrier_code");
  const watchCompanyName = watch("company_name");
  const watchCarrierPartner = watch("carrier_partner_id");

  const watchOwner = watch("owner");
  const watchCancelId = watch("cancel_id");
  const watchDeleteId = watch("delete_id");
  const watchUser = watch("user_id");


  const watchProblemType = watch("problem_type");
  useEffect(() => {
    if (workStatus === 4) {
      setTruckList([]);
      setDriverList([]);
      setPartnerList([]);
      setValue("truck_id", "");
      setValue("driver_id", "");
      setValue("carrier_partner_id", "");
      if (watchOwner === "ใช่") {
        let body = {
          active: true,
          document_approve_status: 1,
          truck_type_id: workData[0].truck_type_id,
          page: 1,
          per_page: 9999,
        };
        getAllTruckInProfile(workData[0].carrier_id, body)
          .then((response) => {
            setTruckList(
              response.data.data.results.map((e) => {
                return {
                  ...e,
                  item: e.license_plate_tail
                    ? e.license_plate_head + "/" + e.license_plate_tail
                    : e.license_plate_head,
                  value: e.id,
                };
              })
            );
          })
          .catch((e) => {
            console.log(e);
          });
        let body_driver = {
          active: true,
          driver_approved_status: 1,
        };
        getAllDriverInProfile(workData[0].carrier_id, body_driver)
          .then((response) => {
            console.log("getAllDriverInProfile response", response);
            let dri = response.data.data.results
              .filter((e) => e.active)
              .map((e) => {
                return {
                  ...e,
                  item: e.driver_info.firstname + " " + e.driver_info.lastname,
                  value: e.driver_info.id,
                };
              });
            setDriverList(dri);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        let body = {
          active: true,
          carrier_approved_status: 1,
          carrier_active: true,
          page: 1,
          per_page: 9999,
        };
        getAllParticipateRunnerProject(workData[0].carrier_id, body)
          .then((response) => {
            console.log("getAllParticipateRunnerProject response", response);
            setPartnerList(
              response.data.data.results.map((e) => {
                return {
                  ...e,
                  item: e.co_runner_profile.company_name,
                  value: e.co_runner_profile.id,
                };
              })
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }, [workStatus, watchOwner]);
  useEffect(() => {
    if (watchCarrierPartner && watchCarrierPartner !== "") {
      setTruckList([]);
      setDriverList([]);
      setValue("truck_id", "");
      setValue("driver_id", "");
      let body = {
        active: true,
        document_approve_status: 1,
        truck_type_id: workData[0].truck_type_id,
        page: 1,
        per_page: 9999,
      };
      getAllTruckInProfile(watchCarrierPartner, body)
        .then((response) => {
          setTruckList(
            response.data.data.results.map((e) => {
              return {
                ...e,
                item: e.license_plate_tail
                  ? e.license_plate_head + "/" + e.license_plate_tail
                  : e.license_plate_head,
                value: e.id,
              };
            })
          );
        })
        .catch((e) => {
          console.log(e);
        });
      let body_driver = {
        active: true,
        driver_approved_status: 1,
      };
      console.log("body_driver", body_driver);
      getAllDriverInProfile(watchCarrierPartner, body_driver)
        .then((response) => {
          console.log("getAllDriverInProfile response", response);
          let dri = response.data.data.results
            .filter((e) => e.active)
            .map((e) => {
              return {
                ...e,
                item: e.driver_info.firstname + " " + e.driver_info.lastname,
                value: e.driver_info.id,
              };
            });
          setDriverList(dri);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [watchCarrierPartner]);
  const getProfile = async (filter = filterUser) => {
    // 
    const body = {
      "shipper": {},
      "carrier": {},
      "driver": {}
    }
    await getAllUserAPI(filter, body)
      .then((response) => {
        console.log(response.data.data);
        setProfileList(response.data.data)
        setTotalProfile(response.data.total)
      })
      .catch(async (error) => {
        // setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {

        } else if (error.response.data.code === "4105") {
          // removeAccessToken("access_token");
          // window.location.reload();
        }
      });
  }
  const renderUpdate = () => {
    switch (workStatus) {
      case 3:
        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label="วันเวลาที่รับงาน*"
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.update_at}
                  />
                )}
                name="update_at"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: -20 }}>
              {/* <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                
                  // <InputNormal
                  //   label="รหัสผู้ใช้งาน*"
                  //   onChange={onChange}
                  //   value={value}
                  //   error={errors.carrier_code}
                  // />
                )}
                name="carrier_code"
                defaultValue=""
              /> */}
              <TextField
                id="search_val"
                variant="standard"
                style={{
                  paddingTop: "16px",
                }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={filterUser.search_val}
                onChange={(val) => {
                  setFilterUser({ ...filterUser, search_val: val.target.value })
                }}
              />

            </div>
            <div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  // getUser();
                  setFilterUser({
                    ...filterUser,
                    per_page: 3,
                    page: 1
                  });
                  getProfile({
                    ...filterUser,
                    per_page: 3,
                    page: 1
                  });
                }}
              >
                ค้นหา
              </Button>
            </div>
            <div style={{ marginTop: 10 }}>
              {profileList.map((ele, index) => {
                return (
                  <div
                    key={"profile" + index}
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderWidth: 1,
                      padding: 10,
                      borderColor: ele.id === watchUser ? "#121f43" : "gray",
                      color: ele.id === watchUser ? "#121f43" : "gray",
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      // console.log(ele.id);
                      // console.log('watchUser', watchUser);

                      setValue('user_id', ele.id);
                      setValue('carrier_id', ele.user_profile.profile.id);
                      setValue('carrier_code', ele.user_code)
                      setValue("company_name", ele.user_profile.profile.company_name);
                    }}
                  >
                    <div>
                      {ele.user_code}
                    </div>
                    <div style={{ fontSize: 20, fontWeight: 500 }}>
                      {ele.user_profile.profile.company_name}
                    </div>
                    <div>
                      {'สร้างบัญชีเมื่อ: ' + helper.momentDateTime(ele.created_at)}
                    </div>

                  </div>
                );
              })}
              <Pagination
                pagination={{
                  page: filterUser.page,
                  per_page: filterUser.per_page,
                  total: totalProfile,
                }}
                setPagination={(val) => {
                  console.log(val);
                  setFilterUser({
                    ...filterUser,
                    per_page: val.per_page,
                    page: val.page,
                  });
                  getProfile({
                    ...filterUser,
                    per_page: val.per_page,
                    page: val.page,
                  })
                  // getFavPlace({
                  //   ...filter,
                  //   per_page: val.per_page,
                  //   page: val.page,
                  // })
                }}
              // loading={loading}
              />

            </div>
            {/* {watchCompanyName ? (
              <div>
                <InputDisable label={"ชื่อโปรไฟล์"} value={watchCompanyName} />
              </div>
            ) : null} */}
          </div>
        );
      case 4: {
        // await getAllTruckInProfile(profile_id, new_params);

        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label="วันเวลาที่กำหนดรถและคนขับ*"
                    onChange={onChange}
                    value={value}
                    error={errors.update_at}
                    showTime
                    className="w-100"
                  />
                )}
                name="update_at"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={"ใช้รถตัวเอง*"}
                    subFirst={"(ค่าเริ่มต้น: ใช่)"}
                    options={[
                      { item: "ใช่", value: "ใช่" },
                      { item: "ไม่ใช่", value: "ไม่ใช่" },
                    ]}
                    error={errors.owner}
                    onChange={onChange}
                  />
                )}
                name="owner"
                defaultValue={"ใช่"}
              />
            </div>
            {watchOwner && watchOwner !== "ใช่" ? (
              <div>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputSelect
                      value={value}
                      label={"ผู้ขนส่งร่วมวิ่ง*"}
                      subFirst={"กรณีใช้รถตัวเองจะไม่สามารถระบุได้"}
                      options={partnerList}
                      onChange={onChange}
                      error={errors.carrier_partner_id}
                    />
                  )}
                  name="carrier_partner_id"
                  defaultValue=""
                />
              </div>
            ) : null}
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={"ทะเบียนรถ*"}
                    subFirst={`ถ้าใช้รถตัวเองจะดึงรายชื่อรถที่อยู่ในโปรไฟล์ตัวเอง`}
                    subSec={
                      "ถ้าเลือกผู้ขนส่งร่วมวิ่งจะดึงรายชื่อจากโปรไฟล์ผู้ขนส่งร่วมวิ่ง"
                    }
                    error={errors.truck_id}
                    options={turckList}
                    onChange={onChange}
                  />
                )}
                name="truck_id"
                defaultValue=""
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={"คนขับ*"}
                    subFirst={`ถ้าใช้รถตัวเองจะดึงรายชื่อคนขับที่อยู่ในโปรไฟล์ตัวเอง`}
                    subSec={
                      "ถ้าเลือกผู้ขนส่งร่วมวิ่งจะดึงรายชื่อจากโปรไฟล์ผู้ขนส่งร่วมวิ่ง"
                    }
                    error={errors.driver_id}
                    options={driverList}
                    onChange={onChange}
                  />
                )}
                name="driver_id"
                defaultValue=""
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label="ยืนยันเวลารับสินค้า*"
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.pickup_datetime}
                  />
                )}
                name="pickup_datetime"
                defaultValue={new Date()}
              />
            </div>
          </div>
        );
      }
      case 8:
      case 10:
        setValue("type", workStatus === 8 ? 0 : 1);
        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label={
                      "วันเวลาที่" +
                      (workStatus === 8 ? "รับ" : "ส่ง") +
                      "สินค้า*"
                    }
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.update_at}
                  />
                )}
                name="update_at"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={
                      "ประเภทผู้" +
                      (workStatus === 8 ? "ส่ง" : "รับ") +
                      "สินค้า" +
                      (workStatus === 8 ? "ต้นทาง*" : "ปลายทาง*")
                    }
                    subFirst={"(ค่าเริ่มต้น: เจ้าของสินค้า)"}
                    options={[
                      { item: "เจ้าของสินค้า", value: 0 },
                      { item: "เพื่อนบ้าน", value: 1 },
                      { item: "เพื่อนร่วมงาน", value: 2 },
                      { item: "รปภ.", value: 3 },
                      { item: "แผนกต้อนรับ", value: 4 },
                      { item: "ครอบครัว", value: 5 },
                      { item: "อื่นๆ", value: 6 },
                    ]}
                    onChange={onChange}
                    error={errors.contact_type}
                  />
                )}
                name="contact_type"
                defaultValue={0}
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    label={
                      "ชื่อผู้" +
                      (workStatus === 8 ? "ส่ง" : "รับ") +
                      "สินค้า" +
                      (workStatus === 8 ? "ต้นทาง*" : "ปลายทาง*")
                    }
                    error={errors.contact_name}
                    onChange={onChange}
                  />
                )}
                name="contact_name"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    label={"หมายเหตุ"}
                    onChange={onChange}
                  />
                )}
                name="remark"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputUpload
                    value={value}
                    label={
                      "ลายมือชื่อผู้" +
                      (workStatus === 8 ? "ส่ง" : "รับ") +
                      "สินค้า" +
                      (workStatus === 8 ? "ต้นทาง*" : "ปลายทาง*")
                    }
                    onChange={onChange}
                    error={errors.signature}
                  />
                )}
                name="signature"
                defaultValue=""
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1, marginRight: 10 }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      value={value}
                      label={"พิกัด lat*"}
                      onChange={onChange}
                      type="number"
                      error={errors.lat}
                    />
                  )}
                  name="lat"
                  defaultValue={null}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      value={value}
                      label={"พิกัด long*"}
                      onChange={onChange}
                      error={errors.long}
                      type="number"
                    />
                  )}
                  name="long"
                  defaultValue={null}
                />
              </div>
            </div>
            <div style={{ marginTop: 15 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputImageShow
                    value={value}
                    onChange={onChange}
                    error={errors.image}
                  />
                )}
                name="image"
                defaultValue={null}
              />
            </div>
          </div>
        );
      case 13:
        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label={"วันเวลาที่ส่งเอกสารนำกลับ*"}
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.update_at}
                  />
                )}
                name="update_at"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={"ผู้ให้บริการจัดส่งเอกสาร*"}
                    options={[
                      { item: "ไปรษณีย์ไทย", value: 0 },
                      { item: "Flash Express", value: 1 },
                      { item: "Kerry Express", value: 2 },
                      { item: "J&T Express", value: 3 },
                      { item: "SCG Express", value: 4 },
                    ]}
                    onChange={onChange}
                    error={errors.logistics_provider_type}
                  />
                )}
                name="logistics_provider_type"
                defaultValue={""}
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    label={"เลขติดตามสถานะ*"}
                    onChange={onChange}
                    error={errors.tracking_code}
                  />
                )}
                name="tracking_code"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                // rules={{
                //   required: true,
                // }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    label={"หมายเหตุ"}
                    onChange={onChange}
                  />
                )}
                name="remark"
                defaultValue=""
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputImageShow
                    value={value}
                    onChange={onChange}
                    error={errors.document_image}
                  />
                )}
                name="document_image"
                defaultValue={null}
              />
            </div>
          </div>
        );
      case 16:
        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label={"วันเวลาที่แจ้งปัญหา*"}
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.update_at}
                  />
                )}
                name="update_at"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={"ประเภทปัญหา*"}
                    options={[
                      { item: "ที่อยู่ไม่ชัดเจน หรือ ที่อยู่ผิด", value: 0 },
                      {
                        item: "ผู้รับสินค้าติดต่อไม่ได้/ไม่มีผู้รับสาย",
                        value: 1,
                      },
                      { item: "รอคิวขึ้น-ลงสินค้าเกิน 3 ชั่วโมง ", value: 2 },
                      { item: "ผู้รับปลายทางไม่รับของวันหยุด", value: 3 },
                      { item: "ผู้รับปลายทางปฏิเสธการรับของ", value: 4 },
                      { item: "สถานที่จัดส่งปิดทำการ", value: 5 },
                      { item: "บรรจุภัณฑ์เสียหาย", value: 6 },
                      { item: "สินค้าเสียหาย", value: 7 },
                      { item: "สินค้าขาดหาย/สูญหาย", value: 8 },
                      { item: "รถเกิดอุบัติเหตุ", value: 9 },
                      { item: "รถเสียระหว่างการเดินทาง", value: 10 },
                      { item: "อื่นๆ", value: 11 },
                    ]}
                    onChange={onChange}
                    error={errors.problem_type}
                  />
                )}
                name="problem_type"
                defaultValue={""}
              />
            </div>
            {watchProblemType === 11 ? (
              <div>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      value={value}
                      label={"อื่นๆ โปรดระบุ"}
                      onChange={onChange}
                      error={errors.problem_topic_other}
                    />
                  )}
                  name="problem_topic_other"
                  defaultValue=""
                />
              </div>
            ) : null}
            <div style={{ marginTop: 15 }}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    id="standard-multiline-static"
                    label="รายละเอียดปัญหา"
                    value={value}
                    defaultValue={value}
                    multiline
                    onChange={onChange}
                    rows={4}
                    // defaultValue="Default Value"
                    error={errors.problem_detail}
                    variant="standard"
                  />
                )}
                name="problem_detail"
                defaultValue={null}
              />
            </div>
            <div></div>
            <div style={{ marginTop: 15 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputImageShow
                    value={value}
                    onChange={onChange}
                    error={errors.problem_image}
                  />
                )}
                name="problem_image"
                defaultValue={null}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1, marginRight: 10 }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      value={value}
                      label={"พิกัด lat*"}
                      onChange={onChange}
                      type="number"
                      error={errors.lat}
                    />
                  )}
                  name="lat"
                  defaultValue={null}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      value={value}
                      label={"พิกัด long*"}
                      onChange={onChange}
                      error={errors.long}
                      type="number"
                    />
                  )}
                  name="long"
                  defaultValue={null}
                />
              </div>
            </div>
          </div>
        );
      case 17:
        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label={"วันเวลาที่ยกเลิกงาน*"}
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.cancel_datetime}
                  />
                )}
                name="cancel_datetime"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={"เหตุผลในการยกเลิก*"}
                    options={[
                      { item: "ประกาศผิด/แก้ไข/ซ้ำ", value: 0 },
                      { item: "ลูกค้ายกเลิก/สินค้าไม่พร้อม", value: 1 },
                      {
                        item: "พขร.ยกเลิกงาน/รถเสีย/เข้ารับสินค้าไม่ทัน",
                        value: 2,
                      },
                      { item: "อื่น ๆ", value: 3 },
                    ]}
                    onChange={onChange}
                    error={errors.cancel_id}
                  />
                )}
                name="cancel_id"
                defaultValue={""}
              />
            </div>
            {watchCancelId === 3 ? (
              <div>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      value={value}
                      label={"อื่นๆ โปรดระบุ"}
                      onChange={onChange}
                      error={errors.cancel_description}
                    />
                  )}
                  name="cancel_description"
                  defaultValue=""
                />
              </div>
            ) : null}
          </div>
        );
      case 18:
        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label="วันเวลาที่หมดอายุ*"
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.post_expire_date}
                  />
                )}
                name="post_expire_date"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label="วันเวลาที่อัพเดท*"
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.update_at}
                  />
                )}
                name="update_at"
                defaultValue={new Date()}
              />
            </div>
          </div>
        );
      case 19:
        return (
          <div>
            <div style={{ marginTop: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldDatePicker
                    label="วันเวลาที่ลบประกาศ*"
                    onChange={onChange}
                    value={value}
                    showTime
                    className="w-100"
                    error={errors.delete_datetime}
                  />
                )}
                name="delete_datetime"
                defaultValue={new Date()}
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputSelect
                    value={value}
                    label={"เหตุผลในการลบประกาศ*"}
                    options={[
                      { item: "ประกาศผิด/แก้ไข/ซ้ำ", value: 0 },
                      { item: "ลูกค้ายกเลิก/สินค้าไม่พร้อม", value: 1 },
                      {
                        item: "พขร.ยกเลิกงาน/รถเสีย/เข้ารับสินค้าไม่ทัน",
                        value: 2,
                      },
                      { item: "อื่น ๆ", value: 3 },
                    ]}
                    onChange={onChange}
                    error={errors.delete_id}
                  />
                )}
                name="delete_id"
                defaultValue=""
              />
            </div>
            {watchDeleteId === 3 ? (
              <div>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      label="อื่นๆโปรดระบุ*"
                      onChange={onChange}
                      value={value}
                      error={errors.delete_description}
                    />
                  )}
                  name="delete_description"
                  defaultValue=""
                />
              </div>
            ) : null}
          </div>
        );
      case 5:
      case 6:
      case 7:
      case 20:
      case 9:
      case 21:
      case 11:
      case 15:
        return caseNormal();
      default:
        return <div></div>;
    }
  };

  const caseNormal = () => {
    let status_name = masterWorkStatus.filter((e) => e.id === workStatus)[0]
      .name;
    status_name = status_name.replace("แล้ว", "");
    return (
      <div>
        <div style={{ marginTop: 10 }}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FieldDatePicker
                label={"วันเวลาที่" + status_name + "*"}
                onChange={onChange}
                value={value}
                showTime
                error={errors.update_at}
                className="w-100"
              />
            )}
            name="update_at"
            defaultValue={new Date()}
          />
        </div>
      </div>
    );
  };

  const getUser = async () => {
    await api
      .getUser({ shipper_code: watchCarrier })
      .then(async (response) => {
        console.log("response,", response);
        setValue("carrier_id", response.data.data.id);
        setValue("company_name", response.data.data.company_name);
      })
      .catch((error) => {
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text: "ไม่พบรหัสผู้ใช้งาน",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };
  const update = async (data) => {
    setDisableButton(true);
    console.log("data update", data);
    let work_id = workData.map((e) => e.work_id);
    switch (workStatus) {
      case 1: {
        await api
          .returnToOrder(work_id)
          .then((response) => {
            console.log("returnToOrder work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.data.code === 4401) {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: "ไม่พบงานหรืองานอยู่ในสถานะกำลังหารถอยู่แล้ว",
                icon: "error",
                showConfirmButton: false,
              });
            } else {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: error.response.data.description ?? "",
                // text: "ไม่พบรหัสผู้ใช้งาน",
                icon: "error",
                showConfirmButton: false,
              });
            }
          });
        break;
      }
      case 3:
        let body = {
          carrier_id: data.carrier_id,
          update_at: new Date(data.update_at).toISOString(),
        };
        // let work_id = workData.map((e) => e.work_id);

        await api
          .acceptWork(work_id, body)
          .then((response) => {
            console.log("accpet work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            Swal.fire({
              timer: 2000,
              title: "error: " + error.response.data.code,
              text: error.response.data?.description ?? "",
              icon: "error",
              showConfirmButton: false,
            });
          });
        break;
      case 4: {
        let body = {
          owner: data.owner === "ใช่",
          carrier_partner_id: data.carrier_partner_id,
          driver_id: data.driver_id,
          truck_id: data.truck_id,
          pickup_datetime: new Date(data.pickup_datetime).toISOString(),
          send_datetime: new Date(data.pickup_datetime).toISOString(),
          update_at: new Date(data.update_at).toISOString(),
        };
        // let work_id = workData.map((e) => e.work_id);

        await api
          .assignWork(work_id, body)
          .then((response) => {
            console.log("accpet work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            Swal.fire({
              timer: 2000,
              title: "error: " + error.response.data.code,
              text: error.response.data.description?.message,
              icon: "error",
              showConfirmButton: false,
            });
          });
        break;
      }
      case 8:
      case 10: {
        console.log("pickup send", data);
        let formData = new FormData();
        formData.append("type", data.type);
        formData.append("contact_type", data.contact_type);
        formData.append("contact_name", data.contact_name);
        formData.append("remark", data.remark);
        formData.append("lat", data.lat);
        formData.append("long", data.long);
        formData.append("signature", data.signature.file);
        data.image.forEach((e) => {
          if (e) {
            formData.append("image", e.file);
          }
        });
        formData.append("update_at", new Date(data.update_at).toISOString());
        await api
          .pickupSendWork(work_id, formData)
          .then((response) => {
            console.log("pickupSend work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            Swal.fire({
              timer: 2000,
              title: "error: " + error.response.data.code,
              // text: "ไม่พบรหัสผู้ใช้งาน",
              text: error.response.data.description ?? "",
              icon: "error",
              showConfirmButton: false,
            });
          });
        // formData.append("point");
        break;
      }
      case 13: {
        console.log("doc return", data);
        let formData = new FormData();
        formData.append(
          "logistics_provider_type",
          data.logistics_provider_type
        );
        formData.append("tracking_code", data.tracking_code.toUpperCase());
        formData.append("document_remark", data.remark);
        data.document_image.forEach((e) => {
          if (e) {
            formData.append("document_image", e.file);
          }
        });
        formData.append("update_at", new Date(data.update_at).toISOString());
        await api
          .documentReturnWork(work_id, formData)
          .then((response) => {
            console.log("doc return work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            Swal.fire({
              timer: 2000,
              title: "error: " + error.response.data.code,
              // text: "ไม่พบรหัสผู้ใช้งาน",
              icon: "error",
              showConfirmButton: false,
            });
          });
        // formData.append("point");
        break;
      }
      case 16: {
        console.log("abnormal ", data);
        let formData = new FormData();
        formData.append("problem_type", data.problem_type);
        formData.append("problem_topic_other", data.problem_topic_other);
        formData.append("problem_detail", data.problem_detail);
        formData.append("abnormal_lat", data.lat);
        formData.append("abnormal_long", data.long);

        data.problem_image.forEach((e) => {
          if (e) {
            formData.append("problem_image", e.file);
          }
        });
        formData.append("update_at", new Date(data.update_at).toISOString());
        await api
          .problemWork(work_id, formData)
          .then((response) => {
            console.log("problem work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.data.code === 4401) {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: "ต้องอยู่ระหว่างรับส่งสินค้าเท่านั้น",
                icon: "error",
                showConfirmButton: false,
              });
            } else {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: error.response.data.description ?? "",
                // text: "ไม่พบรหัสผู้ใช้งาน",
                icon: "error",
                showConfirmButton: false,
              });
            }
          });
        break;
      }
      case 17: {
        let body = {
          cancel_id: data.cancel_id,
          cancel_description: data.cancel_description,
          cancel_datetime: new Date(data.cancel_datetime).toISOString(),
        };

        // let work_id = workData.map((e) => e.work_id);

        await api
          .cancelWork(work_id, body)
          .then((response) => {
            console.log("cancel work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.data.code === 4409) {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: "ต้องเป็นงานที่มีคนรับแล้วเท่านั้นถึงจะยกเลิกได้",
                icon: "error",
                showConfirmButton: false,
              });
            } else {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: error.response.data.description ?? "",
                // text: "ไม่พบรหัสผู้ใช้งาน",
                icon: "error",
                showConfirmButton: false,
              });
            }
          });
        break;
      }
      case 18: {
        let body = {
          post_expire_date: data.post_expire_date,
          update_at: new Date(data.update_at).toISOString(),
        };

        // let work_id = workData.map((e) => e.work_id);

        await api
          .expireWork(work_id, body)
          .then((response) => {
            console.log("cancel work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.data.code === 4401) {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: "ต้องเป็นสถานะกำลังหารถเท่านั้น",
                icon: "error",
                showConfirmButton: false,
              });
            } else {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: error.response.data.description ?? "",
                // text: "ไม่พบรหัสผู้ใช้งาน",
                icon: "error",
                showConfirmButton: false,
              });
            }
          });
        break;
      }
      case 19: {
        let body = {
          delete_id: data.delete_id,
          delete_description: data.delete_description,
          delete_datetime: new Date(data.delete_datetime).toISOString(),
        };

        // let work_id = workData.map((e) => e.work_id);

        await api
          .deletelWork(work_id, body)
          .then((response) => {
            console.log("cancel work", response);
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: "อัพเดทสถานะเสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                setWorkStatus();
                success();
                setDisableButton(false);
              },
            });
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.data.code === 4401) {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: "ต้องเป็นสถานะกำลังหารถเท่านั้น",
                icon: "error",
                showConfirmButton: false,
              });
            } else {
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: error.response.data.description ?? "",
                // text: "ไม่พบรหัสผู้ใช้งาน",
                icon: "error",
                showConfirmButton: false,
              });
            }
          });
        break;
      }
      case 5:
      case 6:
      case 7:
      case 20:
      case 9:
      case 21:
      case 11:
      case 15:
        {
          console.log("case", workStatus);
          let body = {
            status_id: workStatus,
            update_at: new Date(data.update_at).toISOString(),
          };
          // let work_id = workData.map((e) => e.work_id);
          await api
            .updateWork(work_id, body)
            .then((response) => {
              console.log("accpet work", response);

              Swal.fire({
                // position: 'top-end',
                icon: "success",
                title: "อัพเดทสถานะเสร็จสิ้น",
                showConfirmButton: false,
                timer: 1500,
                willClose: () => {
                  setWorkStatus();
                  success();
                  setDisableButton(false);
                },
              });
            })
            .catch((error) => {
              setDisableButton(false);
              Swal.fire({
                timer: 2000,
                title: "error: " + error.response.data.code,
                text: error.response.data.description ?? "",
                // text: "ไม่พบรหัสผู้ใช้งาน",
                icon: "error",
                showConfirmButton: false,
              });
            });
        }
        break;
      default:
        return <div></div>;
    }
  };
  return (
    <div>
      <Modal open={open}>
        <Box
          sx={{
            ...style,
            minHeight: "80vmin",
            position: "relative",
          }}
        >
          <div
            style={{
              float: "right",
              cursor: "pointer",
              textAlign: "end",
              padding: "10px 10px 0px 10px",
            }}
            onClick={() => {
              setWorkStatus();

              close();
            }}
          >
            <CloseIcon sx={{ color: "#8ca3ba" }} />
          </div>
          <div style={{ padding: "0px 20px 100px 20px" }}>
            <div style={{ textAlign: "center", fontFamily: "Kanit" }}>
              อัพเดทสถานะงาน
            </div>
            <Line opacity={0.6} heigth={3} />
            <FieldSelect
              name={"สถานะงานที่อัพเดท*"}
              options={masterWorkStatus}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              value={workStatus}
              onChange={(val) => {
                setWorkStatus(val);
              }}
              closePaddingBottom={true}
            />
            {renderUpdate()}
          </div>

          <div
            style={{
              // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: 20,
              // borderRadius: 20,
              bottom: 0,
              position: "absolute",
              textAlign: "center",
              width: "100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outline"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    color: "#8ca3ba",
                    borderColor: "#8ca3ba",
                    height: 50,
                  }}
                  color="secondary"
                  onClick={() => {
                    setWorkStatus();
                    close();
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
              {workStatus ? (
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={handleSubmit(update)}
                    disabled={disableButton}
                  >
                    ยืนยัน
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalUpdateStatus;
