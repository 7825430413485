import instance from "utils/axiosInstance";

export const getUnpaid = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/unpaid`, {
    params: params,
  });
};

export const getInvoice = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/invoice`, {
    params: params,
  });
};

export const getInvoiceXlsx = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/invoice/xlsx`, {
    params: params,
    responseType: "blob",
  });
};

export const getInvoiceGroup = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/invoice-group`, {
    params: params,
  });
};

export const createInvoice = async (data = {}) => {
  const formData = new FormData();

  data.trade_transport_ids.forEach((code, index) => {
    formData.append(`trade_transport_ids[${index}]`, code);
  });
  formData.append("actual_date", data.actual_date);
  formData.append("buy_amount_per_trip", data.buy_amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);
  formData.append("unit_price", data.unit_price);

  return await instance.post(`api/trade-finance/seller/invoice`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelInvoice = async (invoiceCode, body) => {
  return await instance.post(
    `api/trade-finance/seller/invoice/${invoiceCode}/cancel`,
    body
  );
};

export const getBillingnote = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/billingnote`, {
    params: params,
  });
};

export const getBillingnoteGroup = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/billingnote-group`, {
    params: params,
  });
};

export const createBillingNote = async (data) => {
  const formData = new FormData();

  data.invoice_codes.forEach((code, index) => {
    formData.append(`invoice_codes[${index}]`, code);
  });
  formData.append("actual_date", data.actual_date);
  formData.append("buy_amount_per_trip", data.buy_amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);

  return await instance.post(`api/trade-finance/seller/billingnote`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelBillingnote = async (billCode, body) => {
  return await instance.post(
    `api/trade-finance/seller/billingnote/${billCode}/cancel`,
    body
  );
};

export const getPaid = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/paid`, {
    params: params,
  });
};

export const getPaidGroup = async (params = {}) => {
  return await instance.get(`api/trade-finance/seller/paid-group`, {
    params: params,
  });
};

export const createPaid = async (data) => {
  const formData = new FormData();

  data.bill_codes.forEach((code, index) => {
    formData.append(`bill_codes[${index}]`, code);
  });

  formData.append("actual_date", data.actual_date);
  formData.append("buy_amount_per_trip", data.buy_amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("amount_of_transfer_actual", data.amount_of_transfer_actual);
  formData.append("bank_id", data.bank_id);
  formData.append("ref", data.ref);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);

  return await instance.post(`api/trade-finance/seller/Paid`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelPaid = async (receiptCode, body) => {
  return await instance.post(
    `api/trade-finance/seller/Paid/${receiptCode}/cancel`,
    body
  );
};

export const createWht = async (params) => {
  return await instance.get(`/api/trade-finance/seller/paid/wht`, {
    params: params,
    responseType: "blob",
  });
};

export const getSellerTransaction = async (params = {}) => {
  return await instance.get(`/api/trade-finance/seller/finance-transaction`, {
    params: params,
  });
};

export const editSellerTransaction = async (type, code, body) => {
  return await instance.patch(
    `/api/trade-finance/seller/finance-transaction/${type}/${code}`,
    body,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
};

export const getSellerEditLog = async (params = {}) => {
  return await instance.get(
    `/api/trade-finance/seller/finance-transaction/edit-log`,
    {
      params: params,
    }
  );
};
