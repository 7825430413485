import LoadingBackground from "components/loading/LoadingBackground";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "./TableTask.module.css";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiUpdateStatusTask } from "utils/api/task/task";
import { useCookies } from "react-cookie";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldSelect from "components/field/FieldSelect";
import FieldInput from "components/field/FieldInput";
import ButtonCustom from "components/ButtonCustom";
import FieldTextArea from "components/field/FieldTextArea";

const UpdateStatusTask = (props) => {
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  useEffect(() => {
    setValue("actual_date", new Date());
    setValue("task_status", props?.data?.task_status);
  }, []);

  const onSubmit = async (data) => {
    console.log("data", data);
    setLoading(true);
    await apiUpdateStatusTask(props?.data?.id, data)
      .then((res) => {
        if (res.data.code === 1000) {
          props?.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  return (
    <div>
      {loading && <LoadingBackground loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={props?.handleClose}
            />
          </div>
          <div style={{ fontSize: 20, fontWeight: 400, textAlign: "center" }}>
            อัพเดทสถานะงาน
          </div>
          <form id="form-task-content" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="actual_date"
              rules={{
                required: "กรุณาเลือกวันที่อัพเดตสถานะ",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldDatePicker
                    label="วันที่อัพเดตสถานะ*"
                    value={value}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    error={!!errors.actual_date}
                    helperText={
                      errors.actual_date ? errors.actual_date.message : ""
                    }
                    readOnly
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="task_status"
              rules={{
                required: "กรุณาเลือกสถานะงาน",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldSelect
                    name={"สถานะงาน*"}
                    options={[
                      { value: 1, name: "ยังไม่เริ่มดำเนินการ" },
                      { value: 2, name: "รับทราบงาน" },
                      { value: 3, name: "กําลังดําเนินการ" },
                      { value: 4, name: "เสร็จสิ้น" },
                      { value: 5, name: "ยกเลิกงาน" },
                    ]}
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.task_status}
                    errorText={
                      errors.task_status ? errors.task_status.message : ""
                    }
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="detail"
              // rules={{
              //   required: "กรุณากรอกรายละเอียดสถานะ",
              // }}
              render={({ field: { onChange, onBlur, value } }) => (
                <div style={{ width: "100%" }}>
                  <FieldTextArea
                    placeholder="รายละเอียดสถานะ"
                    value={value}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    error={!!errors.detail}
                    errorMessage={errors.detail ? errors.detail.message : ""}
                  />
                </div>
              )}
            />
            <div style={{ paddingTop: "20px", display: "flex" }}>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name={"ยกเลิก"}
                  onClick={props?.handleClose}
                  type={"cancel"}
                />
              </div>

              <div style={{ flex: 0.5 }}>
                <ButtonCustom name={"บันทึก"} type="submit" fullWidth={true} />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateStatusTask;
