import React, { useEffect, useState } from "react";
import styles from "./TableTrade.module.css";
import { Modal, Typography } from "@mui/material";
import FieldDatePicker from "components/field/FieldDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import ButtonCustom from "components/ButtonCustom";
import { paidTransport } from "utils/api/trade/trade";
const ModalPaidTransport = (props) => {
  const [isClicked, setIsClicked] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    setIsClicked(false);
  }, [props?.open]);

  const closeModal = () => {
    props?.handleClose();
    reset();
  };
  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    setIsClicked(true);
    if (props?.idTransport) {
      await paidTransport(props?.idTransport, data)
        .then((res) => {
          if (res.data.code === 1000) {
            closeModal();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Modal open={props?.open} onClose={closeModal} sx={{ zIndex: 1000 }}>
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon sx={{ color: "grey.main" }} onClick={closeModal} />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              ทำรายการชำระเงิน
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="payment_date"
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldDatePicker
                      label="วันที่ชำระเงิน"
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={closeModal}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="บันทึก"
                  style={{ padding: 0 }}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                  disabled={isClicked}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalPaidTransport;
