import instance from "utils/axiosInstance";

export const getUnpaid = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/unpaid`, {
    params: params,
  });
};

export const getInvoice = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/invoice`, {
    params: params,
  });
};

export const getInvoiceXlsx = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/invoice/xlsx`, {
    params: params,
    responseType: "blob",
  });
};

export const getInvoiceGroup = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/invoice-group`, {
    params: params,
  });
};

export const createInvoice = async (data = {}) => {
  const formData = new FormData();
  console.log({ data });
  data.trade_transport_ids.forEach((code, index) => {
    formData.append(`trade_transport_ids[${index}]`, code);
  });
  formData.append("actual_date", data.actual_date);
  formData.append("amount_per_trip", data.amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);
  formData.append("unit_price", data.unit_price);
  formData.append("rental_amount", data.rental_amount);
  formData.append("rental_ot", data.rental_ot);
  formData.append("wht_rate", data.wht_rate);
  formData.append("wht_service", data.wht_service);
  formData.append("wht", data.wht);

  return await instance.post(`api/rental-finance/lessor/invoice`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelInvoice = async (invoiceCode, body) => {
  return await instance.post(
    `api/rental-finance/lessor/invoice/${invoiceCode}/cancel`,
    body
  );
};

export const getBillingnote = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/billingnote`, {
    params: params,
  });
};

export const getBillingnoteGroup = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/billingnote-group`, {
    params: params,
  });
};

export const createBillingNote = async (data) => {
  const formData = new FormData();

  data.invoice_codes.forEach((code, index) => {
    formData.append(`invoice_codes[${index}]`, code);
  });
  formData.append("actual_date", data.actual_date);
  formData.append("amount_per_trip", data.amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);
  formData.append("wht", data.wht);

  return await instance.post(
    `api/rental-finance/lessor/billingnote`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const cancelBillingnote = async (billCode, body) => {
  return await instance.post(
    `api/rental-finance/lessor/billingnote/${billCode}/cancel`,
    body
  );
};

export const getPaid = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/paid`, {
    params: params,
  });
};

export const getPaidGroup = async (params = {}) => {
  return await instance.get(`api/rental-finance/lessor/paid-group`, {
    params: params,
  });
};

export const createPaid = async (data) => {
  const formData = new FormData();

  data.bill_codes.forEach((code, index) => {
    formData.append(`bill_codes[${index}]`, code);
  });

  formData.append("actual_date", data.actual_date);
  formData.append("amount_per_trip", data.amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("amount_of_transfer_actual", data.amount_of_transfer_actual);
  formData.append("bank_id", data.bank_id);
  formData.append("ref", data.ref);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);
  formData.append("wht", data.wht);
  return await instance.post(`api/rental-finance/lessor/Paid`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelPaid = async (receiptCode, body) => {
  return await instance.post(
    `api/rental-finance/lessor/Paid/${receiptCode}/cancel`,
    body
  );
};

export const createWht = async (params) => {
  return await instance.get(`/api/rental-finance/lessor/paid/wht`, {
    params: params,
    responseType: "blob",
  });
};

export const getLessorTransaction = async (params = {}) => {
  return await instance.get(`/api/rental-finance/lessor/truck-transaction`, {
    params: params,
  });
};

export const editLessorTransaction = async (type, code, body) => {
  return await instance.patch(
    `/api/rental-finance/lessor/truck-transaction/${type}/${code}`,
    body,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
};

export const getLessorEditLog = async (params = {}) => {
  return await instance.get(
    `/api/rental-finance/lessor/truck-transaction/edit-log`,
    {
      params: params,
    }
  );
};
