import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getDashboardCountWork,
  getDashboardSumWork,
  getDashboardWork,
} from "utils/api/dashboard/newDashboard";
import helper from "utils/helper";
import { getAllUser as getAllUserAPI } from "utils/api/user/user";
import ModalWork from "components/work/modalWork";
import {
  Search,
  CleaningServices,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import FieldInput from "components/field/FieldInput";
import Pagination from "components/pagination/Pagination";
import LightTooltip from "components/tooltip/LightTooltip";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import { FaWindowMinimize } from "react-icons/fa";
const NewDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dataWork, setDataWork] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [barData, setBarData] = useState(null);
  const [showGetProfile, setShowGetProfile] = useState(false);
  const firstDayOfMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD 00:00:00");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");
  const [filterDashboard, setFilterDashboard] = useState({
    type_data_set: "day",
    start_date: firstDayOfMonth,
    end_date: yesterday,
    on_dashboard: true,
  });
  const [filterProfile, setFilterProfile] = useState({
    sort_by: "updated_at",
    search_by: "company_name",
    search_val: "",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 3,
    total: 0,
    is_main: [true],
    profile_type: [],
    role: ["shipper"],
    active: ["true"],
  });
  const [profileList, setProfileList] = useState([]);
  const [totalProfile, setTotalProfile] = useState(0);
  const [optionCalculate, setOptionCalculate] = useState([]);
  const [optionCalculate2, setOptionCalculate2] = useState([]);
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);
  const [allIncome, setAllIncome] = useState(0);
  const [allWork, setAllWork] = useState(0);
  const [allTarget, setAllTarget] = useState(0);
  const [visibleTarget, setVisibleTarget] = useState(true);
  console.log("graph", graph1, graph2);
  console.log("allIncome", allIncome);
  console.log("allTarget", allTarget);
  const subDashboard = [
    {
      label: "งานขนส่ง",
      path: "/",
    },
  ];
  const breadcrumbs = [
    {
      label: "งานขนส่ง",
      path: null,
    },
  ];

  useEffect(() => {
    if (dataWork) {
      const optionCalculate = dataWork.map((item) => {
        return {
          value: { sum: item.sum, name: item.xname, count: item.count },
          name: item.xname,
        };
      });
      setOptionCalculate(optionCalculate);
    }
  }, [dataWork]);

  //default graph
  useEffect(() => {
    handleFirstDropdownChange(
      optionCalculate[optionCalculate.length - 2]?.value
    );
    setGraph2(optionCalculate[optionCalculate.length - 1]?.value);
  }, [optionCalculate]);

  const handleFirstDropdownChange = (value) => {
    setGraph1(value);
    setGraph2(null);
    const selectedIndex = optionCalculate?.findIndex(
      (option) => option?.name === value?.name
    );
    console.log("selectedIndex", selectedIndex);
    const newFilteredOptions = optionCalculate.slice(selectedIndex + 1);
    console.log("newFilteredOptions", newFilteredOptions);
    setOptionCalculate2(newFilteredOptions);
  };

  useEffect(() => {
    getDataCount();
  }, [filterDashboard]);

  const getDataCount = async (fil = filterDashboard) => {
    let _filter = {};
    if (fil.on_dashboard !== undefined && fil.on_dashboard === "all") {
      const { on_dashboard, ...rest } = fil;
      _filter = rest;
    } else {
      _filter = fil;
    }

    await getDashboardWork(_filter).then((res) => {
      const response = res.data.data;
      const mapGroups = response?.map((item) => {
        return {
          ...item,
          groups: Object.values(
            item.groups?.reduce(
              (acc, { company_name, shipper_freight_before_tax }) => {
                if (!acc[company_name]) {
                  acc[company_name] = {
                    company_name,
                    count: 0,
                    income: 0,
                  };
                }
                acc[company_name].count += 1;
                acc[company_name].income += shipper_freight_before_tax;
                return acc;
              },
              {}
            )
          ).sort((a, b) => b.income - a.income),
        };
      });

      const totalIncome = response.reduce(
        (sum, item) => sum + Number(item.sum),
        0
      );
      const totalTarget = response.reduce(
        (sum, item) => sum + Number(item.target),
        0
      );
      const totalWork = response.reduce((sum, item) => sum + item.count, 0);

      setDataWork(mapGroups);
      setBarData(mapGroups[mapGroups.length - 1]);
      setAllIncome(totalIncome);
      setAllWork(totalWork);
      setAllTarget(totalTarget);
      const max = Math.max(...response.map((item) => item.sum));
      //   const niceMaxValue = Math.ceil((max + max * 0.1) / 10000) * 10000;

      const niceMaxValue = Math.ceil(max + max * 0.1);
      setMaxValue(niceMaxValue);
    });
  };

  useEffect(() => {
    if (profileList.length > 0) {
      getProfile();
    }
  }, [filterProfile.page]);

  const calculatePercentage = (a, b) => {
    if (a === 0 || a === undefined) {
      return null;
    }
    const c = b - a;
    const d = (c / a) * 100;
    return d.toFixed(2);
  };

  const getProfile = async (filter = filterProfile) => {
    const body = {
      shipper: {},
      carrier: {},
      driver: {},
    };
    await getAllUserAPI(filter, body)
      .then((response) => {
        console.log(response.data.data);
        setProfileList(response.data.data);
        setTotalProfile(response.data.total);
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
        } else if (error.response.data.code === "4105") {
          // removeAccessToken("access_token");
          // window.location.reload();
        }
      });
  };
  const formatNumber = (number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const CustomLegend = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            width: "100%",
          }}
        >
          {payload.map((entry, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: entry.color,
                  marginRight: 5,
                  borderRadius: 50,
                }}
              ></div>
              <span>{entry.value == "sum" ? "รายได้" : "เป้าหมาย"}</span>
            </div>
          ))}
          {visibleTarget ? (
            <Visibility size={20} onClick={() => setVisibleTarget(false)} />
          ) : (
            <VisibilityOff size={20} onClick={() => setVisibleTarget(true)} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            whiteSpace: "nowrap",
          }}
        >
          {"รวมทั้งหมด " + formatNumber(allWork) + " งาน"}
        </div>
      </div>
    );
  };

  const CustomTooltip = ({ payload, label, active }) => {
    if (!active || !payload || payload.length === 0) return null;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          background: "white",
          borderRadius: 10,
          border: "1px solid black",
          padding: 20,
          flexDirection: "column",
        }}
      >
        <div>{payload[0].payload.xname}</div>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: 50,
              }}
            ></div>
            <span>
              {entry.dataKey == "target" ? "เป้าหมาย : " : "รายได้ : "}
              {formatNumber(entry.value)}
            </span>
          </div>
        ))}
        <div>
          <div>
            ส่วนต่าง(บาท) :
            <span
              style={{
                color:
                  Number(payload[0].payload.sum) -
                    Number(payload[0].payload.target) >
                  0
                    ? "green"
                    : Number(payload[0].payload.sum) -
                        Number(payload[0].payload.target) <
                      0
                    ? "red"
                    : "primary.main",
              }}
            >
              {formatNumber(
                (
                  Number(payload[0].payload.sum) -
                  Number(payload[0].payload.target)
                ).toFixed(2)
              )}
            </span>
          </div>
          <div>
            ส่วนต่าง(%) :
            <span
              style={{
                color:
                  calculatePercentage(
                    Number(payload[0].payload.target),
                    Number(payload[0].payload.sum)
                  ) > 0
                    ? "green"
                    : calculatePercentage(
                        Number(payload[0].payload.target),
                        Number(payload[0].payload.sum)
                      ) < 0
                    ? "red"
                    : "primary.main",
              }}
            >
              {formatNumber(
                calculatePercentage(
                  Number(payload[0].payload.target),
                  Number(payload[0].payload.sum)
                )
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const CustomBarShape = (props) => {
    const { x, y, width, height, fill } = props;
    const borderRadius = 5;
    const borderColor = "#00C49F";
    const borderWidth = 1;
    if (height <= 0) {
      return null;
    }
    return (
      <path
        d={`
          M${x},${y + height} 
          L${x},${y + borderRadius} 
          Q${x},${y} ${x + borderRadius},${y} 
          L${x + width - borderRadius},${y} 
          Q${x + width},${y} ${x + width},${y + borderRadius} 
          L${x + width},${y + height} 
          Z
        `}
        fill={fill}
        stroke={borderColor}
        strokeWidth={borderWidth}
      />
    );
  };

  return (
    <Container>
      <SubMenu
        menus={subDashboard.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `งานขนส่ง` ? true : false,
          };
        })}
        breadcrumbs={breadcrumbs}
      />
      <div
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"แสดงตาม"}
              options={[
                { value: "year", name: "รายปี" },
                { value: "month", name: "รายเดือน" },
                { value: "week", name: "รายสัปดาห์" },
                { value: "day", name: "รายวัน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"day"}
              value={filterDashboard.type_data_set}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  type_data_set: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px", paddingBottom: "20px" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterDashboard.start_date ?? firstDayOfMonth,
                end_date: filterDashboard.end_date ?? yesterday,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"ไม่รวมงานโปรเจ็ค"}
              options={[
                { value: true, name: "ใช่" },
                { value: false, name: "ไม่ใช่" },
                { value: "all", name: "ทั้งหมด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={true}
              value={filterDashboard?.on_dashboard}
              onChange={(val) =>
                setFilterDashboard({
                  ...filterDashboard,
                  on_dashboard: val,
                })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
              <div style={{ flex: 0.7, paddingRight: 20 }}>
                <FieldInput
                  value={filterDashboard.profile_name ?? ""}
                  label={"ชื่อโปรไฟล์"}
                  style={{ width: "-webkit-fill-available" }}
                  readOnly
                  closePaddingBottom
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 0.3,
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "secondary.main",
                  }}
                  onClick={() => {
                    setShowGetProfile(true);
                  }}
                >
                  <Search sx={{ color: "white", fontSize: 30 }} />
                </Avatar>
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "40px",
                    height: "40px",
                    // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                    filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                    bgcolor: "white",
                  }}
                  onClick={() => {
                    setFilterDashboard({
                      ...filterDashboard,
                      profile_id: null,
                      profile_name: null,
                    });
                  }}
                >
                  <CleaningServices
                    sx={{ color: "secondary.main", fontSize: 30 }}
                  />
                </Avatar>
                {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px" }}
                  color="secondary"
                  onClick={() => {
                    // getProject();
                    setShowGetProfile(true);
                  }}
                >
                  ค้นหา
                </Button> */}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"กราฟแท่งที่1(ก่อน)"}
              options={optionCalculate}
              style={{
                width: "-webkit-fill-available",
              }}
              value={graph1}
              onChange={handleFirstDropdownChange}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid
            style={{ paddingRight: "20px" }}
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
          >
            <FieldSelect
              name={"กราฟแท่งที่2(หลัง)"}
              options={optionCalculate2}
              style={{
                width: "-webkit-fill-available",
              }}
              value={graph2}
              onChange={(value) => setGraph2(value)}
              closePaddingBottom={true}
              readOnly={graph1 ? false : true}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          spacing={1}
          sx={{ alignItems: "stretch", marginTop: "10px" }}
        >
          <Grid item xs={8}>
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                marginBottom: "5px",
                borderRadius: "10px",
              }}
            >
              <Typography
                fontSize={18}
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontWeight: 500,
                }}
              >
                รายได้
              </Typography>
              <ResponsiveContainer width="100%" height={450}>
                <ComposedChart
                  width={730}
                  height={500}
                  data={dataWork}
                  margin={{
                    top: 30,
                    right: 50,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <XAxis
                    dataKey={"xname"}
                    style={{ fontSize: "10px", fontWeight: 500 }}
                  />
                  <YAxis
                    style={{ fontSize: "10px", fontWeight: 500 }}
                    tickFormatter={formatNumber}
                    domain={[0, maxValue]}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                  <CartesianGrid stroke="#f5f5f5" />
                  <Bar
                    dataKey="sum"
                    barSize={20}
                    fill={`rgba(0,196,159,0.25)`}
                    onClick={(data, index) => setBarData(data.payload)}
                    shape={<CustomBarShape />}
                  >
                    <LabelList
                      dataKey="label"
                      content={(props) => {
                        const { x, y, value, width } = props;
                        const lines = value.split("\n");
                        const offset = 40;
                        return (
                          <text
                            x={x + width / 2}
                            y={y - offset}
                            fill="#00C49F"
                            textAnchor="middle"
                            fontSize="10px"
                            fontWeight="500"
                            transform={`rotate(-57, ${x + width + 5}, ${
                              y - offset
                            })`}
                          >
                            {lines.map((line, index) => (
                              <tspan
                                key={index}
                                x={x + width / 2}
                                dy={index * 12}
                              >
                                {line}
                              </tspan>
                            ))}
                          </text>
                        );
                      }}
                    />
                  </Bar>
                  {visibleTarget && (
                    <Line
                      strokeWidth={2}
                      dataKey="target"
                      stroke={`rgba(0,136,254,0.75)`}
                    />
                  )}
                  {/* <LabelList
                      dataKey="target"
                      position="bottom"
                      style={{
                        fontSize: 10,
                        backgroundColor: "black",
                        fontWeight: 500,
                      }}
                      formatter={formatNumber}
                    />
                  </Line> */}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} sx={{ alignItems: "stretch" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{ height: "100%", alignItems: "stretch" }}
                >
                  <Grid item xs={6} sx={{ height: 170 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <Typography
                        fontSize={18}
                        style={{
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        รายได้เปลี่ยนแปลง
                      </Typography>
                      <Typography
                        fontSize={10}
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        คำนวนจากช่วงก่อนหน้า
                      </Typography>
                      <Grid container justifyContent="center">
                        <Grid item xs={3} sx={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {calculatePercentage(graph1?.sum, graph2?.sum) >
                              0 ? (
                                <ImArrowUp color="#27CE88" size={40} />
                              ) : calculatePercentage(
                                  graph1?.sum,
                                  graph2?.sum
                                ) < 0 ? (
                                <ImArrowDown color="#FF316F" size={40} />
                              ) : (
                                <FaWindowMinimize color="#FFC675" size={40} />
                              )}
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                color:
                                  Number(
                                    calculatePercentage(
                                      graph1?.sum,
                                      graph2?.sum
                                    )
                                  ) > 0
                                    ? "#27CE88"
                                    : Number(
                                        calculatePercentage(
                                          graph1?.sum,
                                          graph2?.sum
                                        )
                                      ) < 0
                                    ? "#FF316F"
                                    : "#FFC675",
                                fontSize: "14px",
                              }}
                            >
                              {graph1 && graph2
                                ? helper.addComma(
                                    calculatePercentage(graph1.sum, graph2.sum)
                                  )
                                : "-"}
                              <p style={{ margin: 0, lineHeight: 1 }}>%</p>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  textAlign: "end",
                                  fontSize: "14px",
                                  color:
                                    Number(
                                      calculatePercentage(
                                        graph1?.sum,
                                        graph2?.sum
                                      )
                                    ) > 0
                                      ? "#27CE88"
                                      : Number(
                                          calculatePercentage(
                                            graph1?.sum,
                                            graph2?.sum
                                          )
                                        ) < 0
                                      ? "#FF316F"
                                      : "#FFC675",
                                }}
                              >
                                {!(graph2?.sum === 0 && graph1?.sum === 0)
                                  ? Number(graph2?.sum - graph1?.sum) > 0
                                    ? "สูงกว่าช่วงก่อนหน้า"
                                    : Number(graph2?.sum - graph1?.sum) < 0
                                    ? "ต่ำกว่าช่วงก่อนหน้า"
                                    : Number(graph2?.sum - graph1?.sum) === 0
                                    ? "เท่ากับช่วงก่อนหน้า"
                                    : "-"
                                  : "-"}
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  textAlign: "end",
                                  fontSize: "14px",
                                  color:
                                    Number(
                                      calculatePercentage(
                                        graph1?.sum,
                                        graph2?.sum
                                      )
                                    ) > 0
                                      ? "#27CE88"
                                      : Number(
                                          calculatePercentage(
                                            graph1?.sum,
                                            graph2?.sum
                                          )
                                        ) < 0
                                      ? "#FF316F"
                                      : "#FFC675",
                                }}
                              >
                                {graph1 && graph2
                                  ? helper.addComma(graph2.sum - graph1.sum) +
                                    " บาท"
                                  : "-"}
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  textAlign: "end",
                                  fontSize: "14px",
                                  color:
                                    Number(
                                      calculatePercentage(
                                        graph1?.sum,
                                        graph2?.sum
                                      )
                                    ) > 0
                                      ? "#27CE88"
                                      : Number(
                                          calculatePercentage(
                                            graph1?.sum,
                                            graph2?.sum
                                          )
                                        ) < 0
                                      ? "#FF316F"
                                      : "#FFC675",
                                }}
                              >
                                {graph1 && graph2
                                  ? formatNumber(
                                      graph2?.count - graph1?.count
                                    ) + " งาน"
                                  : "-"}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} sx={{ height: 170 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <Typography
                        fontSize={18}
                        style={{
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        รายได้สะสม
                      </Typography>
                      <Typography
                        fontSize={10}
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        คำนวนจากช่วงที่เลือก
                      </Typography>
                      <Grid container justifyContent="center">
                        <Grid item xs={3} sx={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {calculatePercentage(
                                Number(allTarget),
                                Number(allIncome)
                              ) > 0 ? (
                                <ImArrowUp color="#27CE88" size={40} />
                              ) : calculatePercentage(
                                  Number(allTarget),
                                  Number(allIncome)
                                ) < 0 ? (
                                <ImArrowDown color="#FF316F" size={40} />
                              ) : (
                                <FaWindowMinimize color="#FFC675" size={40} />
                              )}
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                color:
                                  calculatePercentage(
                                    Number(allTarget),
                                    Number(allIncome)
                                  ) > 0
                                    ? "#27CE88"
                                    : calculatePercentage(
                                        Number(allTarget),
                                        Number(allIncome)
                                      ) < 0
                                    ? "#FF316F"
                                    : "#FFC675",
                                fontSize: "14px",
                              }}
                            >
                              {allTarget && allIncome
                                ? formatNumber(
                                    calculatePercentage(
                                      Number(allTarget),
                                      Number(allIncome)
                                    )
                                  )
                                : "-"}
                              <p style={{ margin: 0, lineHeight: 1 }}>%</p>
                            </div>
                          </div>
                        </Grid>

                        <Grid item xs={9}>
                          <Grid container>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  textAlign: "end",
                                  color: "primary.main",
                                  fontSize: "14px",
                                }}
                              >
                                {helper.addComma(allIncome) + " บาท" ?? "-"}
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  textAlign: "end",
                                  fontSize: "14px",
                                  color:
                                    calculatePercentage(
                                      Number(allTarget),
                                      Number(allIncome)
                                    ) > 0
                                      ? "#27CE88"
                                      : calculatePercentage(
                                          Number(allTarget),
                                          Number(allIncome)
                                        ) < 0
                                      ? "#FF316F"
                                      : "#FFC675",
                                }}
                              >
                                {calculatePercentage(
                                  Number(allTarget),
                                  Number(allIncome)
                                ) > 0
                                  ? "สูงกว่าแผน"
                                  : calculatePercentage(
                                      Number(allTarget),
                                      Number(allIncome)
                                    ) < 0
                                  ? "ต่ำกว่าแผน"
                                  : calculatePercentage(
                                      Number(allTarget),
                                      Number(allIncome)
                                    ) === 0
                                  ? "เท่ากับแผน"
                                  : "-"}
                              </div>
                            </Grid>

                            {/* <Grid item xs={12}>
                              <div
                                style={{
                                  textAlign: "end",
                                  color:
                                    calculatePercentage(
                                      Number(allTarget),
                                      Number(allIncome)
                                    ) > 0
                                      ? "#27CE88"
                                      : calculatePercentage(
                                          Number(allTarget),
                                          Number(allIncome)
                                        ) < 0
                                      ? "#FF316F"
                                      : "primary.main",
                                }}
                              >
                                {formatNumber(
                                  calculatePercentage(
                                    Number(allTarget),
                                    Number(allIncome)
                                  )
                                ) + " %" ?? "-"}
                              </div>
                            </Grid> */}
                            <Grid item xs={12}>
                              <div
                                style={{
                                  textAlign: "end",
                                  fontSize: "14px",
                                  color:
                                    calculatePercentage(
                                      Number(allTarget),
                                      Number(allIncome)
                                    ) > 0
                                      ? "#27CE88"
                                      : calculatePercentage(
                                          Number(allTarget),
                                          Number(allIncome)
                                        ) < 0
                                      ? "#FF316F"
                                      : "#FFC675",
                                }}
                              >
                                {formatNumber(
                                  (allIncome - allTarget).toFixed(2)
                                ) + " บาท" ?? "-"}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ height: 360 }}>
                <Box
                  style={{
                    backgroundColor: "white",
                    padding: "15px",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    fontSize={18}
                    style={{
                      textAlign: "center",
                      marginBottom: "15px",
                      fontWeight: 500,
                    }}
                  >
                    10 อันดับลูกค้าที่ใช้งานมากที่สุด
                  </Typography>
                  <Grid container>
                    <Grid item xs={5}>
                      <div>ชื่อ</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "end" }}>จำนวน</div>
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ textAlign: "end" }}>รายได้</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "end" }}>%</div>
                    </Grid>
                  </Grid>
                  {barData?.groups?.map(
                    (row, index) =>
                      index < 10 && (
                        <Grid container key={index} spacing={1}>
                          <Grid item xs={5}>
                            <LightTooltip title={row.company_name}>
                              <div
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {row.company_name}
                              </div>
                            </LightTooltip>
                          </Grid>
                          <Grid item xs={2}>
                            <div style={{ textAlign: "end" }}>
                              {formatNumber(row.count)}
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ textAlign: "end" }}>
                              {helper.addComma(row.income)}
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div style={{ textAlign: "end" }}>
                              {helper.addComma(
                                (row.income / Number(barData?.sum)) * 100
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      )
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ModalWork
        close={() => {
          setShowGetProfile(false);
          setProfileList([]);
          setTotalProfile(0);
          setFilterProfile({
            ...filterProfile,
            search_val: "",
            page: 1,
            per_page: 3,
            total: 0,
          });
        }}
        open={showGetProfile}
        minHeight
        enableClose
      >
        <div style={{ padding: 20 }}>
          <div style={{ textAlign: "center" }}>โปรไฟล์ลูกค้า</div>
          <Line />
          <TextField
            id="search_val"
            variant="standard"
            style={{
              paddingTop: "16px",
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={filterProfile.search_val}
            onChange={(val) => {
              setFilterProfile({
                ...filterProfile,
                search_val: val.target.value,
              });
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            style={{ borderRadius: "10px", marginTop: 20 }}
            color="secondary"
            onClick={() => {
              getProfile();
            }}
          >
            ค้นหา
          </Button>
          <div style={{ marginTop: 10 }}>
            {profileList.map((ele, index) => {
              return (
                <div
                  key={"profile" + index}
                  style={{
                    borderRadius: 10,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 10,
                    borderColor:
                      filterDashboard.profile_id === ele.user_profile.profile.id
                        ? "#121f43"
                        : "",
                    color:
                      filterDashboard.profile_id === ele.user_profile.profile.id
                        ? "#121f43"
                        : "",
                    cursor: "pointer",
                    marginTop: 10,
                  }}
                  onClick={() => {
                    console.log(ele);
                    setFilterDashboard({
                      ...filterDashboard,
                      profile_id: ele.user_profile.profile.id,
                      profile_name: ele.user_profile.profile.company_name,
                    });
                  }}
                >
                  <div>{ele.user_code}</div>
                  <div style={{ fontSize: 20, fontWeight: 500 }}>
                    {ele.user_profile.profile.company_name}
                  </div>
                  <div>
                    {"สร้างบัญชีเมื่อ: " +
                      helper.momentDateTime(ele.created_at)}
                  </div>
                </div>
              );
            })}
            <Pagination
              pagination={{
                page: filterProfile.page,
                per_page: filterProfile.per_page,
                total: totalProfile,
              }}
              setPagination={(val) => {
                console.log(val);
                setFilterProfile({
                  ...filterProfile,
                  per_page: val.per_page,
                  page: val.page,
                });
              }}
            />
            {filterDashboard.profile_id && profileList.length > 0 ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                color="secondary"
                onClick={async () => {
                  setShowGetProfile(false);
                  setProfileList([]);
                  setTotalProfile(0);
                  setFilterProfile({
                    ...filterProfile,
                    search_val: "",
                    page: 1,
                    per_page: 3,
                    total: 0,
                  });
                }}
              >
                ยืนยัน
              </Button>
            ) : null}
          </div>
        </div>
      </ModalWork>
    </Container>
  );
};

export default NewDashboard;
