import React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

const FieldTextArea = ({
  placeholder = ``,
  onChange = () => {},
  value = ``,
  minRows = 10,
  maxRows = 10,
  error = false,
  errorMessage = "",
}) => {
  return (
    <div style={{ width: "100%" }}>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        minRows={minRows}
        maxRows={maxRows}
        error={error}
      />
      {error && errorMessage && (
        <p style={{ color: "#FF316F", fontSize: "14px", margin: "4px 0 0" }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default FieldTextArea;

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#121f43",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme, error }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'Kanit', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${
      error
        ? "#FF316F" // Error color
        : theme.palette.mode === "dark"
        ? grey[700]
        : grey[200]
    };
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${error ? "#FF316F" : blue[400]};
    }

    &:focus {
      border-color: ${error ? "#FF316F" : blue[400]};
      box-shadow: 0 0 0 1px ${
        theme.palette.mode === "dark" ? blue[400] : blue[400]
      };
    }

    &:focus-visible {
      outline: 0;
    }
  `
);
