import {
  Box,
  Container,
  FormControl,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import "../finance.css";
import { subMenuFinance } from "utils/submenuoptions";
import { getPaidGroup, cancelPaid, createWht } from "utils/api/finance/carrier";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import UpdateStatusButton2way from "components/button/finance/UpdateStatusButton2way";
import io from "socket.io-client";
import CustomLinearProgress from "components/feedback/CustomLinearProgress";
import API from "../../../utils/api/work/work";
import SelectRowValue from "components/button/SelectRowValue";
import FieldTextArea from "components/field/FieldTextArea";
import AuthContext from "components/context/AuthContext";

const CarrierReceiptGroup = () => {
  const { adminAuth } = useContext(AuthContext);
  const apiWork = API.create();
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "pay_in_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "work_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);

  //updatestatus modal
  const [updateStatusForm, setUpdateStatusForm] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  // const [error, setError] = useState({
  //   actual_date: false,
  //   work_code: false,
  //   file: false,
  // });

  //submunu
  const [subMenu_menus, setSubMenuMenus] = useState([]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [setSubmenuDone, setSetSubmenuDone] = useState(false);

  const [socketId, setSocketId] = useState();
  const [progress, setProgress] = useState(0);
  const [cancelDetail, setCancelDetail] = useState("");
  const [errorDetail, setErrorDetail] = useState(false);

  useEffect(() => {
    if (cancelDetail !== "") {
      setErrorDetail(false);
    }
  }, [cancelDetail]);
  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_ENPOINT, {
      auth: { Authorization: helper.getCookie("access_token") },
    });
    socket.on("message", (data) => {
      console.log("Received message from server:", data);
      console.log("socket", socket.id);
      setSocketId(socket.id);
    });
    socket.on("downloading", (data) => {
      setProgress(data);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    setSubMenuBreadcrumbs([
      {
        label: "ข้อมูลการเงิน",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้ขนส่ง",
        path: null,
      },
      {
        label: "ประวัติการแจ้งโอน",
        path: null,
      },
    ]);
    handleSearch();
    getStatus();
  }, []);

  useEffect(() => {
    if (adminAuth) {
      const filteredMenus = subMenuFinance.filter((menu) => {
        return (
          !menu.fix_role || menu.fix_role.includes(adminAuth.admin_role_type)
        );
      });
      setSubMenuMenus(filteredMenus);
      setSetSubmenuDone(true);
    }
  }, [adminAuth]);

  useEffect(() => {
    if (setSubmenuDone) {
      setActiveSubMenu(13);
      setSetSubmenuDone(false);
    }
  }, [setSubmenuDone]);
  useEffect(() => {
    if (rowSelected.length > 0) {
      setItems((prevItems) =>
        prevItems.map((item) => {
          console.log(item.key);
          console.log(rowSelected[0].key);
          if (item.key !== rowSelected[0].key) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const getStatus = async () => {
    apiWork
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        setWorkStatus(
          response.data.results.map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      work_code: "",
      invoice_code: "",
      carrier_name: "",
      project_name: "",
      bill_code: "",
      pay_in_code: "",
      search: "",
      profile_type:
        formdata.profile_type !== "ALL" ? formdata.profile_type : "",
      payment_type:
        formdata.payment_type !== "ALL" ? formdata.payment_type : "",
      work_status: formdata.work_status !== "ALL" ? formdata.work_status : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getPaidGroup(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;

        const resultWithKey = result.map((item) => {
          return { ...item, key: item.bill_code };
        });

        const uniqueCarrier_Ids = [
          ...new Set(result.map((item) => item.carrier_id)),
        ];

        const updatedResult = resultWithKey.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;
          if (resetCheckBox !== true) {
            if (rowSelected.length > 0) {
              const carrierID = rowSelected[0].carrier_id;
              rowIsActive = rowSelected.some(
                (selectedItem) => selectedItem.key === item.key
              );
              rowDisabled = carrierID !== item.carrier_id;
            }
          }
          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
            key: item.pay_in_code,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
    }
  };

  const handleOnReset = () => {
    setFormSearch({
      ...formSearch,
      profile_type: "ALL",
      payment_type: "ALL",
      work_status: "ALL",
    });
  };

  const handleUpdatestatusFormChange = (name, event) => {
    const value = event;
    setUpdateStatusForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnclickUpdateStatusButton = () => {
    setUpdateStatusForm({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    });
    return true;
  };

  const handleCancelStatus = async () => {
    if (cancelDetail === "") {
      setErrorDetail(true);
    } else {
      try {
        const body = {
          detail: cancelDetail,
        };
        const response = await cancelPaid(rowSelected[0].pay_in_code, body);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Notification Error",
          error.response.data.description
        );
        return false;
      }
    }
  };

  const handleGenWht = async () => {
    const params = {
      year: updateStatusForm.year,
      month: updateStatusForm.month,
      client_id: socketId,
    };
    try {
      const response = await createWht(params);
      if (response) {
        // console.log(response);
        helper.downloadFileFromResponse(response);
        setProgress(0);
        // const link = document.createElement("a");
        // link.href = href;
        // link.setAttribute("download", "file.pdf");
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.log(error);
    }
    console.log("handleGenWht");
    return false;
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "pay_in_date", name: "วันที่ชำระล่าสุด" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "work_code", name: "เลขที่งาน" },
                { value: "project_name", name: "ชื่อโครงการ" },
                { value: "carrier_name", name: "ชื่อโปรไฟล์ผู้ขนส่ง" },
                { value: "invoice_code", name: "เลขที่ใบตั้งเบิก" },
                { value: "bill_code", name: "เลขที่ใบเตรียมแจ้งโอน" },
                { value: "pay_in_code", name: "เลขที่ใบแจ้งโอน" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <UpdateStatusButton2way
              title="GEN E-WHT / ยกเลิกการแจ้งโอน"
              description="คุณต้องการ GEN E-WHT หรือยกเลิกการเตรียมแจ้งโอน?"
              textUpdateBtn="GEN E-WHT"
              textCancelBtn="ยกเลิกการแจ้งโอน"
              cancelDescription="ยืนยันการยกเลิกการแจ้งโอน?"
              onClick={handleOnclickUpdateStatusButton}
              onConfirmCancel={handleCancelStatus}
              onConfirmUpdate={handleGenWht}
              disabledCancelBtn={rowSelected.length === 0}
              textConfirmUpdateBtn="ดาวน์โหลด"
              selectedLength={rowSelected.length}
              openNotificationWithIcon={openNotificationWithIcon}
              onClose={() => setCancelDetail("")}
              childrenCancel={() => (
                <FieldTextArea
                  placeholder="กรุณากรอกเหตุผลในการยกเลิก"
                  onChange={(e) => setCancelDetail(e.target.value)}
                  value={cancelDetail}
                  error={errorDetail}
                  errorMessage={"*จำเป็นต้องระบุ"}
                />
              )}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButton2way>
            <FormControl>
              <SelectRowValue
                onSelect={(e) => {
                  setPagination({ ...pagination, per_page: e });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
            <AdvanceSearchButton
              onReset={handleOnReset}
              onSearch={handleSearch}
              value={formSearch}
            >
              {renderFormAdvSearch()}
            </AdvanceSearchButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormUpdateStatus = () => {
    function createYearArray() {
      const currentYear = new Date().getFullYear();
      const startYear = 2023;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push(year);
      }

      return yearArray;
    }

    const years = createYearArray();
    const yearsOptions = years.map((year) => {
      return { value: year, name: year };
    });

    const monthOptions = [
      { value: "1", name: "1" },
      { value: "2", name: "2" },
      { value: "3", name: "3" },
      { value: "4", name: "4" },
      { value: "5", name: "5" },
      { value: "6", name: "6" },
      { value: "7", name: "7" },
      { value: "8", name: "8" },
      { value: "9", name: "9" },
      { value: "10", name: "10" },
      { value: "11", name: "11" },
      { value: "12", name: "12" },
    ];

    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="ปีที่ต้องการ GEN*"
              options={yearsOptions}
              value={updateStatusForm.year}
              onChange={(e) => handleUpdatestatusFormChange("year", e)}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="เดือนที่ต้องการ GEN*"
              options={monthOptions}
              value={updateStatusForm.month}
              onChange={(e) => handleUpdatestatusFormChange("month", e)}
            />
          </FormControl>
          <CustomLinearProgress progress={progress} />
        </form>
      </Box>
    );
  };

  const renderFormAdvSearch = () => {
    return (
      <>
        <form>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "pay_in_date", name: "วันที่ชำระล่าสุด" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </FormControl>
          <FieldDateRangePicker
            className="FieldDateRangePicker-drawer w-100"
            popupClassName="FieldDateRangePicker-drawer-popup"
            name={`วันเริ่มต้น - วันสิ้นสุด`}
            value={{
              start_date: formSearch.dateFrom,
              end_date: formSearch.dateTo,
            }}
            onChange={(e) => handleOnChangeSearch(e, "date")}
            style={{ marginBottom: "23px" }}
          />
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทผู้ขนส่ง`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              value={formSearch.profile_type}
              onChange={(e) => handleOnChangeSearch(e, "profile_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทการชำระเงินงตาม`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "เงินสด" },
                { value: "1", name: "เครดิต" },
              ]}
              value={formSearch.payment_type}
              onChange={(e) => handleOnChangeSearch(e, "payment_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะงาน`}
              options={[{ value: "ALL", name: "ทั้งหมด" }, ...workStatus]}
              value={formSearch.work_status}
              onChange={(e) => handleOnChangeSearch(e, "work_status")}
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderTable = () => {
    const fields = [
      {
        key: "pay_in_date",
        label: "วันที่แจ้งโอน",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "pay_in_code",
        label: "เลขที่แจ้งโอน",
        tdclass: "text-center",
      },
      {
        key: "carrier_name",
        label: "ชื่อโปรไฟล์ผู้ขนส่ง",
      },
      {
        key: "carrier_profile_type",
        label: "ประเภทโปรไฟล์",
        tdclass: "text-center",
      },
      // {
      //   key: "project_name",
      //   label: "ชื่อโครงการ",
      // },
      {
        key: "carrier_payment_type",
        label: "ประเภทการรับเงินของผู้ขนส่ง",
        tdclass: "text-center",
      },
      {
        key: "carrier_freight_before_tax",
        label: "ราคาผู้ขนส่งก่อนหักภาษี",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_freight_tax",
        label: "ภาษีหัก ณ ที่จ่าย",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_insurance_per_trip",
        label: "ประกันภัยสินค้า",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_penalty",
        label: "ค่าปรับ",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_freight_net",
        label: "ราคาผู้ขนส่งสุทธิ",
        render: "currency",
        tdclass: "text-end ",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={true}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          type="carrier"
        />
      </>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <div className="Finance CarrierReceiptGroup">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default CarrierReceiptGroup;
