import {
  Avatar,
  Container,
  Drawer,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import TableAntdCustom from "components/table/TableAntd/TableAntdCustom";
import React, { useState, useEffect } from "react";
import moment from "moment";
import helper from "utils/helper";
import ButtonCustom from "components/ButtonCustom";
import styles from "./TruckRental.module.css";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import {
  Search,
  Add,
  Edit,
  Delete,
  FilterAlt,
  RotateRight,
} from "@mui/icons-material";
// import ModalAddOrder from "./ModalAddOrder";
import CustomModal from "components/modal/CustomModal";
import AlertModal from "components/modal/AlertModal";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Loading from "components/loading/LoadingBackground";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import {
  getTradePaymentStatus,
  getWorkTradeStatus,
} from "utils/api/masterData/service";
import SelectRowValue from "components/button/SelectRowValue";
import {
  cancelOrderRental,
  getAllRentalOrder,
} from "utils/api/truckRental/truckRental";
import ModalAddRentalOrder from "./ModalAddRentalOrder";
import SubMenu from "components/layout/SubMenu.js";
const TableRental = () => {
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModalOrder, setOpenModalOrder] = useState({
    open: false,
    type: "",
    data: null,
  });
  // const [openModalTransport, setOpenModalTransport] = useState({
  //   open: false,
  //   type: "",
  //   data: null,
  //   order_id: null,
  //   order_code: null,
  //   trade_project_id: null,
  // });
  // const [openModalUpdateStatusTransport, setOpenModalUpdateStatusTransport] =
  //   useState({
  //     open: false,
  //     data: null,
  //   });
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [cancelModal, setCancelModal] = useState({ open: false, id: null });
  const [cancelTransport, setCancelTransport] = useState({
    open: false,
    id: null,
  });
  const [editData, setEditData] = useState(null);
  const [editDataTransport, setEditDataTransport] = useState(null);
  const [dataOrder, setDataOrder] = useState([]);
  const dateTo = moment().format("YYYY-MM-DD 23:59:59");
  const dateFrom = moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00");
  const [paidTransport, setPaidTransport] = useState({ open: false, id: null });
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [listWorkStatus, setListWorkStatus] = useState([]);
  const [listPaymentStatus, setListPaymentStatus] = useState([]);
  const [filter, setFilter] = useState({
    sort_by: "created_at",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
  });

  console.log("dataOrder", dataOrder);
  const subMenu = [
    {
      label: "ทำรายการเช่ารถ",
      path: "/truck-rental/rental-order",
    },
  ];
  const breadcrumbs = [
    {
      label: "ทำรายการเช่ารถ",
      path: null,
    },
  ];

  useEffect(() => {
    getOrder(filter);
  }, [filter.page, filter.per_page]);

  useEffect(() => {
    getWorkTradeStatusMaster();
    getTradePaymentStatusMaster();
  }, []);
  const getWorkTradeStatusMaster = async () => {
    await getWorkTradeStatus()
      .then((res) => {
        console.log("res", res);
        if (res.data.code === 1000) {
          const results = res.data.data.results;
          setListWorkStatus(
            results?.map((res) => ({ value: res.id, name: res.name }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getTradePaymentStatusMaster = async () => {
    await getTradePaymentStatus()
      .then((res) => {
        console.log("res", res);
        if (res.data.code === 1000) {
          const results = res.data.data.results;
          setListPaymentStatus(
            results?.map((res) => ({ value: res.id, name: res.name }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getOrder = async (_filter) => {
    setLoading(true);
    await getAllRentalOrder(_filter)
      .then((res) => {
        console.log(res);
        const results = res.data.results;
        //   results.forEach((item) => {
        //     item.trade_transports.forEach((option) => {
        //       option.sell_amount_unit = item.sell_amount_unit;
        //     });
        //   });

        setDataOrder(results);
        setFilter({ ...filter, total: res.data.total });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  const handleCancelOrder = async (id) => {
    await cancelOrderRental(id)
      .then((res) => {
        if (res.data.code === 1000) {
          setCancelModal({ open: false, id: null });
          getOrder(filter);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   const handleCancelTransport = async (id) => {
  //     await cancelTradeTransport(id)
  //       .then((res) => {
  //         if (res.data.code === 1000) {
  //           setCancelTransport({ open: false, id: null });
  //           getOrder(filter);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  const columnsMain = [
    {
      title: "ชื่อประกาศ",
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: "เลขที่ใบสั่งซื้อ",
      //   dataIndex: "order_code",
      key: "order_code",
      render: (val) => {
        return (
          <div style={{ textAlign: "center" }}>
            <div>{val?.order_code}</div>
            <div>#{val?.id}</div>
          </div>
        );
      },
    },
    {
      title: "วันที่สั่งซื้อ / วันสุดท้ายของรอบ",
      //   dataIndex: "puchase_date",
      key: "puchase_date",

      render: (val) => {
        return (
          <div>
            <div style={{ whiteSpace: "nowrap" }}>
              {val?.puchase_date
                ? helper.ToConvertDateTimeForTable(val?.puchase_date, false)
                : ""}
            </div>{" "}
            <div style={{ whiteSpace: "nowrap" }}>
              {val?.end_date_term
                ? helper.ToConvertDateTimeForTable(val?.end_date_term, false)
                : ""}
            </div>
          </div>
        );
      },
    },

    {
      title: "ผู้เช่า",
      key: "tenant",
      render: (value) => {
        return (
          <div>{value?.truck_tenant_project.truck_tenants?.company_name}</div>
        );
      },
    },
    {
      title: "โครงการ",
      key: "project",
      render: (value) => <div>{value.truck_tenant_project.project_name}</div>,
    },
    {
      title: "ผู้ให้เช่า",
      key: "lessor",
      render: (value) => {
        return <div>{value?.truck_lessor.lessor_name}</div>;
      },
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "จำนวนสินค้าที่เช่า",
      // dataIndex: "sell_amount",
      key: "tenant_amount",

      render: (val) => {
        const resultSellAmount = val?.tenant_amount
          ? helper.addComma(parseFloat(val?.tenant_amount)?.toFixed(2)) +
            " " +
            (val?.tenant_amount_unit || "")
          : null;

        return (
          <div>
            <div>{resultSellAmount || ""}</div>
          </div>
        );
      },
    },
    {
      title: "รวมราคาสินค้าที่เช่า (บาท)",
      // dataIndex: "sell_price",
      key: "tenant_price",
      render: (val) =>
        val?.tenant_price
          ? helper.addComma(parseFloat(val?.tenant_price)?.toFixed(2))
          : null,
    },
    {
      title: "รวมราคา OT ผู้เช่า",
      // dataIndex: "sell_price",
      key: "tenant_ot",
      render: (val) =>
        val?.tenant_ot
          ? helper.addComma(parseFloat(val?.tenant_ot)?.toFixed(2))
          : null,
    },
    {
      title: "รวมราคาสินค้าที่ให้เช่า (บาท)",
      // dataIndex: "sell_price",
      key: "lessor_price",
      render: (val) =>
        val?.lessor_price
          ? helper.addComma(parseFloat(val?.lessor_price)?.toFixed(2))
          : null,
    },
    {
      title: "รวมราคา OT ผู้ให้เช่า",
      // dataIndex: "sell_price",
      key: "lessor_ot",
      render: (val) =>
        val?.lessor_ot
          ? helper.addComma(parseFloat(val?.lessor_ot)?.toFixed(2))
          : null,
    },
    // {
    //   title: "สถานะงานล่าสุด",
    //   key: "work_status",

    //   render: (val) => {
    //     return (
    //       <div
    //         style={{
    //           color: val?.workStatus?.id === 3 ? "#27ce88" : "#121f43",
    //         }}
    //       >
    //         {val?.workStatus?.name}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "สถานะการชำระเงินล่าสุด",
    //   //   dataIndex: "trade_payment_status",
    //   key: "payment_status",

    //   render: (val) => {
    //     return (
    //       <div
    //         style={{
    //           color: val?.paymentStatus?.id === 3 ? "#27ce88" : "#121f43",
    //         }}
    //       >
    //         {val?.paymentStatus?.name}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "สถานะการชำระเงินผู้เช่า",
      key: "buyer_payment_status",
      render: (val) => {
        return (
          <div
            style={{
              color:
                val?.tenantPaymentStatus?.id === 1
                  ? "blue"
                  : val?.tenantPaymentStatus?.id === 2 ||
                    val?.tenantPaymentStatus?.id === 4 ||
                    val?.tenantPaymentStatus?.id === 6 ||
                    val?.tenantPaymentStatus?.id === 8
                  ? "#ff316f"
                  : val?.tenantPaymentStatus?.id === 7
                  ? "#27ce88"
                  : "#ffc675",
            }}
          >
            {val?.tenantPaymentStatus?.name}
          </div>
        );
      },
    },
    {
      title: "สถานะการชำระเงินผู้ให้เช่า",
      key: "buyer_payment_status",
      render: (val) => {
        return (
          <div
            style={{
              color:
                val?.lessorPaymentStatus?.id === 1
                  ? "blue"
                  : val?.lessorPaymentStatus?.id === 2 ||
                    val?.lessorPaymentStatus?.id === 4 ||
                    val?.lessorPaymentStatus?.id === 6 ||
                    val?.lessorPaymentStatus?.id === 8
                  ? "#ff316f"
                  : val?.lessorPaymentStatus?.id === 7
                  ? "#27ce88"
                  : "#ffc675",
            }}
          >
            {val?.lessorPaymentStatus?.name}
          </div>
        );
      },
    },
    {
      title: "ทำรายการ",
      key: "actions",
      width: 130,
      render: (val) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              paddingBottom: "5px",
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: 25,
                height: 25,
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              onClick={() =>
                val?.active
                  ? setOpenModalOrder({ open: true, type: "edit", data: val })
                  : null
              }
            >
              <Edit
                sx={{
                  color: val?.active ? "secondary.main" : "gray.main",
                  fontSize: 23,
                }}
              />
            </Avatar>
            <Avatar
              variant="rounded"
              sx={{
                width: 25,
                height: 25,
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              onClick={() => {
                return val?.active &&
                  val?.tenant_payment_status === 1 &&
                  val?.lessor_payment_status === 1
                  ? setCancelModal({ open: true, id: val?.id })
                  : null;
              }}
            >
              <Delete
                sx={{
                  color: (() => {
                    return val?.active &&
                      val?.tenant_payment_status === 1 &&
                      val?.lessor_payment_status === 1
                      ? "secondary.main"
                      : "gray.main";
                  })(),
                  fontSize: 23,
                }}
              />
            </Avatar>
          </div>
          <div>{val.admin_info.firstname + " " + val.admin_info.lastname}</div>
        </div>
      ),
    },
    // {
    //   title: "ผู้ทำรายการ",
    //   key: "admin",
    //   render: (val) => {
    //     return (
    //       <div>{val.admin_info.firstname + " " + val.admin_info.lastname}</div>
    //     );
    //   },
    // },
  ];

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"created_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "order_code", name: "เลขที่ใบเช่า" },
                { value: "tenant_name", name: "ผู้เช่า" },
                { value: "lessor_name", name: "ผู้ให้เช่า" },
                { value: "project", name: "โครงการ" },
                { value: "id_rental_order", name: "id ใบสั่งซื้อ" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"name"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterSearch = { ...filter, page: 1 };
                getOrder(filterSearch);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <div>
              <SelectRowValue
                onSelect={(val) => {
                  setFilter({ ...filter, per_page: val });
                }}
              />
              {/* <FieldSelect
                  name={"จำนวนแถว"}
                  options={[
                    { value: 5, name: "5" },
                    { value: 10, name: "10" },
                    { value: 20, name: "20" },
                    { value: 50, name: "50" },
                    { value: 100, name: "100" },
                  ]}
                  defaultValue={5}
                  value={filter.per_page}
                  onChange={(val) => {
                    setFilter({ ...filter, per_page: val });
                  }}
                  closePaddingBottom={true}
                  fullWidth={true}
                /> */}
            </div>
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
          navigate("/");
        }}
      />

      <Drawer
        anchor="right"
        open={advanceFilter}
        // open={true}
        onClose={() => {
          setAdvanceFilter(false);
          const filterA = { ...filter, page: 1 };
          getOrder(filterA);
        }}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            <FieldSelect
              name={"กรองและการจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available", paddingTop: "10px" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
            <FieldSelect
              name={"สถานะงานล่าสุด"}
              options={listWorkStatus}
              style={{
                width: "-webkit-fill-available",
                paddingTop: "10px",
              }}
              value={filter.work_status}
              onChange={(val) => setFilter({ ...filter, work_status: val })}
              closePaddingBottom={true}
            />{" "}
            <FieldSelect
              name={"สถานะการชำระเงินล่าสุด"}
              options={listPaymentStatus}
              style={{
                width: "-webkit-fill-available",
                paddingTop: "10px",
              }}
              value={filter.payment_status}
              onChange={(val) => setFilter({ ...filter, payment_status: val })}
              closePaddingBottom={true}
            />
            <div style={{ width: "100%", paddingTop: "26px" }}>
              <ButtonCustom
                name={"ล้างการกรอง"}
                onClick={() => {
                  setFilter({
                    sort_by: "created_at",
                    start_date: "",
                    end_date: "",
                    page: 1,
                    per_page: 5,
                    total: 0,
                  });
                }}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </Drawer>

      <Container>
        <SubMenu
          menus={subMenu.map((menu) => {
            return {
              label: menu.label,
              path: menu.path,
              active: menu.label === `ทำรายการเช่ารถ` ? true : false,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={"/"}
        />
        <div style={{ paddingTop: "20px" }}></div>
        {filterBar()}

        <div style={{ paddingTop: "20px" }}>
          <TableAntdCustom
            mainColunmns={columnsMain}
            mainData={dataOrder}
            onChange={(key, row) => setSelectedRows(row)}
            pagination={filter}
            scroll={{ y: "calc(100vh - 480px)" }}
            setPagination={(val) => {
              setFilter({
                ...filter,
                ...val,
              });
            }}
          />
        </div>
        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() =>
            setOpenModalOrder({ open: true, type: "create", data: null })
          }
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>
      </Container>
      {openModalOrder && (
        <ModalAddRentalOrder
          open={openModalOrder.open}
          type={openModalOrder.type}
          handleClose={() => {
            setOpenModalOrder({ open: false, type: "", data: null });
            getOrder(filter);
          }}
          editData={openModalOrder.data}
        />
      )}

      <CustomModal
        open={cancelModal.open}
        title={"ยืนยันการยกเลิก"}
        onCancel={() => {
          setCancelModal({ open: false, id: null });
        }}
        onConfirm={async () => {
          handleCancelOrder(cancelModal.id);
        }}
      ></CustomModal>
    </React.Fragment>
  );
};

export default TableRental;
