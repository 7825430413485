import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import ModalSelectProduct from "./modalSelectProduct";
import Swal from "sweetalert2";
import { getAllShipperFavoriteGroupByProfileId } from "utils/api/profile/shipperFavoriteGroup";
import Pagination from "components/pagination/Pagination";
import { Button, TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import Line from "components/layout/line";
import ModalWork from "./modalWork";
import { getAllCarrierInFavoriteGroup } from "utils/api/profile/carrierInFavoriteGroup";
const SelectFavCarrier = (props) => {
  const [show, setShow] = useState(false);
  const { value, onChange = () => { }, profile_id, is_detail } = props;
  const [favList, setFavList] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalCarrier, setTotalCarrier] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    per_page: 5,
    sort_field: "updated_at",
    sort_order: -1,
    active: true,
  });
  const [filterCarrier, setFilterCarrier] = useState({
    page: 1,
    per_page: 5,
    sort_field: "updated_at",
    sort_order: -1,
    active: true,
  });
  const [showCarrierInGroup, setShowCarrierInGroup] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [carrierList, setCarrierList] = useState([]);

  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
    setShowCarrierInGroup(false);
  };
  useEffect(() => {
    if (profile_id) {
      onChange(null);
    }
  }, [profile_id]);
  useEffect(() => {
    if (show && profile_id) {
      getFavCarrier();
    }
  }, [show, profile_id]);
  useEffect(() => {
    getFavCarrier();
  }, [filter]);
  useEffect(() => {
    getCarrierInGroup();
  }, [filterCarrier]);
  useEffect(() => {
    if (showCarrierInGroup) {
      getCarrierInGroup();
    } else {
      setCarrierList([]);
    }
  }, [showCarrierInGroup]);
  const getFavCarrier = async () => {
    let fav = await getAllShipperFavoriteGroupByProfileId(profile_id, filter);
    console.log(fav);
    setFavList(fav.data.data.results);
    setTotal(fav.data.data.total);
  };
  const getCarrierInGroup = async () => {
    let carrier = await getAllCarrierInFavoriteGroup(
      showCarrierInGroup?.id,
      filterCarrier
    );
    console.log(carrier);
    setCarrierList(carrier.data.data.results);
    setTotalCarrier(carrier.data.data.total);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (is_detail) {
            setShowDetail(true);
          } else {
            clickShow();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("../../assets/image/work/fav.png")
            }
            height={40}
            alt={"bill"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div
            style={{
              fontWeight: 500,
              opacity: is_detail ? 1 : value?.name ? 1 : 0.3,
            }}
          >
            ผู้ให้บริการที่ชื่นชอบ
          </div>
          <div
            style={{
              fontSize: 13,
              opacity: is_detail ? 1 : value?.name ? 1 : 0.3,
            }}
          >
            {is_detail
              ? is_detail?.profile_shipper_favorite_group?.name
              : value?.name
                ? value?.name
                : "เลือกขนส่งที่ถูกใจจากรายชื่อที่บันทึกไว้"}
          </div>
        </div>
        <div style={{ alignSelf: "center" }}>
          <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
        </div>
      </div>
      <ModalWork
        open={show}
        minHeight
        enableClose
        close={() => {
          clickClose();
        }}
      >
        {showCarrierInGroup ? (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ผู้ให้บริการที่ชื่นชอบ</div>
            <Line />
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filterCarrier.search_value}
              onChange={(val) => {
                setFilterCarrier({
                  ...filterCarrier,
                  company_name: val.target.value,
                });
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{ height: 50 }}
              color="secondary"
              onClick={() => {
                getFavCarrier();
                // onChange(null);
              }}
            >
              ค้นหา
            </Button>
            <div>
              {carrierList.map((ele, index) => {
                return (
                  <div
                    key={"fav" + index}
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderWidth: 1,
                      padding: 10,
                      borderColor: showCarrierInGroup?.select.includes(ele.id)
                        ? "#121f43"
                        : "",
                      color: showCarrierInGroup?.select.includes(ele.id)
                        ? "#121f43"
                        : "",
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      console.log(ele);

                      if (showCarrierInGroup?.select.includes(ele.id)) {
                        // console.log(
                        //   showCarrierInGroup?.select.includes(ele.id)
                        // );
                        // console.log(showCarrierInGroup?.select.indexOf(ele.id));
                        let tmp = [...showCarrierInGroup.select];
                        tmp.splice(
                          showCarrierInGroup?.select.indexOf(ele.id),
                          1
                        );
                        setShowCarrierInGroup({
                          ...showCarrierInGroup,
                          select: tmp,

                        });
                      } else {
                        console.log('here', showCarrierInGroup.select)
                        let not_group = false
                        carrierList.forEach(e => {

                          showCarrierInGroup.select.forEach(ele => {
                            if (e.id === ele) {
                              not_group = true
                            }
                          })
                        })
                        let tmp = [...showCarrierInGroup.select];;
                        if (not_group) {
                          tmp = [];
                        }

                        tmp.push(ele.id);
                        setShowCarrierInGroup({
                          ...showCarrierInGroup,
                          select: tmp,
                        });
                        // showCarrierInGroup?.select.push(ele.id)
                      }
                      // getCarrierInGroup(ele.id);
                      // setShowCarreri(ele);
                    }}
                  >
                    {ele.profile.company_name}
                  </div>
                );
              })}
            </div>
            <div style={{ textAlign: "center" }}>
              <Pagination
                pagination={{
                  page: filterCarrier.page,
                  per_page: filterCarrier.per_page,
                  total: totalCarrier,
                }}
                setPagination={(val) => {
                  console.log(val);
                  setFilterCarrier({
                    ...filterCarrier,
                    per_page: val.per_page,
                    page: val.page,
                  });
                  // getFavPlace({
                  //   ...filter,
                  //   per_page: val.per_page,
                  //   page: val.page,
                  // })
                }}
              // loading={loading}
              />
            </div>
            <div
              style={{ position: "absolute", bottom: 20, right: 20, left: 20 }}
            >
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  onClick={() => {
                    if (
                      showCarrierInGroup?.select?.length === carrierList.length
                    ) {
                      let tmp = [...showCarrierInGroup.select];
                      tmp = [];
                      setShowCarrierInGroup({
                        ...showCarrierInGroup,
                        select: tmp,
                      });
                    } else {
                      let all = carrierList.map((e) => {
                        return e.id;
                      });
                      // console.log("all", all);
                      let tmp = [...showCarrierInGroup.select];
                      tmp = all;
                      setShowCarrierInGroup({
                        ...showCarrierInGroup,
                        select: tmp,
                      });
                    }
                  }}
                // disabled={showCarrierInGroup?.select?.length === 0}
                >
                  {showCarrierInGroup?.select?.length === carrierList.length
                    ? "ไม่เลือกทั้งหมด"
                    : "เลือกทั้งหมด"}{" "}
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      setShowCarrierInGroup(false);
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={() => {
                      onChange(showCarrierInGroup);
                      clickClose();
                    }}
                    disabled={showCarrierInGroup?.select?.length === 0}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ผู้ให้บริการที่ชื่นชอบ</div>
            <Line />
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_value}
              onChange={(val) => {
                setFilter({ ...filter, name: val.target.value });
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{ height: 50 }}
              color="secondary"
              onClick={() => {
                getFavCarrier();
                // onChange(null);
              }}
            >
              ค้นหา
            </Button>
            <div>
              {favList.map((ele, index) => {
                return (
                  <div
                    key={"fav" + index}
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderWidth: 1,
                      padding: 10,
                      borderColor: ele.id === value?.id ? "#121f43" : "",
                      color: ele.id === value?.id ? "#121f43" : "",
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      console.log(ele);
                      // getCarrierInGroup(ele.id);
                      setShowCarrierInGroup({
                        id: ele.id,
                        select: value?.select ?? [],
                        name: ele.name,
                      });

                      // setShowCarreri(ele);
                    }}
                  >
                    {ele.name}
                  </div>
                );
              })}
            </div>
            <div style={{ textAlign: "center" }}>
              <Pagination
                pagination={{
                  page: filter.page,
                  per_page: filter.per_page,
                  total: total,
                }}
                setPagination={(val) => {
                  console.log(val);
                  setFilter({
                    ...filter,
                    per_page: val.per_page,
                    page: val.page,
                  });
                  // getFavPlace({
                  //   ...filter,
                  //   per_page: val.per_page,
                  //   page: val.page,
                  // })
                }}
              // loading={loading}
              />
            </div>
            <div
              style={{ position: "absolute", bottom: 20, right: 20, left: 20 }}
            >
              <Button
                type="submit"
                fullWidth
                variant="outline"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  color: "#8ca3ba",
                  borderColor: "#8ca3ba",
                  height: 50,
                }}
                color="secondary"
                onClick={() => {
                  onChange(null);
                  clickClose();
                }}
              >
                ไม่เลือกผู้ให้บริการ
              </Button>
            </div>
          </div>
        )}
      </ModalWork>
      <ModalWork
        open={showDetail}
        minHeight
        enableClose
        close={() => {
          setShowDetail(false);
        }}
      >
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>ผู้ให้บริการที่ชื่นชอบ</div>
          <Line />
          <div style={{ color: "#121f43", marginTop: 10 }}>
            <div>
              <label style={{ fontWeight: 600 }}>ชื่อกลุ่ม:</label>{" "}
              {is_detail?.profile_shipper_favorite_group?.name}
            </div>
            <div style={{ fontWeight: 600 }}>ผู้ให้บริการที่เลือก</div>
            <div>
              {is_detail?.carrierInWorkFavoriteGroup.map((e, i) => {
                return (
                  <div key={i + "favc"} style={{ fontSize: 14 }}>
                    {e.profile_carrier_in_favorite_group.profile.company_name}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{ position: "absolute", bottom: 20, right: 20, left: 20 }}
          >
            <div style={{ flex: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  setShowDetail(false);
                }}
              // disabled={showCarrierInGroup?.select?.length === 0}
              >
                ปิด
              </Button>
            </div>
          </div>
        </div>
      </ModalWork>
    </div>
  );
};
export default SelectFavCarrier;
