import { Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./TableTrade.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldSelect from "components/field/FieldSelect";
import ButtonCustom from "components/ButtonCustom";
import {
  createOrder,
  getAllCustomer,
  getAllTradeProject,
  getProjectTradeById,
  editOrderTrade,
} from "utils/api/trade/trade";
import { de } from "date-fns/locale";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
const ModalAddOrder = (props) => {
  const defaultValue = props.editData;
  console.log("defaultValue", defaultValue);
  const [listCustomer, setListCustomer] = useState([]);
  const [listProject, setListPeoject] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  console.log("listProject", listProject);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (defaultValue && props?.type === "edit") {
      setValue("order_code", defaultValue?.order_code);
      setValue("puchase_date", defaultValue?.puchase_date);
      setValue("product_name", defaultValue?.product_name);
      setValue("sell_amount", defaultValue?.sell_amount);
      setValue("sell_amount_unit", defaultValue?.sell_amount_unit);
      setValue("sell_price", defaultValue?.sell_price);
      setValue("po_file", defaultValue?.po_file);
    }
  }, [defaultValue]);

  const customer = watch("customer_id");

  useEffect(() => {
    setIsClicked(false);
  }, [props?.open]);

  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    getProject(customer);
  }, [customer]);

  useEffect(() => {
    if (defaultValue) {
      getProjectById(defaultValue?.trade_project_id);
    }
  }, [defaultValue]);

  const getCustomer = async () => {
    await getAllCustomer({ type: "list_select" })
      .then((res) => {
        const results = res.data.results;
        setListCustomer(
          results?.map((res) => ({
            value: res.id,
            name: res.company_name,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getProject = async (id) => {
    await getAllTradeProject(id, { type: "list_select" })
      .then((res) => {
        const results = res.data.results;
        console.log("result project", results);
        setListPeoject(
          results?.map((res) => ({
            value: res.id,
            name: res.project_name,
            product: res.product_trade_type?.name,
          }))
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getProjectById = async (id) => {
    await getProjectTradeById(id).then((res) => {
      const results = res.data.results;
      console.log("result project id", results);
      setValue("trade_project_id", results?.id ?? null);
      setValue("customer_id", results?.trade_customer_id ?? null);
    });
  };

  const validateFile = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!file || typeof file === "string") {
      return true; // No error when the file is empty
    }

    if (!allowedTypes.includes(file.type)) {
      return "อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB";
    }
    if (file.size > maxSize) {
      return "อัพโหลดไฟล์นามสกุลไฟล์ JPG PNG และ PDF เท่านั้น และขนาดไม่เกิน 10 MB";
    }
    return true;
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    setIsClicked(true);
    const GForm = new FormData();
    Object.keys(data).forEach((key) => {
      GForm.append(key, data[key]);
    });
    if (props?.type === "edit") {
      await editOrderTrade(defaultValue.id, GForm)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await createOrder(GForm)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Modal
        open={props?.open}
        onClose={() => {
          props?.handleClose();
          reset();
        }}
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={() => {
                props?.handleClose();
                reset();
              }}
            />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              {props?.type === "edit" ? "แก้ไขรายการหลัก" : "เพิ่มรายการหลัก"}
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="order_code"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกเลขที่ใบสั่งซื้อ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldInput
                      label="เลขที่ใบสั่งซื้อ"
                      onChange={onChange}
                      value={value}
                      error={!!errors.order_code}
                      helperText={
                        errors.order_code ? errors.order_code.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                name="po_file"
                control={control}
                rules={{
                  required: false,
                  validate: validateFile,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldUpload
                    name={"ไฟล์ทำรายการสั่งซื้อ"}
                    accept="image/jpeg, image/png, application/pdf"
                    icon={<UploadFile />}
                    value={value} //คลิกเพื่อเปิด
                    onChange={onChange}
                    // url={dataDetail.image}
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    error={!!errors.po_file}
                    textError={errors.po_file ? errors.po_file.message : false}
                  />
                )}
              />
              <Controller
                control={control}
                name="puchase_date"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกวันที่สั่งซื้อ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldDatePicker
                      label="วันที่สั่งซื้อ"
                      onChange={onChange}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.puchase_date}
                      errorText={
                        errors.puchase_date ? errors.puchase_date.message : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="customer_id"
                rules={{
                  required: props?.type === "edit" ? false : "กรุณาเลือกลูกค้า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ลูกค้า"
                      onChange={onChange}
                      options={listCustomer ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.customer_id}
                      errorText={
                        errors.customer_id ? errors.customer_id.message : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="trade_project_id"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณาเลือกโครงการ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="โครงการ"
                      onChange={(val) => {
                        onChange(val);
                        listProject?.forEach((ele) => {
                          console.log("element", ele, val);
                          if (ele.value === val) {
                            setValue("product_name", ele.product);
                          }
                        });
                      }}
                      options={listProject ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.trade_project_id}
                      errorText={
                        errors.trade_project_id
                          ? errors.trade_project_id.message
                          : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="product_name"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกชื่อสินค้า",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ชื่อสินค้า"
                      onChange={onChange}
                      value={value}
                      error={!!errors.product_name}
                      helperText={
                        errors.product_name ? errors.product_name.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="sell_amount"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกจำนวนสินค้าที่ซื้อขาย",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="จำนวนสินค้าที่ซื้อขาย"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.sell_amount}
                      helperText={
                        errors.sell_amount ? errors.sell_amount.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="sell_amount_unit"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกประเภทหน่วยนับ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ประเภทหน่วยนับ"
                      onChange={onChange}
                      value={value}
                      error={!!errors.sell_amount_unit}
                      helperText={
                        errors.sell_amount_unit
                          ? errors.sell_amount_unit.message
                          : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="sell_price"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกราคาสินค้าที่ซื้อขาย",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="รวมราคาสินค้าที่ขาย (บาท)"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.sell_price}
                      helperText={
                        errors.sell_price ? errors.sell_price.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={() => {
                    props.handleClose();
                    reset();
                  }}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="บันทึก"
                  style={{ padding: 0 }}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                  disabled={isClicked}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddOrder;
